import React, { Component } from 'react';
import V from 'voca';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Row, Col, Table } from 'antd';
import SimpleSlider from '../../../patients/Slider';
import '../../../../styles/atomic.css';
import {
  clearCurrentMonitoredPatient,
  exitCurrentMonitoredPatient,
  setCurrentMonitoredPatient,
} from '../../../monitor-timer/actions';
import { delay } from '../../../patients/RTMDashboard/components/PatientDetailDrawer/mixins';
import { getRecoil } from 'recoil-nexus';
import states from '../../../../states';
import services from '../../../../services';

const getTime = date => new Date(date).getTime();
const formatDate = date => moment(date).format('MM/DD/YYYY');

class AdherenceLog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stepsCount: [],
      painScale: [],
    };
  }

  async componentDidMount() {
    const {
      patient,
      visibleProfile: { GroupInfo },
    } = this.props;

    if (patient) {
      await this.getPoints();

      if (!!GroupInfo.EnableRTM && !!patient.EnableRTM) {
        await this.setMonitoring();
      }
    }
  }

  async componentWillUnmount() {
    await this.exitMonitoring();
  }

  async componentDidUpdate(prevProps) {
    const {
      dispatch,
      patient,
      visibleProfile: {
        EmailAddress,
        GroupInfo: { Name, EnableRTM },
      },
      currentWeek,
      currTab,
    } = this.props;

    if (prevProps.currTab !== currTab) {
      if (currTab !== 'adherenceLog') {
        await this.exitMonitoring();
      } else {
        await this.setMonitoring();
      }
    }

    const prevWeek = JSON.stringify(prevProps.currentWeek);
    const currWeek = JSON.stringify(currentWeek);

    if (prevWeek !== currWeek) {
      await this.getPoints();
    }

    // CHECKS IF PATIENT OBJECT UPDATES
    // FOR SETTING AND CLEARING CURRENT MONITORED PATIENT
    // DEPENDS IF ENABLE RTM IS TRUE OR FALSE
    if (JSON.stringify(prevProps.patient) !== JSON.stringify(patient)) {
      if (EnableRTM && patient && patient.EnableRTM) {
        dispatch(
          setCurrentMonitoredPatient({
            patient,
            activity: 'View Adherence Log',
            groupName: Name,
            provider: EmailAddress,
          })
        );
      } else {
        dispatch(clearCurrentMonitoredPatient());
      }
    }
  }

  setMonitoring = async () => {
    const {
      dispatch,
      patient,
      visibleProfile: {
        EmailAddress,
        GroupInfo: { Name },
      },
      currentMonitoredPatient,
      isMonitoring,
    } = this.props;

    if (currentMonitoredPatient && isMonitoring) {
      await dispatch(exitCurrentMonitoredPatient());
      await delay(1500);
    }

    await dispatch(
      setCurrentMonitoredPatient({
        patient,
        activity: 'View Adherence Log',
        groupName: Name,
        provider: EmailAddress,
      })
    );
  };

  exitMonitoring = async () => {
    const {
      dispatch,
      patient,
      visibleProfile: {
        GroupInfo: { EnableRTM },
      },
      currentMonitoredPatient,
    } = this.props;

    if (EnableRTM && patient && patient.EnableRTM) {
      if (currentMonitoredPatient) {
        await dispatch(exitCurrentMonitoredPatient());
      }
    }
  };

  getPoints = async () => {
    const { patient, currentWeek, visibleProfile } = this.props;

    try {
      const hours = 23 * 60 * 60 * 1000;
      const minutes = 59 * 60 * 1000;
      const seconds = 59 * 1000;

      const query = {
        start: getTime(currentWeek[0].date),
        end: getTime(currentWeek[6].date) + hours + minutes + seconds,
      };

      const { GroupId } = visibleProfile;
      const response = await services.rtm.getDatapoints(
        GroupId,
        patient.Sub,
        query
      );

      if (response.status === 200) {
        const { patientDataPoints, painScale } = response.data;

        if (patientDataPoints.length > 0) {
          const steps = [];
          patientDataPoints.forEach(item => {
            const { Datapoints } = item;
            Datapoints.forEach(dp => {
              if (dp.Event === 'STEPS') {
                steps.push({
                  date: dp.Time,
                  value: dp.Count,
                });
              }
            });
          });

          this.setState({
            stepsCount: steps,
            painScale,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  renderRxTracker = rx => {
    if (!rx) {
      return null;
    }

    const { exercises } = rx;
    if (!exercises) {
      return null;
    }

    return exercises.map((exercise, i) => ({
      key: i,
      exerciseName: V.titleCase(exercise.exerciseName),
      ...this.props.currentWeek.reduce((acc, day, i) => {
        let completionDates;
        if (
          exercise.completionDates &&
          Array.isArray(exercise.completionDates)
        ) {
          completionDates = exercise.completionDates.map(cDate =>
            String(moment(cDate).format('MM/DD/YYYY'))
          );
        } else {
          completionDates = exercise.completionDates;
        }

        return {
          ...acc,
          [day.date]:
            completionDates &&
            Array.isArray(exercise.completionDates) &&
            _.indexOf(completionDates, String(day.date)) >= 0 ? (
              <i className="fa fa-check-circle fa-2x" aria-hidden="true" />
            ) : (
              <span />
            ),
        };
      }, {}),
    }));
  };

  render() {
    const { currentWeek } = this.props;
    const { stepsCount, painScale } = this.state;

    const prescription = getRecoil(states.prescription);
    const { form } = prescription;

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          <Col lg={16} md={16} sm={6} xs={4} />
          <Col lg={8} md={8} sm={18} xs={20}>
            <SimpleSlider />
          </Col>
        </Row>
        <Table
          scroll={{
            x: 'max-content',
          }}
          size="small"
          pagination={false}
          columns={[
            {
              title: '',
              dataIndex: 'exerciseName',
              key: 'exerciseName',
              fixed: 'left',
              width: 160,
              className: 'care-plan-program-table_row',
            },
            ...currentWeek.map((e, i) => ({
              title: e.dayS,
              dataIndex: e.date,
              key: e.date,
              className: `ant-table-cell ${
                moment().format('MM/DD/YYYY') < e.date ? 'grayed' : ''
              }`,
            })),
          ]}
          dataSource={[
            ...this.renderRxTracker(form.prescription || []),
            {
              key: '1',
              exerciseName: 'Steps Taken',
              ...currentWeek.reduce((acc, day, i) => {
                const step = stepsCount.find(
                  item => formatDate(item.date) === day.date
                );

                return {
                  ...acc,
                  [day.date]: <strong>{step?.value || ''}</strong>,
                };
              }, {}),
            },
            {
              key: '2',
              exerciseName: 'Daily Outcome Score',
              ...currentWeek.reduce((acc, day, i) => {
                const scale = painScale.find(
                  item => formatDate(item.Date) === day.date
                );

                return {
                  ...acc,
                  [day.date]: <strong>{scale?.Value || ''}</strong>,
                };
              }, {}),
            },
          ]}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentWeek: state.patients.currentWeek,
    week: state.patients.weekChange,
    visibleProfile: state.visibleProfile,
    patient: state.patients.currentPatient,
    currentMonitoredPatient: state.monitorTimer.currentMonitoredPatient,
    isMonitoring: state.monitorTimer.isMonitoring,
  };
}

export default connect(mapStateToProps)(withRouter(AdherenceLog));

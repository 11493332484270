import 'isomorphic-fetch';
import config from '../../../config';
import { createAction } from 'redux-actions';
import { API_REQUEST } from '../middleware';

//actions
export const REQUEST_AUTHENTICATION = 'REQUEST_AUTHENTICATION';
export const REQUEST_DEAUTHENTICATION = 'REQUEST_DEAUTHENTICATION';
export const GET_PROFILE = 'GET_PROFILE';
export const VALIDATE_CODE = 'VALIDATE_CODE';
export const UPDATE_PASSWORD_BY_CODE = 'UPDATE_PASSWORD_BY_CODE';
export const UPDATE_PASSWORD_BY_TOKEN = 'UPDATE_PASSWORD_BY_TOKEN';
export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const DISABLE_PROFILE_PHOTO_POPUP = 'DISABLE_PROFILE_PHOTO_POPUP';

//action creators

export const loginSuccess = createAction(LOGIN_SUCCESS);

export const CLEAR_DATA = 'CLEAR_DATA';

export const clearData = createAction(CLEAR_DATA);

export const POST_INTEGRATION_CREDENTIALS = 'POST_INTEGRATION_CREDENTIALS';

export const postIntegrationCredentials = createAction(
  POST_INTEGRATION_CREDENTIALS,
  async data => {
    const response = fetch(`${config.root}/integration`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      body: JSON.stringify(data)
    });
    return response;
  }
);

export const requestAuth = credentials =>
  createAction(REQUEST_AUTHENTICATION)(
    fetch(`${config.root}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    }).then(
      response =>
        new Promise((resolve, reject) => {
          if (response.status === 200) {
            response.json().then(json => resolve(json));
          } else {
            response.text().then(text => reject(text));
          }
        })
    )
  );

export const requestDeauth = createAction(REQUEST_DEAUTHENTICATION);

export const requestProfile = (profileId, role) => {
  return {
    [API_REQUEST]: createAction(GET_PROFILE)({
      resource: 'users',
      method: 'GET',
      query: `search=${encodeURIComponent(profileId)}&role=${role}`
    })
  };
};

export const GET_ROLE = 'GET_ROLE';

export const getRole = validation => {
  return {
    [API_REQUEST]: createAction(GET_ROLE)({
      resource: 'get-role',
      method: 'GET',
      query: `validation=${validation}`
    })
  };
};

export const GET_APPSTORE_LINKS = 'GET_APPSTORE_LINKS';

export const getAppstoreLinks = subdomain => {
  return {
    [API_REQUEST]: createAction(GET_APPSTORE_LINKS)({
      resource: 'get-appstore-links',
      method: 'GET',
      query: `subdomain=${subdomain}`
    })
  };
};

export const codeValidation = code =>
  createAction(VALIDATE_CODE)(
    fetch(`${config.root}/users/code-validation/${code}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(
      response =>
        new Promise((resolve, reject) => {
          if (response.status === 200) {
            response.json().then(json => resolve(json));
          } else {
            response.text().then(text => reject(text));
          }
        })
    )
  );

export const updatePasswordByCode = (newPassword, validationCode) =>
  createAction(UPDATE_PASSWORD_BY_CODE)(
    fetch(`${config.root}/users/update-password`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ newPassword, validationCode })
    }).then(
      response =>
        new Promise((resolve, reject) => {
          if (response.status === 200) {
            response.json().then(json => resolve(json));
          } else {
            response.text().then(text => reject(text));
          }
        })
    )
  );

export const updatePasswordByToken = values => {
  return {
    [API_REQUEST]: createAction(GET_PROFILE)({
      resource: 'users/update-password',
      method: 'PUT',
      body: {
        ...values
      }
    })
  };
};

export const requestPasswordReset = input =>
  createAction(REQUEST_PASSWORD_RESET)(
    fetch(`${config.root}/users/password-reset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ input })
    }).then(
      response =>
        new Promise((resolve, reject) => {
          if (response.status === 200) {
            response.json().then(json => resolve(json));
          } else {
            response.text().then(text => reject(text));
          }
        })
    )
  );

export const disableProfilePhotoPopup = (EmailAddress, Preferences) => ({
  [API_REQUEST]: createAction(DISABLE_PROFILE_PHOTO_POPUP)({
    resource: `users/disable-popup`,
    method: 'PUT',
    body: { EmailAddress, Preferences }
  })
});

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRecoilState } from 'recoil';
import { capitalize } from 'voca';
import {
  Drawer,
  Button,
  Checkbox,
  Col,
  Dropdown,
  Input,
  Menu,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
  List,
  Popconfirm,
  notification
} from 'antd';
import {
  CloseOutlined,
  DeleteOutlined,
  FilterOutlined,
  PicCenterOutlined,
  SearchOutlined
} from '@ant-design/icons';

import {
  categories,
  filterData,
  formatCategory,
  searchData,
  sortData
} from '../../utils/educationalPdf';
import { toCamelCaseObjKeys } from '../../utils/object.utils';

import LoadingPage from '../../components/LoadingPage';
import PDFCategoryList from '../../components/PDFCategoryList';
import states from '../../states';
import services from '../../services';

const AssignEducationalPDFDrawer = ({
  visibleProfile,
  isOpen,
  handleClose,
  data,
  patient,
  successCallback
}) => {
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filter, setFilter] = useState([]);
  const [search, setSearch] = useState('');
  const [selectionPanelDrawer, setSelectionPanelDrawer] = useState(false);

  const [pdf, setPdf] = useRecoilState(states.pdf);

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });

  const isSticky = () => {
    const selectionPanel = document.querySelector('.ptw-pres-exercise-list');
    const scrollTop = window.scrollY;

    if (selectionPanel) {
      if (scrollTop >= 300) {
        selectionPanel.classList.add('is-sticky');
      }
    }
  };

  const initData = async () => {
    if (pdf.list.length) {
      return;
    }

    try {
      setLoading(true);

      const ptw = '2667b98c-4e98-4cdb-b03a-959ed7a7f435';
      const sub = visibleProfile.Sub;

      const [fromPTW, fromSub] = await Promise.all([
        services.educationalPdf.getByOwner(ptw),
        services.educationalPdf.getByOwner(sub)
      ]);

      if (fromPTW.status === 200 && fromSub.status === 200) {
        setPdf((prevState) => ({
          ...prevState,
          list: categories.map((cat) => ({
            category: cat,
            data: [...fromPTW.data, ...fromSub.data]
              .filter((item) => item.Category === cat)
              .map((item) => ({
                ...toCamelCaseObjKeys(item)
              }))
          }))
        }));
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred while fetching Educational PDFs.'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleSelectFilter = (e) => {
    setFilter((prevState) => {
      if (prevState.includes(e.target.name)) {
        return prevState.filter((item) => item !== e.target.name);
      }
      return [...prevState, e.target.name];
    });
  };

  const handleSelectPDF = (selectedItem) => {
    setPdf((prevState) => {
      // check if the selected items id is already in the list
      const isAlreadySelected = prevState.currSelected.some(
        (item) => item.id === selectedItem.id
      );

      return {
        ...prevState,
        currSelected: isAlreadySelected
          ? prevState.currSelected.filter((item) => item.id !== selectedItem.id)
          : [...prevState.currSelected, selectedItem]
      };
    });
  };

  const handleRemovePDF = (selectedItem) => {
    setPdf((prevState) => {
      return {
        ...prevState,
        currSelected: prevState.currSelected.filter(
          (item) => item.id !== selectedItem.id
        )
      };
    });
  };

  const handleRemoveAllPDF = () => {
    setPdf((prevState) => {
      return {
        ...prevState,
        currSelected: []
      };
    });
  };

  const handleCloseFilter = (removedTag) => {
    setFilter(filter.filter((tag) => tag !== removedTag));
  };

  const handleAssignPdf = async () => {
    try {
      setSaving(true);

      const dataId = data?.Id || null;
      const content = {
        Id: dataId,
        Sub: patient.Sub,
        GroupId: visibleProfile.GroupId,
        PDFs: [...pdf.currSelected].map((item) => ({
          Id: item.id,
          Owner: item.owner,
          Title: item.title,
          Category: item.category,
          Url: item.url
        }))
      };

      const { EmailAddress, PhoneNumber, FirstName } = patient;
      const response = await services.educationalPdf.assignPdf(content, {
        Email: EmailAddress || null,
        Phone: PhoneNumber || null,
        Fname: capitalize(FirstName || 'patient')
      });

      if (response.status === 200) {
        notification.success({
          message: 'Success',
          description: 'Educational PDF/s assigned successfully.'
        });

        handleClose();
        successCallback(response.data);
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred while assigning Educational PDFs.'
      });
    } finally {
      setSaving(false);
    }
  };

  let filteredList = [];
  filteredList = filterData(pdf.list, filter);
  filteredList = searchData(filteredList, search);
  filteredList = sortData(filteredList);

  return (
    <Fragment>
      <PDFSelectionPanelDrawer
        pdf={pdf}
        isOpen={selectionPanelDrawer}
        handleClose={() => setSelectionPanelDrawer(false)}
        handleRemoveAllPDF={handleRemoveAllPDF}
        handleRemovePDF={handleRemovePDF}
      />
      <Drawer
        destroyOnClose
        title="Assign Educational PDF"
        placement="left"
        visible={isOpen}
        width="100%"
        onClose={handleClose}
        footer={[
          <Space className="pull-right" size="middle">
            <Button
              size="large"
              className="ptw-btn"
              type="default"
              onClick={handleClose}
            >
              Cancel
            </Button>

            <Button
              size="large"
              className="ptw-btn btn-primary"
              type="primary"
              loading={saving}
              onClick={handleAssignPdf}
            >
              Assign
            </Button>
          </Space>
        ]}
      >
        <Row gutter={[24, 24]}>
          <Col lg={18} md={16}>
            {loading ? (
              <LoadingPage type="list" content="Loading PDFs, please wait..." />
            ) : (
              <div className="ptw-main-body">
                <div className="tab-header">
                  <div />
                  <div className="tab-header-controls">
                    <Input
                      size="middle"
                      placeholder="Search educational PDFs"
                      prefix={<SearchOutlined />}
                      value={search}
                      onChange={handleSearch}
                    />
                    <Tooltip title="Filter Educational PDFs">
                      <Dropdown
                        overlay={
                          <Menu>
                            {categories.map((item, i) => (
                              <Menu.Item key={item}>
                                <Checkbox
                                  name={item}
                                  checked={filter.includes(item)}
                                  onChange={handleSelectFilter}
                                >
                                  {formatCategory(item)}
                                </Checkbox>
                              </Menu.Item>
                            ))}
                          </Menu>
                        }
                        onOpenChange={(e) => setFilterOpen(e)}
                        open={filterOpen}
                        trigger={['click']}
                        placement="bottomRight"
                      >
                        <Button className="btn-default" shape="circle">
                          <FilterOutlined style={{ fontSize: 16 }} />
                        </Button>
                      </Dropdown>
                    </Tooltip>
                    <Tooltip title="Selected PDFs">
                      <Button
                        className="btn-default hide-dt show-sm-tablet"
                        shape="circle"
                        onClick={() => setSelectionPanelDrawer(true)}
                      >
                        <PicCenterOutlined style={{ fontSize: 16 }} />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
                <Space direction="vertical" size={8} style={{ width: '100%' }}>
                  {!!filter.length && (
                    <div>
                      <Space size={6}>
                        <Typography.Text>Filtered by:</Typography.Text>
                        <Space size={0}>
                          {filter.map((item) => (
                            <Tag
                              color="blue"
                              key={item}
                              closable
                              onClose={() => handleCloseFilter(item)}
                            >
                              {formatCategory(item)}
                            </Tag>
                          ))}
                        </Space>
                      </Space>
                    </div>
                  )}
                  {!!search.length && (
                    <Typography.Text>
                      Results found for:{' '}
                      <Typography.Text strong>{search}</Typography.Text>
                    </Typography.Text>
                  )}

                  <Row gutter={[16, 16]}>
                    {filteredList &&
                      filteredList.map((item, i) => {
                        return (
                          <Col key={i} span={24}>
                            <PDFCategoryList
                              type="ASSIGN_PDF"
                              handleSelectPDF={handleSelectPDF}
                              category={item.category}
                              data={item.data}
                            />
                          </Col>
                        );
                      })}
                  </Row>
                </Space>
              </div>
            )}
          </Col>
          <Col lg={6} md={8} sm={0} xs={0}>
            <div className="ptw-pres-exercise-list is-sticky">
              <Space
                className="pull-right"
                style={{ marginTop: -6 }}
                direction="horizontal"
              >
                <Popconfirm
                  title="Are you sure you want to remove all PDFs?"
                  okText="Yes"
                  cancelText="No"
                  placement="left"
                  onConfirm={handleRemoveAllPDF}
                >
                  <Tooltip title="Remove All">
                    <Button
                      type="text"
                      className="btn-default"
                      size="large"
                      shape="circle"
                      icon={<DeleteOutlined />}
                    />
                  </Tooltip>
                </Popconfirm>
              </Space>
              <Typography.Title level={4}>
                Selected PDFs ({pdf.currSelected.length})
              </Typography.Title>
              <List
                size="large"
                dataSource={pdf.currSelected}
                renderItem={(item) => {
                  return (
                    <List.Item
                      key={item.id}
                      className="pdf-selected-item"
                      style={{ paddingRight: 0 }}
                      actions={[
                        <Tooltip title="Remove">
                          <Button
                            type="text"
                            shape="circle"
                            icon={<CloseOutlined />}
                            onClick={() => handleRemovePDF(item)}
                          />
                        </Tooltip>
                      ]}
                    >
                      <List.Item.Meta title={item.title} />
                    </List.Item>
                  );
                }}
              />
            </div>
          </Col>
        </Row>
      </Drawer>
    </Fragment>
  );
};

const PDFSelectionPanelDrawer = ({
  pdf,
  isOpen,
  handleClose,
  handleRemoveAllPDF,
  handleRemovePDF
}) => {
  return (
    <Drawer
      destroyOnClose
      title="Selected Educational PDFs"
      placement="left"
      visible={isOpen}
      width="100%"
      onClose={handleClose}
      footer={null}
      zIndex={99999}
    >
      <Space
        className="pull-right"
        style={{ marginTop: -6 }}
        direction="horizontal"
      >
        <Popconfirm
          title="Are you sure you want to remove all PDFs?"
          okText="Yes"
          cancelText="No"
          placement="left"
          onConfirm={handleRemoveAllPDF}
        >
          <Tooltip title="Remove All">
            <Button
              type="text"
              className="btn-default"
              size="large"
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Popconfirm>
      </Space>
      <Typography.Title level={4}>
        Selected PDFs ({pdf.currSelected.length})
      </Typography.Title>
      <List
        size="large"
        dataSource={pdf.currSelected}
        renderItem={(item) => {
          return (
            <List.Item
              key={item.id}
              className="pdf-selected-item"
              style={{ paddingRight: 0 }}
              actions={[
                <Tooltip title="Remove">
                  <Button
                    type="text"
                    shape="circle"
                    icon={<CloseOutlined />}
                    onClick={() => handleRemovePDF(item)}
                  />
                </Tooltip>
              ]}
            >
              <List.Item.Meta title={item.title} />
            </List.Item>
          );
        }}
      />
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  visibleProfile: state.visibleProfile
});

export default connect(mapStateToProps)(AssignEducationalPDFDrawer);

import '../styles.css';
import React, { Fragment } from 'react';
import { useRecoilValue } from 'recoil';
import { Card, Tooltip, Typography } from 'antd';

import isURL from 'validator/lib/isURL';
import states from '../../../states';
import { InfoCircleFilled } from '@ant-design/icons';

const getDateTimeSent = datetime =>
  `${new Date(datetime).toLocaleString('en-US', {
    dateStyle: 'medium',
  })} at ${new Date(datetime).toLocaleString('en-US', {
    timeStyle: 'short',
  })}`;

const MessageBox = ({ board }) => {
  const user = useRecoilValue(states.user);
  const { details } = user;
  const { emailAddress, sub } = details;

  return board.Messages.map((item, i) => {
    const isRecipient = item.to === emailAddress || item.to === sub;
    let content = item.message;

    const regex = /\[.*\]/;
    let exerciseName = null;

    if (regex.test(item.message)) {
      exerciseName = item.message.split(']')[0].replace('[', '');
      content = item.message.split(']')[1];
    }

    const msgArr = item.message.replaceAll('\n\n', ' ').split(' ');
    if (msgArr.some(msg => isURL(msg))) {
      content = msgArr.map((msg, i) => {
        let msgContent = msg;

        if (isURL(msg)) {
          const scheme = 'https://';
          const url = (!msg.startsWith(scheme) ? scheme : '') + msg;

          msgContent = (
            <Typography.Text
              onClick={() => window.open(url, '_blank')}
              className="url-msg"
              style={{ color: isRecipient ? '#4169e1' : '#fff' }}
            >
              {msg}
            </Typography.Text>
          );
        }

        return (
          <Fragment key={i}>
            {msgContent}
            {msgArr[i + 1] && <> </>}
          </Fragment>
        );
      });
    }

    return (
      <Fragment key={i}>
        <Tooltip
          placement={isRecipient ? 'right' : 'left'}
          title={getDateTimeSent(item.date)}
        >
          <div
            style={{
              borderRadius: '0.5em',
              margin: '0.2em 0',
              padding: '0.5em 1em',
              maxWidth: '75%',
              clear: 'both',
              wordWrap: 'break-word',
              backgroundColor: isRecipient ? '#eeeeee' : '#2192ff',
              float: isRecipient ? 'left' : 'right',
              color: !isRecipient && '#fff',
            }}
          >
            {exerciseName && (
              <div
                style={{
                  padding: 6,
                  background: '#fff',
                  marginBottom: 6,
                  borderRadius: 4,
                }}
              >
                <Typography.Text>
                  <InfoCircleFilled /> Feedback to the{' '}
                  <Typography.Text style={{ textTransform: 'capitalize' }}>
                    "{exerciseName}"
                  </Typography.Text>
                  exercise
                </Typography.Text>
              </div>
            )}
            {content}
          </div>
        </Tooltip>
      </Fragment>
    );
  });
};

export default React.memo(MessageBox);

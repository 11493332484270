import { Component } from 'react';
import { titleCase } from '../../groups/stringHelpers';
import ReactGA from 'react-ga';
import imagePlaceholder from '../../../img/image-placeholder.png';
import React from 'react';
import { Typography, Space } from 'antd';
import TextTranslation from '../../../I18n/TranslateText';

class PrescriptionModalExercise extends Component {
  constructor(props) {
    super(props);
    const {
      exercise: { groupId, id }
    } = props;
    this.state = {
      src: `https://d2p805pqn3eul9.cloudfront.net/${groupId}/${id}.jpg`
    };
  }

  render() {
    const {
      props: {
        exercise,
        exercise: { id, groupId },
        currLocale = 'en',
        raintreePDF = false
      }
    } = this;

    return (
      <div className={raintreePDF ? '' : 'exercise-item-container'}>
        <div className="ptw-print-card-image-wrapper">
          <img
            id="exerciseImg"
            style={{
              maxHeight: '240px'
            }}
            src={
              exercise.imgBase64 ||
              exercise.image ||
              exercise.imageUrl ||
              `https://d2p805pqn3eul9.cloudfront.net/${groupId}/${id}.jpg`
            }
            onError={(e) => {
              ReactGA.event({
                category: 'Printout ImageError',
                action: `${groupId}/${id}`
              });

              const proxy = `https://s3.amazonaws.com/ptwired-exercise-images-prod/${groupId}/${id}.jpg`;

              if (e.target.src === proxy) {
                e.target.src = imagePlaceholder;
              } else {
                e.target.src = proxy;
              }
            }}
            ref={(elem) => (this.imageTag = elem)}
            alt="exercise"
          />
        </div>
        <Space direction="vertical" size={12} className="full-width">
          <Typography.Text strong>
            <TextTranslation
              currLocale={currLocale}
              currText={titleCase(exercise.exerciseName)}
            />
          </Typography.Text>
          <Typography.Text>
            <strong>
              <TextTranslation currLocale={currLocale} currText={'Sets:'} />
            </strong>{' '}
            {exercise.sets} |{' '}
            <strong>
              <TextTranslation currLocale={currLocale} currText={'Reps:'} />
            </strong>{' '}
            {exercise.reps}
            {exercise.hold && (
              <React.Fragment>
                |{' '}
                <strong>
                  <TextTranslation currLocale={currLocale} currText={'Hold:'} />{' '}
                </strong>{' '}
                {exercise.hold}
              </React.Fragment>
            )}{' '}
            {exercise.frequency && (
              <React.Fragment>
                | {exercise.frequency}x per {exercise.perdiem}
              </React.Fragment>
            )}
          </Typography.Text>
          <Typography.Paragraph style={{ fontSize: 12 }}>
            <TextTranslation
              currLocale={currLocale}
              currText={exercise.instructions}
            />
          </Typography.Paragraph>
        </Space>
      </div>
    );
  }
}

export default PrescriptionModalExercise;

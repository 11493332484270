import React from 'react';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Breadcrumb } from 'antd';
import {
  setCurrentMonitoredPatient,
  exitCurrentMonitoredPatient,
  startLoadingMonitoredPatient,
  endLoadingMonitoredPatient
} from '../features/monitor-timer/actions';

import { getRecoil, setRecoil } from 'recoil-nexus';
import states from '../states';

const handleSetCurrentMonitoredPatient = async (
  title,
  dispatch,
  patient,
  visibleProfile
) => {
  if (title === 'Patients' || title === 'Profile') {
    const rxState = getRecoil(states.prescription);
    const { list } = rxState;

    await dispatch(exitCurrentMonitoredPatient());

    if (title === 'Profile' && visibleProfile && patient) {
      if (patient && patient.EnableRTM && list.length === 1) {
        await dispatch(startLoadingMonitoredPatient());
        await dispatch(
          setCurrentMonitoredPatient({
            patient,
            activity: 'View Adherence Log',
            groupName: visibleProfile.GroupName,
            provider: visibleProfile.Provider
          })
        );
        await dispatch(endLoadingMonitoredPatient());
      }
    }

    setRecoil(states.prescription, {
      ...rxState,
      action: ''
    });
  }
};

const DispatchLinkItem = connect(null, dispatch =>
  bindActionCreators({ visit: push }, dispatch)
)(({ visit, title, url, dispatch, patient, visibleProfile }) => (
  <Breadcrumb.Item
    href="javascript:void"
    onClick={e => {
      handleSetCurrentMonitoredPatient(
        title,
        dispatch,
        patient,
        visibleProfile
      );

      e.preventDefault();
      visit(url);
    }}
  >
    {title}
  </Breadcrumb.Item>
));

export default DispatchLinkItem;

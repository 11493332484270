import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import V, { titleCase } from 'voca';
import { Button, Space, Row, Col, Typography } from 'antd';
import {
  ArrowLeftOutlined,
  PrinterOutlined,
  MessageFilled,
  PhoneFilled,
  TrophyFilled,
  YoutubeFilled,
} from '@ant-design/icons';

import PDFFooter from '../../../patients/Modals/PDFFooter';
import TextTranslation from '../../../../I18n/TranslateText';
import { Helmet } from 'react-helmet';
import ReactToPrint from 'react-to-print';

const generatePages = (currLayout, exercises) => {
  const pages = [];
  let exPerPage = 4;

  if (currLayout === 'B') {
    exPerPage = 2;
  } else if (currLayout === 'C') {
    exPerPage = 1;
  }

  if (exercises) {
    const filtered = exercises.filter(e => !e.deleted);

    const numberOfPages = Math.ceil(filtered.length / exPerPage);

    for (let i = 0; i < numberOfPages; i++) {
      const start = i * exPerPage;
      const end = i * exPerPage + exPerPage;
      const cut = filtered.slice(start, end);
      pages.push(cut);
    }
  }

  return pages;
};

const MobilePrintPreview = ({ location, raintreePDF = false }) => {
  const [printing, setPrinting] = useState(true);
  const [back, setBack] = useState(false);

  const {
    printProps,
    settingProps: { currLocale = 'en', currLayout = 'A', currEmail, currPhone },
  } = location.state;
  const { currentPatient, visibleProfile, siteDesign, prescription } =
    printProps;
  const { FirstName, LastName, GroupId, Creator } = currentPatient;
  const { exercises, perdiem, frequency, instructions } = prescription;

  const pages = generatePages(currLayout, exercises);

  useEffect(() => {
    window.print();

    setTimeout(() => {
      setPrinting(false);
    }, 1000);
  }, []);

  const printRef = useRef(null);

  const truncateText = text => {
    const maxLimit = 28;

    if (text.length >= maxLimit) {
      return text.substring(0, maxLimit - 3).trim() + '...';
    } else {
      return text;
    }
  };

  const renderExerciseItem = (exercise, currLocale = 'en') => {
    const { id, groupId } = exercise;
    return (
      <div className={raintreePDF ? '' : 'mb-print-ex-item'}>
        <div className="mb-print-ex-image-wrapper">
          <img
            src={
              exercise.imgBase64 ||
              exercise.image ||
              exercise.imageUrl ||
              `https://d2p805pqn3eul9.cloudfront.net/${groupId}/${id}.jpg`
            }
            onError={e => {
              ReactGA.event({
                category: 'Printout ImageError',
                action: `${groupId}/${id}`,
              });

              const proxy = `https://s3.amazonaws.com/ptwired-exercise-images-prod/${groupId}/${id}.jpg`;

              if (e.target.src === proxy) {
                e.target.src = imagePlaceholder;
              } else {
                e.target.src = proxy;
              }
            }}
            alt="exercise"
          />
        </div>
        <Space
          direction="vertical"
          size={4}
          className="full-width mb-exercise-content-wrapper"
        >
          <Typography.Text strong>
            <TextTranslation
              currLocale={currLocale}
              currText={titleCase(exercise.exerciseName)}
            />
          </Typography.Text>
          <Typography.Text>
            <strong>
              <TextTranslation currLocale={currLocale} currText={'Sets:'} />
            </strong>{' '}
            {exercise.sets} |{' '}
            <strong>
              <TextTranslation currLocale={currLocale} currText={'Reps:'} />
            </strong>{' '}
            {exercise.reps}
            {exercise.hold && (
              <React.Fragment>
                |{' '}
                <strong>
                  <TextTranslation currLocale={currLocale} currText={'Hold:'} />{' '}
                </strong>{' '}
                {exercise.hold}
              </React.Fragment>
            )}{' '}
            {exercise.frequency && (
              <React.Fragment>
                | {exercise.frequency}x per {exercise.perdiem}
              </React.Fragment>
            )}
          </Typography.Text>
          <Typography.Paragraph style={{ fontSize: 12 }}>
            <TextTranslation
              currLocale={currLocale}
              currText={exercise.instructions}
            />
          </Typography.Paragraph>
        </Space>
      </div>
    );
  };

  const renderComponentToPrint = () => {
    return (
      <div className="print-mobile" ref={printRef}>
        <section className="first-page">
          <Row gutter={[16, 16]} className="header-container">
            <Col lg={12} md={12} sm={12} xs={12}>
              <Space direction="vertical" size={18}>
                <img src={siteDesign.logoUrl} alt="logo" />
                <Space direction="vertical" size={2}>
                  <Typography.Text>
                    <strong>
                      <TextTranslation
                        currLocale={currLocale}
                        currText={'Created for: '}
                      />
                    </strong>
                    {V.titleCase(FirstName)} {V.titleCase(LastName)}
                  </Typography.Text>

                  {visibleProfile && (
                    <Typography.Text>
                      <strong>
                        <TextTranslation
                          currLocale={currLocale}
                          currText={'Prepared by: '}
                        />
                      </strong>
                      <Typography.Text style={{ textDecoration: 'capitalize' }}>
                        {truncateText(
                          `${visibleProfile.FirstName} ${visibleProfile.LastName}`
                        )}
                      </Typography.Text>
                    </Typography.Text>
                  )}
                </Space>
              </Space>
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <PDFFooter
                currLocale={currLocale}
                currEmail={currEmail}
                currPhone={currPhone}
              />
            </Col>
          </Row>
          <Row
            gutter={[16, 16]}
            className="home-exercise-program-container"
            style={
              GroupId &&
              GroupId === 'b592cd97-5fb7-4d73-b4de-255af54d7df9' && {
                marginTop: 40,
              }
            }
          >
            <Col lg={24} md={24} sm={24} xs={24}>
              <Typography.Title level={3}>
                <TextTranslation
                  currLocale={currLocale}
                  currText={'Home Exercise Program'}
                />
              </Typography.Title>
              <Space direction="vertical" size={12}>
                {exercises.length !== 0 && !exercises[0].frequency && (
                  <Space direction="vertical" size={2}>
                    <Typography.Text strong>
                      {' '}
                      <TextTranslation
                        currLocale={currLocale}
                        currText={'Frequency'}
                      />
                    </Typography.Text>
                    <Typography.Text>{`${frequency}x per ${perdiem}`}</Typography.Text>
                  </Space>
                )}
                <Space direction="vertical" size={2}>
                  <Typography.Text strong>
                    {' '}
                    <TextTranslation
                      currLocale={currLocale}
                      currText={'Instructions'}
                    />
                  </Typography.Text>
                  <Typography.Text>{instructions}</Typography.Text>
                </Space>
              </Space>
            </Col>
          </Row>
          {GroupId && GroupId !== 'b592cd97-5fb7-4d73-b4de-255af54d7df9' && (
            <Fragment>
              <Row gutter={[16, 16]} className="app-features-container">
                <Col lg={24} md={24} sm={24} xs={24}>
                  <Typography.Title level={4}>
                    <TextTranslation
                      currLocale={currLocale}
                      currText={'You can also access on our mobile app!'}
                    />
                  </Typography.Title>
                  <Typography.Text>
                    <TextTranslation
                      currLocale={currLocale}
                      currText={'By using your app you can access...'}
                    />
                  </Typography.Text>
                  <Row gutter={[16, 16]} style={{ marginTop: 12 }}>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <Space direction="vertical" size={16}>
                        <Space size={16} className="full-width">
                          <YoutubeFilled style={{ fontSize: 24 }} />
                          <Typography.Text strong>
                            <TextTranslation
                              currLocale={currLocale}
                              currText={'HD Exercise Videos'}
                            />
                          </Typography.Text>
                        </Space>
                        <Space size={16} className="full-width">
                          <TrophyFilled style={{ fontSize: 24 }} />
                          <Typography.Text strong>
                            <TextTranslation
                              currLocale={currLocale}
                              currText={'Earn Achievements'}
                            />
                          </Typography.Text>
                        </Space>
                      </Space>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <Space direction="vertical" size={16}>
                        <Space size={16} className="full-width">
                          <MessageFilled style={{ fontSize: 24 }} />
                          <Typography.Text strong>
                            <TextTranslation
                              currLocale={currLocale}
                              currText={'Instant Messaging'}
                            />
                          </Typography.Text>
                        </Space>
                        <Space size={16} className="full-width">
                          <PhoneFilled style={{ fontSize: 24 }} />
                          <Typography.Text strong>
                            <TextTranslation
                              currLocale={currLocale}
                              currText={'Much More!'}
                            />
                          </Typography.Text>
                        </Space>
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="access-instructions-container">
                <Col lg={24} md={24} sm={24} xs={24}>
                  <Space direction="vertical" size={2}>
                    <Typography.Title level={4}>
                      <TextTranslation
                        currLocale={currLocale}
                        currText={'How do I access?'}
                      />
                    </Typography.Title>
                    <Typography.Text>
                      <TextTranslation
                        currLocale={currLocale}
                        currText={`
                            1. Check your email for an email from admin@ptwired.com.
                            Open the email.
                            `}
                      />
                    </Typography.Text>
                    <Typography.Text>
                      <TextTranslation
                        currLocale={currLocale}
                        currText={`2. Select 'Activate My Account'.`}
                      />
                    </Typography.Text>
                    <Typography.Text>
                      <TextTranslation
                        currLocale={currLocale}
                        currText={'3. Set your password.'}
                      />
                    </Typography.Text>
                    <Typography.Text>
                      <TextTranslation
                        currLocale={currLocale}
                        currText={`
                            4. You'll be linked to our App Store page to download
                            our app and start accessing! You can also access via web
                            on your computer.
                          `}
                      />
                    </Typography.Text>
                  </Space>
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="trouble-accessing-container">
                <Col lg={24} md={24} sm={24} xs={24}>
                  <Space direction="vertical" size={2}>
                    <Typography.Title level={4}>
                      <TextTranslation
                        currLocale={currLocale}
                        currText={`Having trouble accessing?`}
                      />
                    </Typography.Title>
                    <Typography.Text>
                      <TextTranslation
                        currLocale={currLocale}
                        currText={`Email us at admin@ptwired.com and we'll assist you.`}
                      />
                    </Typography.Text>
                  </Space>
                </Col>
              </Row>
            </Fragment>
          )}
        </section>

        {pages.map((exercisePage, i) => {
          const colSpan = currLayout === 'A' ? 12 : 24;
          return (
            <Fragment key={i}>
              <Row
                gutter={[24, 24]}
                className={`mb-exercise-page ${currLayout}`}
                id={`p-${i - 1}`}
              >
                {exercisePage.map((exercise, j) => (
                  <Col
                    lg={colSpan}
                    md={colSpan}
                    sm={colSpan}
                    xs={colSpan}
                    key={`${i - 1}-${j}`}
                  >
                    {renderExerciseItem(exercise, currLocale)}
                  </Col>
                ))}
              </Row>
            </Fragment>
          );
        })}
      </div>
    );
  };

  if (back) {
    return (
      <Redirect
        to={{
          pathname: `/patients/${currentPatient.Sub}`,
        }}
      />
    );
  }

  return (
    <div>
      <Helmet>
        <style type="text/css">{`
          #launcher {
            display: none !important;
          }
        `}</style>
      </Helmet>
      {!printing && (
        <Space size={8}>
          <Button
            type="ghost"
            size="large"
            icon={<ArrowLeftOutlined />}
            onClick={() => setBack(true)}
          >
            Back
          </Button>
          <ReactToPrint
            onBeforeGetContent={() => {
              document.title = `${currentPatient.FirstName}_${currentPatient.LastName}`;
              setPrinting(true);
            }}
            onAfterPrint={() => {
              document.title = 'PT Wired';

              setTimeout(() => {
                setPrinting(false);
              }, 2500);
            }}
            removeAfterPrint={true}
            content={() => printRef.current}
            trigger={() => (
              <Button type="primary" size="large" icon={<PrinterOutlined />}>
                Print
              </Button>
            )}
          />
        </Space>
      )}

      {renderComponentToPrint()}
    </div>
  );
};

export default MobilePrintPreview;

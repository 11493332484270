import apiFetch from '../utils/fetch.utils';
import states from '../states';
import { getRecoil } from 'recoil-nexus';

export const searchPatients = async term => {
  try {
    const groupsState = getRecoil(states.groups);
    const { id, name } = groupsState.user;

    const uri = `groups/${name}/patients?query=${term}&groupId=${id}&action=QUERY_BY_NAME`;
    const method = 'GET';

    return apiFetch(uri, method);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const searchBySub = async (sub, groupName = null) => {
  try {
    const groupsState = getRecoil(states.groups);
    const name = groupName || groupsState.user.name;

    const uri = `groups/${name}/patients?action=QUERY_BY_SUB&sub=${sub}&index=patients`;
    const method = 'GET';

    return apiFetch(uri, method);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getPatients = (groupId, query, status) => {
  try {
    const method = 'GET';
    const uri = `users?groupId=${encodeURIComponent(groupId)}&query=${query}${
      status ? `&status=${status}` : ''
    }`;

    return apiFetch(uri, method);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getPatientDetails = (query, input) => {
  try {
    const uri = `users?${query}=${encodeURIComponent(input)}&role=p`;
    const method = 'GET';

    return apiFetch(uri, method);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

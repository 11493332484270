import { FULFILLED } from 'redux-promise-middleware';
import { GET_PROFILE } from '../../membership/actions';
import {
  UPDATE_PATIENT_PROFILE,
  CHECK_COMPLETE_EXERCISE
} from '../../mobile/actions/patient';
import {
  UPDATE_USER_PROFILE,
  UPDATE_CORNER_PHOTO
} from '../../users/actions/users';

import m from 'moment';

export default (state = {}, action) => {
  switch (action.type) {
    case `${GET_PROFILE}_${FULFILLED}`:
      const profile = action.payload;
      if (profile.Role === 'p' && profile.Prescriptions) {
        profile.Prescriptions = profile.Prescriptions.filter(item => {
          if (item.hasOwnProperty('Active') && !item.Active) {
            return false;
          }

          return true;
        });
      }

      return { ...state, ...profile };

    case `${UPDATE_PATIENT_PROFILE}_${FULFILLED}`:
      return {
        ...state,
        ...action.payload,
        Prescription: {
          ...action.payload.Prescription,
          exercises: state.Prescription.exercises
        }
      };

    case `${UPDATE_USER_PROFILE}_${FULFILLED}`:
      const { Preferences } = action.payload;
      return {
        ...state,
        isFetching: false,
        fetched: true,
        Preferences: Preferences
      };

    case UPDATE_CORNER_PHOTO:
      const { payload } = action;
      return {
        ...state,
        isFetching: false,
        fetched: true,
        imgUrl: payload
      };

    case CHECK_COMPLETE_EXERCISE:
      const { Prescription } = state;
      const { index } = action.payload;
      const exerciseToUpdate = Prescription.exercises[index];
      if (exerciseToUpdate) {
        const updatedExercise = {
          ...exerciseToUpdate,
          completionDates: {
            ...exerciseToUpdate.completionDates,
            [m().format('MM/DD/YYYY')]: true
          }
        };

        return {
          ...state,
          Prescription: {
            ...Prescription,
            exercises: [
              ...Prescription.exercises.slice(0, index),
              updatedExercise,
              ...Prescription.exercises.slice(index + 1)
            ]
          }
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};

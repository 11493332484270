import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import isURL from 'validator/lib/isURL';

import { Typography, Space } from 'antd';
import { titleCase } from '../../../groups/stringHelpers';

import imagePlaceholder from '../../../../img/image-placeholder.png';
import services from '../../../../services';

const ExerciseItem = ({ pageContents, exercise, raintreePDF }) => {
  const { groupId, id, imgBase64, image, imageUrl } = exercise;

  const imageLink = `https://d2p805pqn3eul9.cloudfront.net/${groupId}/${id}.jpg`;
  const proxyLink = `https://s3.amazonaws.com/ptwired-exercise-images-prod/${groupId}/${id}.jpg`;
  const qryParams = '?withheaders=yes';
  const imgSource = imgBase64 || image || imageUrl || imageLink + qryParams;

  const [imageSrc, setImageSrc] = useState(imgSource);

  useEffect(() => {
    if (!!raintreePDF && isURL(imageSrc)) {
      if (
        imageSrc.includes('d2p805pqn3eul9') &&
        !imageSrc.includes(qryParams)
      ) {
        setImageSrc(imgSource + qryParams);
      }

      if (imageSrc.includes('youtube') || imageSrc.includes('vimeo')) {
        getImageBase64(imageSrc);
      }
    }
  }, []);

  const getImageBase64 = async (url) => {
    try {
      await services.exercises.getExerciseImage(url).then((res) => {
        if (res.status === 200) {
          setImageSrc(res.data.base64);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const renderImage = () => {
    if (!!raintreePDF) {
      return (
        <div
          style={{
            marginBottom: '1em',
            height: '200px',
            width: '300px',
            border: '1px solid grey',
            overflow: 'hidden'
          }}
        >
          <img
            id={'img-' + id}
            alt="exercise"
            crossOrigin="anonymous"
            src={imageSrc}
            onError={(e) => {
              ReactGA.event({
                category: 'ImageError',
                action: `${groupId}/${id}`
              });

              const withQuery = proxyLink + qryParams;
              if (e.target.src === withQuery) {
                e.target.src = imagePlaceholder;
              } else {
                e.target.src = withQuery;
              }
            }}
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'contain'
            }}
          />
        </div>
      );
    }

    return (
      <div className="print-ex-image-wrapper">
        <img
          id={'img-' + id}
          alt="exercise"
          src={imgSource.replace(qryParams, '')}
          onError={(e) => {
            ReactGA.event({
              category: 'ImageError',
              action: `${groupId}/${id}`
            });

            if (e.target.src === proxyLink) {
              e.target.src = imagePlaceholder;
            } else {
              e.target.src = proxyLink;
            }
          }}
        />
      </div>
    );
  };

  return (
    <div className={raintreePDF ? '' : 'print-ex-item'}>
      {renderImage()}

      <Space direction="vertical" size={12} className="full-width">
        <Typography.Text strong>
          {titleCase(exercise.exerciseName)}
        </Typography.Text>
        <Typography.Text>
          <strong>{pageContents.sets}</strong> {exercise.sets} |{' '}
          <strong>{pageContents.reps}</strong> {exercise.reps}
          {exercise.hold && (
            <React.Fragment>
              | <strong>{pageContents.hold}</strong> {exercise.hold}
            </React.Fragment>
          )}{' '}
          {exercise.frequency && (
            <React.Fragment>
              | {exercise.frequency}x {pageContents.per} {exercise.perdiem}
            </React.Fragment>
          )}
        </Typography.Text>
        <Typography.Paragraph style={{ fontSize: 12 }}>
          {exercise.instructions}
        </Typography.Paragraph>
      </Space>
    </div>
  );
};

export default ExerciseItem;

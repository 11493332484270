import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import V from 'voca';
import {
  Dropdown,
  Form,
  List,
  Button,
  Space,
  Tooltip,
  Input,
  Card,
} from 'antd';
import {
  RightOutlined,
  StarFilled,
  StarOutlined,
  TagOutlined,
} from '@ant-design/icons';
import { Checkmark } from 'react-ionicons';
import { useRecoilValue } from 'recoil';

import imagePlaceholder from '../../../img/image-placeholder.png';
import services from '../../../services';
import states from '../../../states';

const ExerciseListItem = ({
  exercise,
  faveLoading,
  tagLoading,
  fromPatientPrescription,
  handleExerciseDetailsModal,
  handleSelectExercise,
  handleAddFavorite,
  handleRemoveFavorite,
  handleAddTag,
}) => {
  const { star, isSelected, exerciseName, groupId, id } = exercise;

  const exercises = useRecoilValue(states.exercises);
  const favorites = useRecoilValue(states.favorites);

  const [hovering, setHovering] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const [form] = Form.useForm();

  const item = favorites.list.find(fav => fav.id === id);
  const isFavorite = item ? true : !!star;

  useEffect(() => {
    if (dropdown) {
      form?.resetFields();
    }
  }, [dropdown]);

  const handleSubmit = async values => {
    const unmapped = [...exercises.unmapped];
    const exercise = unmapped.find(ex => ex.id === id);

    let name = exerciseName;
    if (exercise) {
      name = exercise.exerciseName;
    } else {
      await services.exercises.getExerciseDetails(id).then(res => {
        if (res.data) {
          name = res.data.ExerciseName;
        }
      });
    }

    await handleAddTag(id, name, exerciseName, values.tag).then(() => {
      form?.resetFields();
    });
  };

  return (
    <div
      onMouseOver={() => setHovering(true)}
      onMouseOut={() => setHovering(false)}
    >
      <List.Item
        className={`ptw-exercise-list-item ${
          isSelected ? 'exercise-selected' : ''
        }`}
      >
        <List.Item.Meta
          avatar={
            <div className="ptw-list-image-wrapper">
              <Space
                direction="horizontal"
                className="ptw-card-action-wrapper"
                size={2}
              >
                <Dropdown
                  open={dropdown}
                  onOpenChange={open => setDropdown(open)}
                  menu={{ items: [] }}
                  arrow
                  destroyPopupOnHide
                  placement="topRight"
                  trigger={['click']}
                  dropdownRender={() => (
                    <Card
                      style={{
                        borderWidth: 0,
                        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                        padding: 0,
                        paddingTop: 14,
                      }}
                    >
                      <Button
                        style={{ position: 'absolute', top: 4, right: 4 }}
                        type="text"
                        shape="circle"
                        icon={<CloseOutlined />}
                        onClick={() => setDropdown(false)}
                      />
                      <Form form={form} layout="inline" onFinish={handleSubmit}>
                        <Form.Item label="Tag" name="tag">
                          <Input placeholder="Request a Tag" name="tag" />
                        </Form.Item>
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={tagLoading}
                          >
                            Save
                          </Button>
                        </Form.Item>
                      </Form>
                    </Card>
                  )}
                >
                  <Tooltip title="Request Tag">
                    <Button
                      size="small"
                      type="link"
                      shape="circle"
                      icon={<TagOutlined className="ptw-card-favorites-icon" />}
                      className={
                        hovering || dropdown
                          ? 'ptw-card-tag-btn-show'
                          : 'ptw-card-tag-btn-hide'
                      }
                    />
                  </Tooltip>
                </Dropdown>

                <Tooltip
                  title={star ? 'Remove from Favorites' : 'Add to Favorites'}
                >
                  <Button
                    type="link"
                    shape="circle"
                    loading={faveLoading}
                    onClick={() => {
                      if (isFavorite) {
                        handleRemoveFavorite(id);
                      } else {
                        handleAddFavorite(id, groupId);
                      }
                    }}
                    icon={
                      isFavorite ? (
                        <StarFilled className="ptw-card-favorites-icon" />
                      ) : (
                        <StarOutlined className="ptw-card-favorites-icon" />
                      )
                    }
                    className={
                      hovering || dropdown || isFavorite
                        ? 'ptw-card-favorites-btn-show'
                        : 'ptw-card-favorites-btn-hide'
                    }
                  />
                </Tooltip>
              </Space>

              {isSelected && (
                <React.Fragment>
                  <Checkmark
                    onClick={() => {
                      if (handleSelectExercise) {
                        handleSelectExercise(exercise);
                      }
                    }}
                    className="ptw-list-select-icon"
                  />
                  <div
                    onClick={() => {
                      if (handleSelectExercise) {
                        handleSelectExercise(exercise);
                      }
                    }}
                    className="ptw-list-image-mask"
                  />
                </React.Fragment>
              )}
              <img
                onClick={() => {
                  if (handleSelectExercise) {
                    handleSelectExercise(exercise);
                  }
                }}
                alt="exercise"
                src={
                  exercise.imgBase64 ||
                  exercise.image ||
                  exercise.imageUrl ||
                  `https://d2p805pqn3eul9.cloudfront.net/${groupId}/${id}.jpg`
                }
                onError={e => {
                  ReactGA.event({
                    category: 'ImageError',
                    action: `${groupId}/${id}`,
                  });
                  const proxy = `https://s3.amazonaws.com/ptwired-exercise-images-prod/${groupId}/${id}.jpg`;
                  if (e.target.src === proxy) {
                    e.target.src = imagePlaceholder;
                  } else {
                    e.target.src = proxy;
                  }
                }}
              />
            </div>
          }
          title={
            <div
              className="exercise-label-container"
              onClick={() => {
                if (handleExerciseDetailsModal) {
                  handleExerciseDetailsModal(exercise);
                }
              }}
            >
              <span className="exercise-name">{V.titleCase(exerciseName)}</span>
              <span className="exercise-preview">
                Preview <RightOutlined />
              </span>
            </div>
          }
        />
      </List.Item>
    </div>
  );
};

export default ExerciseListItem;

import React, { useState, useEffect, useRef, Fragment } from 'react';
import {
  Col,
  Input,
  Row,
  Select,
  Typography,
  Form,
  Checkbox,
  Popover,
  Button
} from 'antd';
import ReactGA from 'react-ga';

import imagePlaceholder from '../../../img/image-placeholder.png';
import { useRecoilState, useSetRecoilState } from 'recoil';
import states from '../../../states';
import services from '../../../services';
import { UndoOutlined } from '@ant-design/icons';

const TemplateFieldData = ({
  isExerciseFrequency,
  exercise,
  onChange,
  initialValues,
  checkedExercises,
  handleCheckExerciseItem
}) => {
  const [usedCustomParams, setUsedCustomParams] = useState(true);
  const [exercises, setExercises] = useRecoilState(states.exercises);

  const setPrescription = useSetRecoilState(states.prescription);

  const prevExercise = useRef();
  const [form] = Form.useForm();

  const id = exercise.id;
  const customParams = exercises.custom.parameter.find(
    (p) => p.ExerciseId === exercise.id
  );

  useEffect(() => {
    const unmapped = exercises.unmapped;
    const item = unmapped.find((ex) => ex.id === exercise.id);

    if (!item) {
      getExerciseDetails();
    }
  }, []);

  useEffect(() => {
    prevExercise.current = exercise;

    if (prevExercise !== exercise) {
      if (checkedExercises.length !== 0) {
        form.setFieldsValue({
          sets: exercise.sets,
          reps: exercise.reps,
          hold: exercise.hold,
          instructions: exercise.instructions,
          frequency: isExerciseFrequency
            ? exercise.frequency || initialValues.Frequency
            : 1,
          perdiem: isExerciseFrequency
            ? exercise.perdiem || initialValues.Perdiem
            : 'day'
        });
      }
    }
  }, [exercise, checkedExercises]);

  const getExerciseDetails = async () => {
    try {
      const { id, groupId } = exercise;
      const response = await services.exercises.getExerciseDetails(id, groupId);

      if (response.status === 200 && response.data) {
        const item = {
          ...exercise,
          ...toCamelCaseObjKeys(response.data)
        };

        setExercises((prevState) => ({
          ...prevState,
          unmapped: [...prevState.unmapped, item]
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSet = (val) => {
    //eslint-disable-next-line
    let formattedVal = val.replace(/[^0-9\-]/g, '');
    onChange(id, 'sets', formattedVal);
  };

  const handleRep = (val) => {
    //eslint-disable-next-line
    let formattedVal = val.replace(/[^0-9\-]/g, '');
    onChange(id, 'reps', formattedVal);
  };

  const handleUseDefaultParams = () => {
    const unmapped = exercises.unmapped;
    const item = unmapped.find((ex) => ex.id === exercise.id);

    setUsedCustomParams(false);
    setPrescription((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        prescription: {
          ...prevState.form.prescription,
          exercises: prevState.form.prescription.exercises.map((ex) => {
            if (item?.id === ex.id) {
              return item;
            }

            return ex;
          })
        }
      }
    }));

    form.setFieldsValue({
      sets: item?.sets || '',
      reps: item?.reps || '',
      hold: item?.hold || '',
      instructions: item?.instructions || ''
    });
  };

  const handleUseCustomParams = () => {
    setUsedCustomParams(true);
    setPrescription((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        prescription: {
          ...prevState.form.prescription,
          exercises: prevState.form.prescription.exercises.map((ex) => {
            if (customParams?.ExerciseId === ex.id) {
              return {
                ...ex,
                exerciseName: customParams.ExerciseName,
                instructions: customParams?.Instructions || '',
                sets: customParams?.Sets || '',
                reps: customParams?.Reps || '',
                hold: customParams?.Hold || ''
              };
            }

            return ex;
          })
        }
      }
    }));

    form.setFieldsValue({
      sets: customParams?.Sets || '',
      reps: customParams?.Reps || '',
      hold: customParams?.Hold || '',
      instructions: customParams?.Instructions || ''
    });
  };

  const renderContent = () => {
    return (
      <div style={{ width: 450 }}>
        <Typography.Text>
          {usedCustomParams
            ? 'This exercise includes previously saved edits to name, instructions and/or parameters. Click this to restore the default values of the exercise.'
            : 'This exercise is using the default name, instructions, and parameters. Click here to re-apply your saved custom edits to the exercise.'}
        </Typography.Text>
      </div>
    );
  };

  const frequency = isExerciseFrequency
    ? exercise.frequency || initialValues.Frequency
    : 1;
  const perdiem = isExerciseFrequency
    ? exercise.perdiem || initialValues.Perdiem
    : 'day';

  const renderTabletView = (
    <div className="customize-exercise-item hide-dt show-lg-tablet">
      <Row gutter={[12, 12]}>
        <Col lg={1} md={1}>
          <Checkbox
            onChange={() => handleCheckExerciseItem(exercise.id)}
            checked={checkedExercises.includes(exercise.id)}
          />
        </Col>
        <Col lg={23} md={23}>
          <Typography.Title level={5}>
            {exercise.exerciseName}{' '}
          </Typography.Title>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col lg={7} md={7} sm={24} xs={24} className="center-content">
          <img
            style={{
              width: '100%',
              aspectRatio: 16 / 10
            }}
            className="exercise-thumbnail"
            alt="exercise"
            src={
              exercise.imgBase64 ||
              exercise.image ||
              exercise.imageUrl ||
              `https://d2p805pqn3eul9.cloudfront.net/${exercise.groupId}/${exercise.id}.jpg`
            }
            onError={(e) => {
              ReactGA.event({
                category: 'ImageError',
                action: `${exercise.groupId}/${exercise.id}`
              });
              const proxy = `https://s3.amazonaws.com/ptwired-exercise-images-prod/${exercise.groupId}/${exercise.id}.jpg`;
              if (e.target.src === proxy) {
                e.target.src = imagePlaceholder;
              } else {
                e.target.src = proxy;
              }
            }}
          />
        </Col>
        <Col lg={17} md={17} sm={24} xs={24}>
          <Form form={form} layout="vertical">
            <Row gutter={[16, 16]}>
              <Col lg={12} md={24} sm={24}>
                <Typography.Text>
                  Parameters{}
                  {!!customParams && (
                    <Popover placement="top" content={renderContent()}>
                      <Button
                        style={{ marginLeft: 4 }}
                        shape="circle"
                        type={usedCustomParams ? 'default' : 'primary'}
                        icon={<UndoOutlined />}
                        onClick={() =>
                          usedCustomParams
                            ? handleUseDefaultParams()
                            : handleUseCustomParams()
                        }
                      />
                    </Popover>
                  )}
                </Typography.Text>
                <Row
                  gutter={[8, 8]}
                  className="mt-1"
                  style={{ marginBottom: -8 }}
                >
                  <Col lg={8} md={8} sm={8} xs={8}>
                    <Form.Item name="sets">
                      <Input
                        size="large"
                        placeholder="Enter sets"
                        defaultValue={
                          /*eslint-disable-next-line*/
                          Number.isInteger(parseInt(exercise.sets + ''))
                            ? exercise.sets
                            : 1
                        }
                        value={exercise.sets}
                        onChange={(e) => handleSet(e.target.value)}
                        type="text"
                        name="sets"
                        suffix="sets"
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={8} sm={8} xs={8}>
                    <Form.Item name="reps">
                      <Input
                        size="large"
                        placeholder="Enter reps"
                        defaultValue={
                          /*eslint-disable-next-line*/
                          Number.isInteger(parseInt(exercise.reps + ''))
                            ? exercise.reps
                            : 1
                        }
                        value={exercise.reps}
                        onChange={(e) => handleRep(e.target.value)}
                        type="text"
                        name="reps"
                        suffix="reps"
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={8} sm={8} xs={8}>
                    <Form.Item name="hold">
                      <Input
                        size="large"
                        placeholder="Enter hold"
                        value={exercise.hold}
                        defaultValue={exercise.hold ? exercise.hold : ' '}
                        onChange={(e) => onChange(id, 'hold', e.target.value)}
                        type="text"
                        name="hold"
                        suffix="hold"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {isExerciseFrequency && (
                  <React.Fragment>
                    <Typography.Text>Prescription Frequency</Typography.Text>
                    <div className="mt-1">
                      <Select
                        size="large"
                        onChange={(val) => {
                          // setEventFrom('frequency');
                          onChange(id, 'frequency', val);
                        }}
                        value={frequency}
                        defaultValue={frequency}
                        virtual={false}
                        name="frequency"
                        style={{ minWidth: 120 }}
                      >
                        <Select.Option value={1}>1x</Select.Option>
                        <Select.Option value={2}>2x</Select.Option>
                        <Select.Option value={3}>3x</Select.Option>
                        <Select.Option value={4}>4x</Select.Option>
                        <Select.Option value={5}>5x</Select.Option>
                        <Select.Option value={6}>6x</Select.Option>
                        <Select.Option value={7}>7x</Select.Option>
                        <Select.Option value={8}>8x</Select.Option>
                        <Select.Option value={9}>9x</Select.Option>
                        <Select.Option value={10}>10x</Select.Option>
                      </Select>
                      <span className="ml-2 mr-2">Every</span>
                      <Select
                        size="large"
                        onChange={(val) => {
                          // setEventFrom('perdiem');
                          onChange(id, 'perdiem', val);
                        }}
                        value={perdiem}
                        virtual={false}
                        name="perdiem"
                        style={{ minWidth: 120 }}
                      >
                        <Select.Option value={'day'}>Day</Select.Option>
                        <Select.Option value={'week'}>Week</Select.Option>
                        <Select.Option value={'month'}>Month</Select.Option>
                      </Select>
                    </div>
                  </React.Fragment>
                )}
              </Col>

              <Col lg={12} md={24} sm={24} xs={24}>
                <Form.Item
                  name="instructions"
                  label="Instructions"
                  rules={[
                    {
                      validator: async (_, value) => {
                        if (!value.length) {
                          onChange(id, 'formError', true);
                          return Promise.reject(
                            'Please input your instructions.'
                          );
                        }

                        if (value.length > 1000) {
                          onChange(id, 'formError', true);
                          return Promise.reject(
                            'Max length is 1000 characters.'
                          );
                        }

                        onChange(id, 'formError', false);
                        return Promise.resolve();
                      }
                    }
                  ]}
                >
                  <Input.TextArea
                    defaultValue={exercise.instructions}
                    value={exercise.instructions ? exercise.instructions : ' '}
                    onInput={(e) =>
                      onChange(id, 'instructions', e.target.value)
                    }
                    size="large"
                    placeholder="Enter instructions"
                    rows={4}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );

  return (
    <Fragment>
      {renderTabletView}
      <div className="customize-exercise-item hide-lg-tablet">
        <Row gutter={[16, 16]} style={{ marginTop: -5, marginBottom: -5 }}>
          <Col lg={1} md={1} />
          <Col lg={23} md={23}>
            <Typography.Title level={5}>
              {exercise.exerciseName}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[32, 32]}>
          <Col lg={1} md={1} className="center-content">
            <Checkbox
              onChange={() => handleCheckExerciseItem(exercise.id)}
              checked={checkedExercises.includes(exercise.id)}
            />
          </Col>
          <Col lg={5} md={5} className="center-content">
            <img
              style={{
                width: '100%',
                aspectRatio: 16 / 10
              }}
              className="exercise-thumbnail"
              alt="exercise"
              src={
                exercise.imgBase64 ||
                exercise.image ||
                exercise.imageUrl ||
                `https://d2p805pqn3eul9.cloudfront.net/${exercise.groupId}/${exercise.id}.jpg`
              }
              onError={(e) => {
                ReactGA.event({
                  category: 'ImageError',
                  action: `${exercise.groupId}/${exercise.id}`
                });
                const proxy = `https://s3.amazonaws.com/ptwired-exercise-images-prod/${exercise.groupId}/${exercise.id}.jpg`;
                if (e.target.src === proxy) {
                  e.target.src = imagePlaceholder;
                } else {
                  e.target.src = proxy;
                }
              }}
            />
          </Col>
          <Col lg={18} md={18}>
            <Form form={form} layout="vertical">
              <Row gutter={[16, 16]}>
                <Col lg={12} md={12}>
                  <Typography.Text>
                    Parameters{}
                    {!!customParams && (
                      <Popover placement="top" content={renderContent()}>
                        <Button
                          style={{ marginLeft: 4 }}
                          shape="circle"
                          type={usedCustomParams ? 'default' : 'primary'}
                          icon={<UndoOutlined />}
                          onClick={() =>
                            usedCustomParams
                              ? handleUseDefaultParams()
                              : handleUseCustomParams()
                          }
                        />
                      </Popover>
                    )}
                  </Typography.Text>
                  <Row
                    gutter={[8, 8]}
                    className="mt-1"
                    style={{ marginBottom: -8 }}
                  >
                    <Col lg={8} md={8}>
                      <Form.Item name="sets">
                        <Input
                          size="large"
                          placeholder="Enter sets"
                          defaultValue={
                            /*eslint-disable-next-line*/
                            Number.isInteger(parseInt(exercise.sets + ''))
                              ? exercise.sets
                              : 1
                          }
                          value={exercise.sets}
                          onChange={(e) => handleSet(e.target.value)}
                          type="text"
                          name="sets"
                          suffix="sets"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={8}>
                      <Form.Item name="reps">
                        <Input
                          size="large"
                          placeholder="Enter reps"
                          defaultValue={
                            /*eslint-disable-next-line*/
                            Number.isInteger(parseInt(exercise.reps + ''))
                              ? exercise.reps
                              : 1
                          }
                          value={exercise.reps}
                          onChange={(e) => handleRep(e.target.value)}
                          type="text"
                          name="reps"
                          suffix="reps"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={8}>
                      <Form.Item name="hold">
                        <Input
                          size="large"
                          placeholder="Enter hold"
                          value={exercise.hold}
                          defaultValue={exercise.hold ? exercise.hold : ' '}
                          onChange={(e) => onChange(id, 'hold', e.target.value)}
                          type="text"
                          name="hold"
                          suffix="hold"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {isExerciseFrequency && (
                    <React.Fragment>
                      <Typography.Text>Prescription Frequency</Typography.Text>
                      <div className="mt-1">
                        <Select
                          size="large"
                          onChange={(val) => {
                            // setEventFrom('frequency');
                            onChange(id, 'frequency', val);
                          }}
                          value={frequency}
                          defaultValue={frequency}
                          virtual={false}
                          name="frequency"
                          style={{ minWidth: 120 }}
                        >
                          <Select.Option value={1}>1x</Select.Option>
                          <Select.Option value={2}>2x</Select.Option>
                          <Select.Option value={3}>3x</Select.Option>
                          <Select.Option value={4}>4x</Select.Option>
                          <Select.Option value={5}>5x</Select.Option>
                          <Select.Option value={6}>6x</Select.Option>
                          <Select.Option value={7}>7x</Select.Option>
                          <Select.Option value={8}>8x</Select.Option>
                          <Select.Option value={9}>9x</Select.Option>
                          <Select.Option value={10}>10x</Select.Option>
                        </Select>
                        <span className="ml-2 mr-2">Every</span>
                        <Select
                          size="large"
                          onChange={(val) => {
                            // setEventFrom('perdiem');
                            onChange(id, 'perdiem', val);
                          }}
                          value={perdiem}
                          virtual={false}
                          name="perdiem"
                          style={{ minWidth: 120 }}
                        >
                          <Select.Option value={'day'}>Day</Select.Option>
                          <Select.Option value={'week'}>Week</Select.Option>
                          <Select.Option value={'month'}>Month</Select.Option>
                        </Select>
                      </div>
                    </React.Fragment>
                  )}
                </Col>

                <Col lg={12} md={12}>
                  <Form.Item
                    name="instructions"
                    label="Instructions"
                    rules={[
                      {
                        validator: async (_, value) => {
                          if (!value.length) {
                            onChange(id, 'formError', true);
                            return Promise.reject(
                              'Please input your instructions.'
                            );
                          }

                          if (value.length > 1000) {
                            onChange(id, 'formError', true);
                            return Promise.reject(
                              'Max length is 1000 characters.'
                            );
                          }

                          onChange(id, 'formError', false);
                          return Promise.resolve();
                        }
                      }
                    ]}
                  >
                    <Input.TextArea
                      defaultValue={exercise.instructions}
                      value={
                        exercise.instructions ? exercise.instructions : ' '
                      }
                      onInput={(e) =>
                        onChange(id, 'instructions', e.target.value)
                      }
                      size="large"
                      placeholder="Enter instructions"
                      rows={4}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default TemplateFieldData;

import { camelCase } from 'voca';
import { getRecoil } from 'recoil-nexus';
import { keyValueMap } from '../components/ExerciseList/ExerciseFilter';

import * as _ from 'lodash';
import states from '../states';

export const calculateHalfChecked = (keys) => {
  let halfChecked = [];
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const parents = getParents(key);
    halfChecked = halfChecked.concat(parents);
  }
  return halfChecked;
};

export const getParents = (key) => {
  const parents = [];

  for (let i = key.length; i > 3; i--) {
    if (i % 2 === 0) continue;
    parents.push(key.slice(0, i - 2));
  }

  return parents;
};

export const createTag = (node) => {
  const value = keyValueMap[node.key];
  return { title: node.title, key: node.key, value: value ? value : null };
};

export const mapTagsToFilters = (tags) => {
  return tags.map((item) => {
    if (item.value) {
      return item.value;
    } else {
      return item.title;
    }
  });
};

export const filterExercises = function (
  exercises,
  searchTerm,
  unparsedfavorites,
  favoritesSelected,
  tags
) {
  const exercisesState = getRecoil(states.exercises);
  const favorites = parseFavorites(unparsedfavorites);

  const mappedFavorites = [];
  for (let i = 0; i < favorites.length; i++) {
    const favorite = favorites[i];
    const parameter = exercisesState.custom.parameter;
    const params = parameter.find((p) => p.ExerciseId === favorite.id);
    const exercise = exercisesState.list.find((e) => e.id === favorite.id);

    if (!params) {
      mappedFavorites.push(exercise || favorite);
    } else {
      mappedFavorites.push({
        ...favorite,
        exerciseName: params.ExerciseName,
        instructions: params.Instructions || '',
        sets: params.Sets || '',
        reps: params.Reps || '',
        hold: params.Hold || ''
      });
    }
  }

  if (tags && tags.length > 0) {
    /**
     * Show filter results and add/remove favorites
     */
    return checkDupes(favoritesSelected, exercises, mappedFavorites);
  }

  if (favoritesSelected) {
    if (!searchTerm || searchTerm === 'NONE') {
      return mappedFavorites;
    }
    /**
     * Show all favorites, remove non-favorites
     */
    return pull(mappedFavorites, searchTerm);
  } else {
    if (!searchTerm || searchTerm === 'NONE') {
      return combine(mappedFavorites, exercises);
    }

    if (!exercises || exercises.length === 0) {
      return exercises;
    }
    /**
     * Combine all favorites and returned exercises
     */
    return combine(pull(mappedFavorites, searchTerm), exercises);
  }
};

/**
 * Remove isSelected
 * @param exercises
 * @returns {*}
 */
export const clearSelected = (exercises) =>
  exercises.map((ex) => {
    const dupe = { ...ex };
    dupe.isSelected = false;
    return dupe;
  });

/**
 * Return Exercises without Favorites
 * @param tags
 * @param exercises
 * @param favorites
 * @returns {*}
 */
const checkDupes = (favoritesSelected, uncompactedExercises, favorites) => {
  const exercises = _.compact(uncompactedExercises);

  //favorites selected & no favorites
  if (favoritesSelected && favorites.length === 0) {
    return [];
  }

  //no favorites
  if (favorites.length === 0 || exercises.length === 0) {
    return exercises;
  }
  //favorites added - need to remove duplicates
  const foundFavorites = [];
  //iterate through favorites, check to see if it is present.
  // Remove exercises that are present and add them to found favorites

  for (let i = 0; i < favorites.length; i++) {
    const id = favorites[i].id;

    for (let j = 0; j < exercises.length; j++) {
      const exercise = exercises[j];

      if (exercise && exercise.id === id) {
        exercises[j] = null;
        foundFavorites.push(favorites[i]);
      }
    }
  }
  //if only show favorites, only return favorites present in exercises
  if (favoritesSelected) {
    return foundFavorites;
  }
  //else combine favorites first to exercises list, and
  //remove falsey values from exercises and return found favorites
  const exs = _.compact(exercises);
  return [...foundFavorites, ...exs];
};

/**
 * Return list of favorites with search term
 * @param favorites
 * @param searchTerm
 * @returns {*}
 */
const pull = (favorites, searchTerm) =>
  _.filter(favorites, (o) => {
    try {
      return o.exerciseName.includes(searchTerm) || o.tag.includes(searchTerm);
    } catch (err) {
      return false;
    }
  });

/**
 * Combine favorites and exercises
 * @param favorites
 * @param exercises
 * @returns {*}
 */
const combine = (favorites, exercises) => {
  const keys = [];

  if (favorites.length === 0) {
    return exercises;
  }

  for (let i = 0; i < favorites.length; i++) {
    keys.push(favorites[i].id);
  }

  const pulled = _.filter(exercises, (o) => {
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (key === o.id) {
        return false;
      }
    }
    return true;
  });

  return [...favorites, ...pulled];
};
/**
 * Transform favorites array to be client readable
 * @param favoritesArray
 * @returns {Array}
 */
export const parseFavorites = (favoritesArray) => {
  if (!favoritesArray || favoritesArray.length === 0) {
    return [];
  }
  const parsedArray = [];

  for (let i = 0; i < favoritesArray.length; i++) {
    const favorite = favoritesArray[i];

    const parsed = _.mapKeys(favorite, (v, k) => {
      return camelCase(k);
    });
    parsed.star = true;
    parsedArray.push(parsed);
  }

  return parsedArray;
};

export const filterByOnlyClinicExercises = (
  clinicExercisesSelected,
  exercisesList
) => {
  if (!clinicExercisesSelected) {
    return exercisesList;
  }

  return exercisesList.filter((item) => !!item.isGroupExercise);
};

export const filterByOnlyMyExercises = (
  myExercisesSelected,
  exercisesList,
  customExercises
) => {
  if (!myExercisesSelected) {
    return exercisesList;
  }

  return exercisesList.filter((item) =>
    customExercises.some((ex) => ex.Id === item.id)
  );
};

// if exercise is selected/deselected, the isSelected value must update
export const exercisesListWithSelectedExercises = (
  exercisesList,
  groupId,
  selectedExercises,
  customExercises
) => {
  if (!exercisesList) {
    return [];
  }

  return exercisesList.map((item) => {
    const isCustomExercise = customExercises.some((ex) => ex.Id === item.id);
    const isGroupExercise = !groupId ? true : item.groupId === groupId;
    const isSelected = selectedExercises.find((ex) => ex && ex.id === item.id);

    return {
      ...item,
      isGroupExercise: isGroupExercise && !isCustomExercise,
      isSelected: !!isSelected
    };
  });
};

import React, { Component } from 'react';
import { Card, Typography, Space, Tag } from 'antd';
import { TeamOutlined } from '@ant-design/icons';

class ExerciseTemplateCard extends Component {
  constructor(props) {
    super(props);

    this.state = { hover: false };
  }

  setStringEllipsis = input => {
    if (input.length >= 35) {
      return input.substring(0, 35) + '...';
    }

    return input;
  };

  render() {
    const { template, handleCardClick } = this.props;

    if (!template) return null;

    return (
      <Card
        className="ptw-card-item"
        onClick={() => {
          handleCardClick(template);
        }}
      >
        {template.GroupTemplate && (
          <Tag className="group-template-indicator">
            <TeamOutlined style={{ fontSize: 10 }} />
          </Tag>
        )}
        <Space direction="vertical">
          <Typography.Text strong>
            {this.setStringEllipsis(`${template.Name}`)}
          </Typography.Text>
          <Typography.Text type="secondary">
            {this.setStringEllipsis(`Author: ${template.Author}`)}
          </Typography.Text>
        </Space>
      </Card>
    );
  }
}

export default ExerciseTemplateCard;

import {
  FilterOutlined,
  MessageTwoTone,
  PlusOutlined,
  SearchOutlined
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  Dropdown,
  Input,
  Menu,
  notification,
  Pagination,
  Row,
  Space,
  Tabs,
  Tag,
  Tooltip,
  Typography
} from 'antd';
import { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ChevronForward } from 'react-ionicons';

import v from 'voca';
import usePendo from '../../hooks/usePendo';
import services from '../../services';
import states from '../../states';

import '../../features/patients/styles.css';
import '../../styles/PT-Wired.css';
import '../../styles/atomic.css';
import '../../styles/ptw-updated.css';
import '../../styles/ptw-print.css';

import DropdownNavigation from '../../components/DropdownNavigation';
import NoResults from '../../components/NoResults';
import LoadingPage from '../../components/LoadingPage';

import RTMDashboard from '../../features/patients/RTMDashboard';
import PatientExercises from '../../features/patients/PatientExercises';
import ExerciseTemplates from '../../features/patients/ExerciseTemplates';
import ReactiveModal from '../../features/patients/Modals/ReactiveModal';
import ReloadModal from '../../features/patients/Modals/ReloadModal';
import EducationalPDFs from '../EducationalPDFs';
import Messages from '../Messages';

import { debounce, isEqual } from 'lodash';
import {
  clearCurrentMonitoredPatient,
  exitCurrentMonitoredPatient
} from '../../features/monitor-timer/actions';
import { toCamelCaseObjKeys } from '../../utils/object.utils';

const setStringEllipsis = (input) => {
  if (!input) return;

  if (input.length >= 30) {
    return input.substring(0, 30) + '...';
  }

  return input;
};

const Patients = ({
  dispatch,
  history,
  currentMonitoredPatient,
  isMonitoring,
  visibleProfile,
  exportPrescription,
  importedTemplate,
  selectedExercises
}) => {
  const {
    EmailAddress,
    Sub,
    GroupId,
    GroupInfo: { Name, EnableRTM, EnableEducPDF },
    Preferences
  } = visibleProfile;

  const searchHistory = history.location.search;

  const [loadingPatients, setLoadingPatients] = useState(false);
  const [loadingBoards, setLoadingBoards] = useState(false);
  const [loadingAnalytics, setLoadingAnalytics] = useState(false);
  const [showReloadModal, setShowReloadModal] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [search, setSearch] = useState('');
  const [redirectLink, setRedirectLink] = useState('');

  const width = window.screen.width;
  const [showReactiveModal, setShowReactiveModal] = useState(width <= 812);

  const toTemplate = searchHistory === '?toTemplate=true';
  const [tab, setTab] = useState(toTemplate ? 'templates' : 'patients');

  const [pagination, setPagination] = useState({
    pageSize: 24,
    currentPage: 1,
    min: 0,
    max: 0
  });

  const [patientState, setPatientState] = useRecoilState(states.patients);
  const [messages, setMessages] = useRecoilState(states.messages);

  const setRTMState = useSetRecoilState(states.rtm);
  const user = useRecoilValue(states.user);
  const filters = useRecoilValue(states.filters);
  const group = useRecoilValue(states.groups);

  const [m, init] = usePendo();
  useEffect(() => {
    const type = 'hashchange';

    init(EmailAddress, Name);
    window.addEventListener(type, hashListener);

    return () => {
      debouncedSearch.cancel();
      window.removeEventListener(type, hashListener);
    };
  }, []);

  useEffect(() => {
    setPatientState((prevState) => ({
      ...prevState,
      redirect: ''
    }));

    if (patientState.redirect) {
      setRedirectLink(`/patients/${patientState.redirect}`);
    }
  }, [patientState.redirect]);

  useEffect(() => {
    if (currentMonitoredPatient && tab === 'patients') {
      if (isMonitoring) {
        dispatch(exitCurrentMonitoredPatient());
      } else {
        dispatch(clearCurrentMonitoredPatient());
      }
    }
  }, [currentMonitoredPatient]);

  useEffect(() => {
    if (searchHistory && searchHistory !== '?toTemplate=true') {
      const parameter = searchHistory.split('=')[1];
      history.push('/patients/' + parameter);
    } else {
      getPatientsList('*');
      getAnalyticsList();

      setPagination({
        ...pagination,
        min: 0,
        max: pagination.pageSize,
        currentPage: pagination.currentPage
      });
    }
  }, [searchHistory]);

  const debouncedSearch = useRef(
    debounce(async (query, status) => {
      await getPatientsList(query, status).then(() => {
        setPagination({
          ...pagination,
          currentPage: 1,
          min: 0,
          max: pagination.pageSize
        });
      });
    }, 500)
  ).current;

  const hashListener = () => {
    const hash = window.location.hash;
    const path = hash.substring(1);

    if (path === '/patients#patientsTab') {
      history.push('/patients');
      setTab('patients');

      getAnalyticsList();
      getPatientsList('*').then(() => {
        setPagination({
          ...pagination,
          min: 0,
          max: pagination.pageSize,
          currentPage: pagination.currentPage
        });
      });
    }
  };

  const getPatientsList = async (query, status = '') => {
    try {
      setLoadingPatients(true);
      const response = await services.patients.getPatients(
        GroupId,
        query,
        status
      );

      setPatientState((prevState) => ({
        ...prevState,
        list: response.data.map((item) => toCamelCaseObjKeys(item))
      }));
    } catch (error) {
      notification.error({
        message: 'Search Failed',
        description: 'An error occurred while searching patients'
      });
    } finally {
      setLoadingPatients(false);
    }
  };

  const getAnalyticsList = async () => {
    try {
      setLoadingAnalytics(true);

      let providers = [Sub];
      if (filters) {
        if (Array.isArray(filters.RTM) && filters.RTM.length) {
          providers = filters.RTM;
        }
      }

      const activeAdmins = group.providers.filter(
        ({ Active }) => Active === true || Active === 'true'
      );
      const providerFilters = isEqual(
        [...providers].sort(),
        [...activeAdmins.map((p) => p.Sub)].sort()
      )
        ? []
        : providers;

      const response = await services.rtm.getAnalytics(providerFilters, {
        id: GroupId,
        name: Name
      });

      if (response.status === 200) {
        setRTMState((prevState) => ({
          ...prevState,
          analytics: [...response.data]
        }));
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Fetch Failed',
        description: 'An error occurred while fetching RTM list'
      });
    } finally {
      setLoadingAnalytics(false);
    }
  };

  const handleInputChange = async (e) => {
    const value = e.target.value;
    const query = value ? encodeURIComponent(value.toLowerCase()) : '*';

    setSearch(value);
    debouncedSearch(query, filter);
  };

  const getBoardList = async () => {
    if (
      Preferences &&
      Preferences.Messaging &&
      Preferences.Messaging === 'enabled'
    ) {
      if (user.details) {
        const { role, sub } = user.details;
        if (role && sub) {
          setLoadingBoards(true);
          await services.message
            .getBoards(role, sub)
            .then((response) => {
              const { data } = response;
              if (Array.isArray(data) && data.length > 0) {
                setMessages({
                  list: data
                });
              }
            })
            .finally(() => setLoadingBoards(false));
        }
      }
    }
  };

  const handleChangeTab = (key) => {
    setTab(key);

    if (key === 'messages') {
      getBoardList();
    }

    if (currentMonitoredPatient && isMonitoring) {
      dispatch(exitCurrentMonitoredPatient());
    }
  };

  const handleSelectFilter = async (e) => {
    const name = e.target.name;
    const checked = e.target.checked;
    const key = checked ? name : '';
    const query = search ? encodeURIComponent(search) : '*';

    setFilter(key);
    debouncedSearch(query, key);
  };

  return (
    <Fragment>
      {showReactiveModal && (
        <ReactiveModal closeWindow={() => setShowReactiveModal(false)} />
      )}

      {showReloadModal && (
        <ReloadModal closeWindow={() => setShowReloadModal(false)} />
      )}

      <DropdownNavigation />

      <Tabs activeKey={tab} onChange={handleChangeTab}>
        <Tabs.TabPane key="patients" tab="Patients">
          <div className="ptw-main-body">
            <div className="tab-header">
              <Typography.Title level={2}>Patients</Typography.Title>

              <div className="tab-header-controls">
                <Link to="/patients/new">
                  <Button type="primary" className="btn-primary ptw-btn">
                    <PlusOutlined /> Add Patient
                  </Button>
                </Link>

                <Input
                  size="middle"
                  placeholder="Search patient name or email"
                  prefix={<SearchOutlined />}
                  onChange={handleInputChange}
                />
                <Tooltip title="Filter Patients">
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key="active">
                          <Checkbox
                            name="active"
                            checked={filter === 'active'}
                            onChange={handleSelectFilter}
                          >
                            Active
                          </Checkbox>
                        </Menu.Item>
                        <Menu.Item key="discharge">
                          <Checkbox
                            name="discharge"
                            checked={filter === 'discharge'}
                            onChange={handleSelectFilter}
                          >
                            Discharged
                          </Checkbox>
                        </Menu.Item>
                      </Menu>
                    }
                    onOpenChange={(e) => setFilterOpen(e)}
                    open={filterOpen}
                    trigger={['click']}
                    placement="bottomRight"
                  >
                    <Button className="btn-default" shape="circle">
                      <FilterOutlined style={{ fontSize: 16 }} />
                    </Button>
                  </Dropdown>
                </Tooltip>
              </div>
            </div>

            {!loadingPatients && !patientState.list.length && (
              <NoResults content="Sorry, no results found." />
            )}

            {loadingPatients ? (
              <LoadingPage
                type="list"
                content="Loading patients, please wait..."
              />
            ) : (
              <Row gutter={[16, 16]}>
                {patientState.list
                  .slice(pagination.min, pagination.max)
                  .map((item, i) => {
                    const { firstName, lastName } = item;
                    const fullname = `${v.capitalize(firstName)} ${v.capitalize(
                      lastName
                    )}`;

                    const { GroupInfo } = visibleProfile;
                    const { emailAddress, phoneNumber } = item;
                    const contact = !!GroupInfo.EnablePhoneNumber
                      ? emailAddress || phoneNumber
                      : emailAddress || 'NOT AVAILABLE';

                    return (
                      <Fragment key={i}>
                        <Col xl={6} lg={12} md={12} sm={12} xs={24}>
                          <Link to={'/patients/' + item.sub}>
                            <Card className="ptw-card-item">
                              <Space direction="vertical">
                                <Space direction="horizontal">
                                  <Typography.Text strong>
                                    {setStringEllipsis(fullname)}
                                  </Typography.Text>

                                  {!!item.discharge && (
                                    <Tag color="volcano">Discharged</Tag>
                                  )}
                                </Space>

                                <Typography.Text type="secondary">
                                  {setStringEllipsis(contact)}
                                </Typography.Text>
                              </Space>

                              <div className="ptw-card-item-icon">
                                <ChevronForward />
                              </div>
                            </Card>
                          </Link>
                        </Col>
                      </Fragment>
                    );
                  })}
              </Row>
            )}

            <div className="pagination-container align-right">
              <Pagination
                responsive
                style={{ bottom: '0px' }}
                total={loadingPatients ? 1 : patientState.list.length}
                pageSize={pagination.pageSize}
                current={pagination.currentPage}
                showSizeChanger={false}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
                onChange={(page) =>
                  setPagination({
                    ...pagination,
                    currentPage: page,
                    min: (page - 1) * pagination.pageSize,
                    max: page * pagination.pageSize
                  })
                }
              />
            </div>
          </div>
        </Tabs.TabPane>

        {EnableRTM && typeof EnableRTM === 'boolean' && !!EnableRTM && (
          <Tabs.TabPane key="rtm" tab="RTM Dashboard">
            <RTMDashboard
              visibleProfile={visibleProfile}
              loadingAnalytics={loadingAnalytics}
              setLoadingAnalytics={(load) => setLoadingAnalytics(load)}
            />
          </Tabs.TabPane>
        )}

        <Tabs.TabPane key="exercises" tab="Exercises">
          <div className="ptw-main-body">
            <PatientExercises isTabbed={true} />
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane key="templates" tab="Templates">
          <div className="ptw-main-body">
            <ExerciseTemplates
              dispatch={dispatch}
              exportPrescription={exportPrescription}
              importedTemplate={importedTemplate}
              selectedExercises={selectedExercises}
              visibleProfile={visibleProfile}
            />
          </div>
        </Tabs.TabPane>

        {EnableEducPDF &&
          typeof EnableEducPDF === 'boolean' &&
          !!EnableEducPDF && (
            <Tabs.TabPane key="educational-pdfs" tab="Educational PDFs">
              <EducationalPDFs />
            </Tabs.TabPane>
          )}

        <Tabs.TabPane
          key="messages"
          tab={
            <Badge
              offset={[20, 0]}
              count={
                messages.list.some(
                  (msg) => !msg.TherapistRead && msg.Messages.length > 0
                ) ? (
                  <MessageTwoTone twoToneColor="red" />
                ) : null
              }
            >
              <span>Messages</span>
            </Badge>
          }
        >
          <div className="ptw-main-body">
            <Messages
              loadingBoards={loadingBoards}
              setLoadingBoards={(load) => setLoadingBoards(load)}
            />
          </div>
        </Tabs.TabPane>
      </Tabs>

      {redirectLink && <Redirect to={redirectLink} />}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  currentMonitoredPatient: state.monitorTimer.currentMonitoredPatient,
  isMonitoring: state.monitorTimer.isMonitoring,
  visibleProfile: state.visibleProfile,
  exportPrescription: state.patients.exportPrescription,
  importedTemplate: state.patients.templateData,
  selectedExercises: state.patients.selectedExercises
});

export default connect(mapStateToProps)(withRouter(Patients));

import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import setNotification from '../../../utils/setNotification.utils';

const useRtmIdleTimer = (
  isMonitoring,
  currentMonitoredPatient,
  streamDuration
) => {
  const [isInactive, setIsInactive] = useState(false);
  const [openInactivePrompt, setOpenInactivePrompt] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);

  const onIdle = () => {
    if (isMonitoring && currentMonitoredPatient && streamDuration) {
      const minutes = Math.floor(streamDuration.current / 60);
      const seconds = streamDuration.current - minutes * 60;

      reset();
      setOpenInactivePrompt(false);
      setIsInactive(true);
      setNotification(
        'info',
        'Monitoring Stopped',
        `Your RTM timer stopped due to inactivity in ${minutes} minutes & ${seconds}.`,
        null
      );
    }
  };

  const onActive = () => {
    if (isMonitoring && currentMonitoredPatient) {
      setIsInactive(false);
    }
  };

  const onPrompt = () => {
    if (isMonitoring && currentMonitoredPatient) {
      setOpenInactivePrompt(true);
    }
  };

  const handleResumeMonitoring = () => {
    reset();
    setOpenInactivePrompt(false);
    setIsInactive(false);
  };

  const { getRemainingTime, reset, start } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    promptBeforeIdle: 1000 * 60 * 2,
    timeout: 1000 * 60 * 5,
    throttle: 500,
  });

  useEffect(() => {
    let interval;

    if (isMonitoring && currentMonitoredPatient) {
      start();
      interval = setInterval(() => {
        setRemainingTime(Math.ceil(getRemainingTime() / 1000));
      }, 500);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isMonitoring, currentMonitoredPatient]);

  return {
    isInactive,
    remainingTime,
    openInactivePrompt,
    handleResumeMonitoring,
  };
};

export default useRtmIdleTimer;

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { FormGroup } from 'react-bootstrap';
import {
  Form,
  Select,
  Row,
  Col,
  Button,
  Space,
  Switch,
  Divider,
  Typography
} from 'antd';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import MaskedInput from 'react-maskedinput';
import FormInput from './Input';
import USStates from './USStates';
import { required } from './validators/generic';
import {
  contactEmailValidator,
  groupNameValidator,
  addressValidator,
  cityValidator,
  contactNameValidator,
  zipValidator,
  phoneValidator,
  asyncValidate
} from './validators/groups';
import { zipCodeNormalizer } from './inputNormalizers';
import FormSwitch from './Switch';

class GroupForm extends Component {
  constructor(props) {
    super(props);

    const { initialValues } = this.props;
    this.state = {
      isPracticePromotions: this.strToBool(initialValues.IsPracticePromotions)
    };
    this.checkPP = this.checkPP.bind(this);
    this.uncheckPP = this.uncheckPP.bind(this);
  }

  boolToString(value, key = '') {
    if (!value) {
      let boolStr = 'false';
      if (value === undefined) {
        switch (key) {
          case 'EnableAppointments':
          case 'EnableTelehealth':
            boolStr = 'true';
            break;

          case 'EnableEducPDF':
            boolStr = 'false';
            break;

          default:
            break;
        }
      }

      return boolStr;
    }

    return value.toString();
  }

  strToBool(val) {
    if (typeof val === 'string') {
      if (val === 'true') {
        return true;
      } else {
        return false;
      }
    }

    return val;
  }

  uncheckPP() {
    this.setState({ isPracticePromotions: false });
  }

  checkPP() {
    this.setState({ isPracticePromotions: true });
  }

  render() {
    const { handleSubmit, submitting, initialValues, group } = this.props;
    const { isPracticePromotions } = this.state;

    initialValues.active = this.boolToString(initialValues.active);

    initialValues.IsPracticePromotions = this.boolToString(
      initialValues.IsPracticePromotions
    );

    initialValues.EnableRTM = this.boolToString(initialValues.EnableRTM);
    initialValues.EnableRTMConsent = this.boolToString(
      initialValues.EnableRTMConsent
    );
    initialValues.EnablePTU = this.boolToString(initialValues.EnablePTU);
    initialValues.EnablePrompt = this.boolToString(initialValues.EnablePrompt);
    initialValues.EnableRaintree = this.boolToString(
      initialValues.EnableRaintree
    );
    initialValues.EnableMultipleRx = this.boolToString(
      initialValues.EnableMultipleRx
    );
    initialValues.EnablePhoneNumber = this.boolToString(
      initialValues.EnablePhoneNumber
    );
    initialValues.EnableAppointments = this.boolToString(
      initialValues.EnableAppointments,
      'EnableAppointments'
    );
    initialValues.EnableTelehealth = this.boolToString(
      initialValues.EnableTelehealth,
      'EnableTelehealth'
    );
    initialValues.EnableEducPDF = this.boolToString(
      initialValues.EnableEducPDF,
      'EnableEducPDF'
    );

    if (Array.isArray(initialValues?.Integrations)) {
      const integrationsArr = [];
      const integrations = initialValues.Integrations;
      const betterPT = integrations.find((i) => i.CompanyName === 'BetterPT');
      const cardConn = integrations.find(
        (i) => i.CompanyName === 'CardConnect'
      );

      if (betterPT) {
        integrationsArr.push({
          CompanyName: 'BetterPT',
          Url: betterPT.Url
        });
      }

      if (cardConn) {
        if (!integrationsArr.length) {
          integrationsArr.push({
            CompanyName: 'BetterPT',
            Url: ''
          });
        }

        integrationsArr.push({
          CompanyName: 'CardConnect',
          Url: cardConn.Url
        });
      }

      initialValues.Integrations = integrationsArr;
    }

    return (
      <div>
        <Form layout="vertical">
          <Row gutter={[24, 0]}>
            <Col md={18} sm={24} xs={24}>
              <Field
                name="name"
                label="Group Name"
                isRequired
                component={FormInput}
                type="text"
                validate={[required, groupNameValidator]}
              />
            </Col>
            <Col md={6} sm={24} xs={24}>
              <Field
                name="groupPhone"
                label="Group Phone"
                isRequired
                component={FormInput}
                componentClass={MaskedInput}
                validate={[required, phoneValidator]}
                mask="111-111-1111"
                placeholder="123-456-7890"
              />
            </Col>
            <Col md={8} sm={24} xs={24}>
              <Field
                name="address"
                label="Address"
                isRequired
                component={FormInput}
                type="text"
                validate={[required, addressValidator]}
              />
            </Col>
            <Col md={6} sm={24} xs={24}>
              <Field
                name="city"
                label="City"
                isRequired
                component={FormInput}
                type="text"
                validate={[required, cityValidator]}
              />
            </Col>
            <Col md={6} sm={24} xs={24}>
              <Field
                name="state"
                label="State"
                isRequired
                component={FormInput}
                type="select"
              >
                {Object.keys(USStates).map((abbrev) => (
                  <Select.Option value={abbrev} key={abbrev}>
                    {USStates[abbrev]}
                  </Select.Option>
                ))}
              </Field>
            </Col>
            <Col md={4} sm={24} xs={24}>
              <Field
                name="zip"
                label="Zip"
                isRequired
                component={FormInput}
                type="text"
                validate={[required, zipValidator]}
                normalize={zipCodeNormalizer}
              />
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Field
                name="contactName"
                label="Contact Name"
                isRequired
                component={FormInput}
                type="text"
                validate={[required, contactNameValidator]}
              />
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Field
                name="contactPhone"
                label="Contact Phone"
                isRequired
                component={FormInput}
                componentClass={MaskedInput}
                validate={[required, phoneValidator]}
                mask="111-111-1111"
                placeholder="123-456-7890"
              />
            </Col>
            <Col md={8} sm={24} xs={24}>
              <Field
                name="contactEmail"
                label="Group Email"
                isRequired
                component={FormInput}
                type="text"
                validate={[required, contactEmailValidator]}
                placeholder="email@domain.com"
              />
            </Col>
            <Col md={8} sm={24} xs={24}>
              <Field
                name="contactEmail"
                label="Contact Email"
                isRequired
                component={FormInput}
                type="text"
                validate={[required, contactEmailValidator]}
                placeholder="email@domain.com"
              />
            </Col>
            <Col md={8} sm={24} xs={24}>
              <Field
                name="GroupEmailDisplay"
                label="Display Name for Emails"
                component={FormInput}
                type="text"
                placeholder="Example PT"
              />
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Field
                name="iOSUrl"
                label="iOS Appstore Url"
                component={FormInput}
                type="text"
                placeholder=""
              />
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Field
                name="AndroidUrl"
                label="Play Store Url"
                component={FormInput}
                type="text"
                placeholder=""
              />
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Field
                name="FacebookUrl"
                label="Facebook Review Page"
                component={FormInput}
                type="text"
                placeholder=""
              />
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Field
                name="GoogleUrl"
                label="Google Review Page"
                component={FormInput}
                type="text"
                placeholder=""
              />
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Field
                name={'Integrations[0].Url'}
                label="Appointments URL (BetterPT)"
                component={FormInput}
                type="text"
                placeholder=""
              />
            </Col>
            <Col md={12} sm={24} xs={24}>
              <Field
                name={'Integrations[1].Url'}
                label="Bill Pay URL (GoCardConnect)"
                component={FormInput}
                type="text"
                placeholder=""
              />
            </Col>
            <Col md={isPracticePromotions ? 8 : 12} sm={24} xs={24}>
              <Field
                name={'PrintEmail'}
                label="PDF Printout Email"
                component={FormInput}
                type="select"
                placeholder="Select email to display on PDF printout"
                validate={[required]}
              >
                <Select.Option value={'provider'}>Provider</Select.Option>
                <Select.Option value={'clinic'}>Clinic</Select.Option>
              </Field>
            </Col>

            <Col md={isPracticePromotions ? 8 : 12} sm={24} xs={24}>
              <Field
                name="ExpoProjectName"
                label="Expo Project Name"
                component={FormInput}
                type="text"
                placeholder=""
              />
            </Col>

            {isPracticePromotions && (
              <Col md={8} sm={24} xs={24}>
                <Field
                  name="BlogUrl"
                  label="PP Blog Url"
                  component={FormInput}
                  type="text"
                  placeholder=""
                />
              </Col>
            )}
          </Row>

          <Divider plain orientation="left" orientationMargin={0}>
            <Typography.Title level={4}>Group Configuration</Typography.Title>
          </Divider>

          <Row gutter={[24, 24]}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <Row gutter={[24, 24]}>
                <Col md={24} sm={24} xs={24}>
                  <Field
                    name="active"
                    label="Active/Deactivate Group"
                    component={FormSwitch}
                    defaultChecked={initialValues.active === 'true'}
                  />
                </Col>
                <Col md={24} sm={24} xs={24}>
                  <Field
                    name="isPracticePromotions"
                    label="Practice Promotions/Standard Group"
                    component={FormSwitch}
                    defaultChecked={
                      initialValues.isPracticePromotions === 'true'
                    }
                  />
                </Col>
                <Col md={24} sm={24} xs={24}>
                  <Field
                    name="EnablePhoneNumber"
                    label="Enable Phone Number"
                    component={FormSwitch}
                    defaultChecked={initialValues.EnablePhoneNumber === 'true'}
                  />
                </Col>
                <Col md={24} sm={24} xs={24}>
                  <Field
                    name="EnableAppointments"
                    label="Enable Appointments"
                    component={FormSwitch}
                    defaultChecked={initialValues.EnableAppointments === 'true'}
                  />
                </Col>
                <Col md={24} sm={24} xs={24}>
                  <Field
                    name="EnableEducPDF"
                    label="Enable Educational PDFs"
                    component={FormSwitch}
                    defaultChecked={initialValues.EnableEducPDF === 'true'}
                  />
                </Col>
                <Col md={24} sm={24} xs={24}>
                  <Field
                    name="EnableTelehealth"
                    label="Enable Telehealth"
                    component={FormSwitch}
                    defaultChecked={initialValues.EnableTelehealth === 'true'}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <Row gutter={[24, 24]}>
                <Col md={24} sm={24} xs={24}>
                  <Field
                    name="EnablePrompt"
                    label="Enable Prompt"
                    component={FormSwitch}
                    defaultChecked={initialValues.EnablePrompt === 'true'}
                  />
                </Col>
                <Col md={24} sm={24} xs={24}>
                  <Field
                    name="EnableRaintree"
                    label="Enable Raintree"
                    component={FormSwitch}
                    defaultChecked={initialValues.EnableRaintree === 'true'}
                  />
                </Col>
                <Col md={24} sm={24} xs={24}>
                  <Field
                    name="EnableMultipleRx"
                    label="Enable Multiple Prescriptions"
                    component={FormSwitch}
                    defaultChecked={initialValues.EnableMultipleRx === 'true'}
                  />
                </Col>
                <Col md={24} sm={24} xs={24}>
                  <Field
                    name="EnablePTU"
                    label="Enable Care Plan"
                    component={FormSwitch}
                    defaultChecked={initialValues.EnablePTU === 'true'}
                  />
                </Col>
                <Col md={24} sm={24} xs={24}>
                  <Field
                    name="EnableRTM"
                    label="Enable RTM"
                    component={FormSwitch}
                    defaultChecked={initialValues.EnableRTM === 'true'}
                  />
                </Col>
                <Col md={24} sm={24} xs={24}>
                  <Field
                    name="EnableRTMConsent"
                    label="Enable RTM Consent"
                    disabled={group?.values?.EnableRTM === 'false'}
                    component={FormSwitch}
                    defaultChecked={initialValues.EnableRTMConsent === 'true'}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={24} sm={24} xs={24}>
              <FormGroup className="ptw-form-actions">
                <Space size={8} className="btn-toolbar pull-right">
                  <Link to="/groups">
                    <Button
                      disabled={submitting}
                      className="ptw-btn"
                      type="default"
                      size="large"
                    >
                      Cancel
                    </Button>
                  </Link>
                  <Button
                    type="primary"
                    className="ptw-btn"
                    size="large"
                    disabled={false}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </Space>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    group: state.form.group
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'group',
    asyncValidate,
    asyncBlurFields: ['name']
  })(withRouter(GroupForm))
);

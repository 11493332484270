import React, { Component } from 'react';
import { FULFILLED } from 'redux-promise-middleware';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { isDirty, isValid, isSubmitting, isPristine, change } from 'redux-form';
import { withRouter } from 'react-router';
import { Breadcrumb, notification } from 'antd';
import _ from 'lodash';

import { FormTypes } from '../groups/actions/groups';
import { usersItemsSelector } from './selectors';
import {
  defaultUser,
  doneLoading,
  getUser,
  isLoading,
  postUser,
  setUserFormType,
  updateCurrentUser,
  updateUser,
} from './actions/users';
import { getClinics } from '../groups/actions/clinics';
import { titleCase } from '../groups/stringHelpers';
import { manualPasswordReset } from '../groups/group-admin/actions';
import { uploadImageDirect } from '../../components/file-uploader/actions';

import Loading from '../../components/Loading';
import UserForm from './UserForm';
import AssignPatientsModal from './AssignPatientsModal';
import services from '../../services';
import DispatchLinkItem from '../../components/DispatchLinkItem';

class UserFormPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groupClinics: [],
      profile: null,
      submitting: false,
      redirect: false,
      boards: [],
      showModal: false,
    };
    this.passwordMessage =
      "The user's existing password will no longer allow them to log in and they will be sent an email with instructions for creating a new one. Proceed?";

    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.goToUsersList = this.goToUsersList.bind(this);
  }

  componentWillUnmount() {
    this.props.dispatch(updateCurrentUser(null));
    this.setState({
      boards: [],
    });
  }

  componentDidMount() {
    const { dispatch, match, group } = this.props;

    dispatch(getClinics(group.name, group.id));
    dispatch(isLoading());

    if (match.params.id !== 'new') {
      dispatch(getUser(group.name, match.params.id)).then(({ action }) => {
        if (action.type.includes(FULFILLED)) {
          this.setState({
            profile: action.payload,
          });

          dispatch(setUserFormType(FormTypes.EDIT));
          dispatch(updateCurrentUser(action.payload));

          const { Role, Sub } = action.payload;
          services.message.getBoards(Role, Sub).then(res => {
            this.setState({
              boards: res.data,
            });
          });
        }

        dispatch(doneLoading());
      });
    } else {
      dispatch(setUserFormType(FormTypes.NEW));
      dispatch(updateCurrentUser(defaultUser));

      this.setState({
        profile: defaultUser,
      });

      dispatch(doneLoading());
    }
  }

  handleFileSelected = (x, errors) => {
    const isValid = _.isEmpty(errors);
    this.setState({
      validExerciseImage: isValid,
    });

    if (isValid) {
      this.props.dispatch(change('user', 'image', x.name));
      this.file = x;
    }
  };

  handleFileRemoved = x => {
    this.setState({
      validExerciseImage: true,
    });

    this.props.dispatch(change('user', 'image', null));
    this.removeFile = x;
  };

  handleChangePassword(admin) {
    const { dispatch } = this.props;

    if (window.confirm(this.passwordMessage)) {
      dispatch(manualPasswordReset(admin.emailAddress)).then(data => {
        if (data.value.message === 'Success') {
          alert("User's password was reset");
        } else {
          alert('There was an unknown problem');
        }
      });
    }
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      redirect: true,
    });
  };

  handleSubmitForm = async values => {
    const { formType, dispatch } = this.props;

    this.setState({
      submitting: true,
    });

    let action = null;
    if (formType === FormTypes.NEW) {
      action = postUser(values.GroupId, values);
    } else {
      dispatch(change('user', 'active', values.active));
      action = updateUser(values.GroupId, values, true);
    }

    try {
      await dispatch(action).then(response => {
        if (response.value.message === 'Success') {
          if (formType === FormTypes.NEW) {
            notification.success({
              message: 'User Created!',
              description: 'An activation email has been sent to the user.',
            });
          } else {
            notification.success({
              message: 'Changes Saved!',
              description: 'User profile has been successfully updated.',
            });
          }
        } else {
          throw new Error(response.value);
        }
      });
    } catch (error) {
      notification.error({
        message: 'Error!',
        description:
          'This email address is already in use. Please use a different email or contact support for assistance.',
      });
    } finally {
      this.setState({
        submitting: false,
      });
    }

    if (formType === FormTypes.EDIT && !values.Active) {
      this.setState({
        showModal: true,
      });
    } else {
      this.goToUsersList();
    }
  };

  goToUsersList() {
    this.setState({
      redirect: true,
    });
  }

  render() {
    const {
      handleSubmitForm,
      handleCloseModal,
      state: { profile, submitting, redirect, showModal, boards },
      props: { formType, currentUser, isLoading, group, dispatch, clinics },
    } = this;

    Object.keys(currentUser).forEach(val => {
      if (val === 'firstName' || val === 'lastName') {
        if (!currentUser[val]) {
          return;
        }
        currentUser[val] = titleCase(currentUser[val]);
      }
    });

    if (profile && profile.Active === true) {
      profile.Active = 'true';
    } else if (profile && profile.Active === false) {
      profile.Active = 'false';
    }

    return (
      <>
        <Loading isLoading={isLoading}>
          {redirect && <Redirect to={'/users'} />}
          <Breadcrumb>
            <DispatchLinkItem url={'/administrator'} title="Admin" />
            <DispatchLinkItem url={'/users'} title="Providers" />
            <Breadcrumb.Item active>
              {formType === FormTypes.NEW ? 'Add' : 'Edit'}
            </Breadcrumb.Item>
          </Breadcrumb>

          {profile && (
            <UserForm
              onChangePassword={this.handleChangePassword}
              onSubmit={handleSubmitForm}
              initialValues={profile}
              group={group}
              formType={formType}
              groupClinics={clinics}
              onFileSelected={this.handleFileSelected}
              onFileRemoved={this.handleFileRemoved}
              submitting={submitting}
              visibleProfile={this.props.visibleProfile}
              uploadImage={async file => {
                await dispatch(
                  uploadImageDirect(
                    `${currentUser.GroupId}/${currentUser.Sub}.jpg`,
                    file
                  )
                );
              }}
            />
          )}
        </Loading>

        <AssignPatientsModal
          onClose={handleCloseModal}
          open={showModal}
          profile={currentUser}
          boards={boards}
          dispatch={dispatch}
        />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    visibleProfile: state.visibleProfile,
    userFromRoute: usersItemsSelector(state)[ownProps.match.params.id],
    currentUser: state.users.currentUser,
    formType: state.users.formType,
    isLoading: state.users.isLoading,
    isFormValid: isValid('user')(state),
    isFormDirty: isDirty('user')(state),
    isFormSubmitting: isSubmitting('user')(state),
    isFormPristine: isPristine('user')(state),
    modalOpen: state.users.modalOpen,
    clinics: state.clinics.items,
  };
}

export default connect(mapStateToProps)(withRouter(UserFormPage));

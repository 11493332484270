import React, { Fragment, useState } from 'react';
import {
  Card,
  Row,
  Space,
  Tooltip,
  Button,
  Typography,
  Popconfirm,
  notification,
} from 'antd';
import { connect } from 'react-redux';
import { useRecoilState } from 'recoil';

import {
  BookOutlined,
  CheckCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';

import { formatCategory } from '../../../utils/educationalPdf';

import EditPDFModal from '../EditPDFModal';
import states from '../../../states';
import services from '../../../services';

const PDFCard = ({ visibleProfile, type = 'DEFAULT', item, onClick }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [pdf, setPdf] = useRecoilState(states.pdf);

  const subTitle = formatCategory(item.category);
  const isSelected = pdf.currSelected.find(
    selected => selected.title === item.title
  );

  const checkAllowModify = () => {
    const { Role, Sub, GroupId } = visibleProfile;

    if (Role === 'sa') {
      return GroupId === item.owner;
    } else {
      return Sub === item.owner;
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);

      const response = await services.educationalPdf.deletePdf(
        item.id,
        item.owner
      );

      if (response.status === 200) {
        notification.success({
          message: 'Delete PDF Success',
          description: 'PDF successfully deleted.',
        });

        setPdf(prevState => ({
          ...prevState,
          list: [...prevState.list].map(pdf => {
            if (!pdf.data.some(i => i.id === item.id)) {
              return pdf;
            }

            return {
              category: pdf.category,
              data: [...pdf.data].filter(i => i.id !== item.id),
            };
          }),
        }));

        setPdf(prevState => ({
          ...prevState,
          list: [...prevState.list].filter(pdf => pdf.id !== item.id),
        }));
      }
    } catch (error) {
      notification.error({
        message: 'Delete PDF Error',
        description: 'An error occurred while deleting the PDF.',
      });
    } finally {
      setLoading(false);
    }
  };

  const isAllowed = checkAllowModify();

  return (
    <Fragment>
      <EditPDFModal
        initialData={item}
        showModal={showEdit}
        onClose={() => setShowEdit(false)}
      />
      <Tooltip title={item.title}>
        <Card
          className={`pdf-cat-card ${isSelected ? 'selected' : ''}`}
          hoverable
          onClick={() => {
            if (type !== 'DEFAULT' || !isAllowed) {
              onClick(item);
            }
          }}
          onMouseOver={() => setIsHovering(true)}
          onMouseOut={() => setIsHovering(false)}
        >
          {isSelected && (
            <Fragment>
              {isHovering ? (
                <CloseOutlined className="pdf-cat-card-icon" />
              ) : (
                <CheckCircleOutlined className="pdf-cat-card-icon" />
              )}
            </Fragment>
          )}
          <Space direction="vertical" size={0} className={isSelected && 'mr-4'}>
            <div className="pdf-cat-card-title">
              <Typography.Title level={5}>{item.title}</Typography.Title>
            </div>
            <Typography.Text
              type="secondary"
              style={{ textTransform: 'uppercase', fontSize: 12 }}
            >
              {subTitle}
            </Typography.Text>
          </Space>
          {type === 'DEFAULT' && isAllowed && (
            <div className="pdf-action-wrapper">
              <Row style={{ height: '100%' }} justify="center" align="middle">
                <Space size={8}>
                  <Button
                    type="primary"
                    icon={<BookOutlined />}
                    size="large"
                    shape="circle"
                    onClick={() => onClick(item)}
                  />
                  <Button
                    type="primary"
                    icon={<EditOutlined />}
                    size="large"
                    shape="circle"
                    onClick={() => setShowEdit(true)}
                  />
                  <Popconfirm
                    placement="rightBottom"
                    title="Are you sure you want to delete this PDF?"
                    okButtonProps={{
                      loading,
                      danger: true,
                    }}
                    okText="Delete"
                    onConfirm={handleDelete}
                  >
                    <Button
                      type="danger"
                      icon={<DeleteOutlined />}
                      size="large"
                      shape="circle"
                    />
                  </Popconfirm>
                </Space>
              </Row>
            </div>
          )}
        </Card>
      </Tooltip>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  visibleProfile: state.visibleProfile,
});

export default connect(mapStateToProps)(PDFCard);

/* eslint-disable react-hooks/exhaustive-deps */
import './styles.css';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  Button,
  Col,
  Input,
  List,
  notification,
  Popover,
  Row,
  Typography
} from 'antd';
import { PlusOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentSlash } from '@fortawesome/free-solid-svg-icons';
import v from 'voca';

import TabHeader from '../../components/TabHeader';
import MessageBoard from '../../components/MessageBoard';
import NewBoardModal from './NewBoardModal';
import Tooltip from './Tooltip';

import truncateText from '../../utils/truncateText.utils';
import states from '../../states';
import services from '../../services';

import { useMutation } from '@apollo/client';
import { READ_MESSAGE } from '../../graphql/mutations';

import {
  setCurrentMonitoredPatient,
  startLoadingMonitoredPatient,
  endLoadingMonitoredPatient,
  exitCurrentMonitoredPatient
} from '../../features/monitor-timer/actions';
import { delay } from '../../features/patients/RTMDashboard/components/PatientDetailDrawer/mixins';
import { toCamelCaseObjKeys } from '../../utils/object.utils';

const Messages = ({
  timerData,
  monitoring,
  setCurrentMonitoring,
  exitCurrentMonitoring,
  startGetLoading,
  doneGetLoading,
  loadingBoards,
  setLoadingBoards
}) => {
  const [readMessage] = useMutation(READ_MESSAGE);

  const [filter, setFilter] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [sub, setSub] = useState('');
  const [current, setCurrent] = useState(null);
  const [addLoading, setAddLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);

  const [messages, setMessages] = useRecoilState(states.messages);
  const setPatients = useSetRecoilState(states.patients);

  const user = useRecoilValue(states.user);
  const groups = useRecoilValue(states.groups);

  useEffect(() => {
    if (filter) {
      setFilteredList(
        messages.list.filter(
          (item) =>
            item.Owner.startsWith(filter) ||
            item.FirstName.startsWith(filter) ||
            item.LastName.startsWith(filter)
        )
      );
    }
  }, [filter]);

  const handleSelectBoard = async (board) => {
    if (!getLoading) {
      if (!current || (current && current.Id !== board.Id)) {
        const { Owner, TherapistRead } = board;

        setPatients((prevState) => ({
          ...prevState,
          details: null
        }));

        setCurrent(board);

        const response = await Promise.all([
          setMonitoringPatient(Owner),
          services.preferences.getPreferences(Owner)
        ]);

        const details = response[0];
        const preferences = response[1]['data'];

        setPatients((prevState) => ({
          ...prevState,
          details: {
            ...toCamelCaseObjKeys(details),
            ...toCamelCaseObjKeys(preferences)
          }
        }));

        const disabled =
          preferences.Preferences &&
          preferences.Preferences.Messaging === 'disabled';

        if (!disabled) {
          if (!TherapistRead) {
            await readMessage({
              variables: {
                messageBoardId: board.Id
              }
            }).then(() => {
              setMessages((prevState) => ({
                ...prevState,
                list: prevState.list.map((message) => {
                  if (message.Id !== board.Id) {
                    return message;
                  }

                  return {
                    ...message,
                    TherapistMessages: 0,
                    TherapistRead: true
                  };
                })
              }));
            });
          }

          if (timerData && monitoring) {
            exitCurrentMonitoring();
          }
        }
      }
    }
  };

  const setMonitoringPatient = async (sub) => {
    let details = null;
    if (groups.user && !!groups.user.enableRTM) {
      try {
        startGetLoading();
        setGetLoading(true);

        const patient = await services.patients.getPatientDetails('sub', sub);
        if (patient.data) {
          details = patient.data;
        }
      } catch (err) {
        details = null;
      } finally {
        await delay(1000);
        doneGetLoading();
        setGetLoading(false);
      }

      let monitorData = null;
      if (details && !!details.EnableRTM) {
        monitorData = {
          patient: details,
          activity: 'Message Patient',
          groupName: groups.user.name,
          provider: user.details.emailAddress
        };
      }

      setCurrentMonitoring(monitorData);
    }

    return details;
  };

  const handleGetBoards = async () => {
    const { role, sub } = user.details;

    setLoadingBoards(true);
    await services.message.getBoards(role, sub).then((res) => {
      if (res && Array.isArray(res.data) && res.data.length > 0) {
        setMessages({
          list: res.data
        });
      }
    });
    setLoadingBoards(false);
  };

  const handleOpenNewBoardModal = () => {
    setOpenModal(!openModal);
  };

  const handleAddNewBoard = async (item) => {
    const notif = {
      status: 'success',
      message: 'Success!',
      desc: 'Patient successfully added in converstation list.'
    };

    try {
      setAddLoading(true);
      setSub(item.sub);

      const owner = {
        sub: item.sub,
        fname: item.firstName,
        lname: item.lastName
      };

      const therapist = {
        sub: user.details.sub,
        fname: user.details.firstName,
        lname: user.details.lastName
      };

      await services.message.addNewBoard(owner, therapist);

      const { role, sub } = user.details;
      await services.message.getBoards(role, sub).then((res) => {
        if (res && Array.isArray(res.data) && res.data.length > 0) {
          setMessages({
            list: res.data
          });
        }
      });
    } catch (err) {
      notif.status = 'error';
      notif.message = 'Error!';
      notif.desc = 'Failed to add patient in converstation list.';
    } finally {
      setAddLoading(false);
      setSub('');
      setOpenModal(false);

      notification[notif.status]({
        message: notif.message,
        description: notif.desc
      });
    }
  };

  if (
    user.details &&
    user.details.preferences &&
    user.details.preferences.messaging &&
    user.details.preferences.messaging === 'disabled'
  ) {
    return (
      <Fragment>
        <TabHeader title="Messages" tooltip={<Tooltip type="title" />} />

        <div className="msg-disabled">
          <Typography.Title level={3}>Messaging Disabled!</Typography.Title>

          <Typography.Text>
            Enable messaging in your <Link to={'/profile'}>Profile</Link> in
            order to send messages.
          </Typography.Text>

          <br />
          <FontAwesomeIcon
            style={{ fontSize: '70px', marginBottom: 48 }}
            icon={faCommentSlash}
          />
        </div>
      </Fragment>
    );
  }

  const isActive = (item) => {
    if (!current) return false;

    return item.Id === current.Id;
  };

  return (
    <Fragment>
      <TabHeader title="Messages" tooltip={<Tooltip type="title" />} />

      <div className="msg-board-container">
        <Row gutter={0}>
          <Col xl={6} lg={8} md={24}>
            <div className="search-conversation">
              <Input
                placeholder="Search conversation.."
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                prefix={<SearchOutlined />}
              />

              <div className="search-conversation-space" />

              <Popover
                arrowPointAtCenter
                trigger="hover"
                placement="right"
                content={<Tooltip type="add" />}
              >
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={handleOpenNewBoardModal}
                />
              </Popover>

              <div className="search-conversation-space" />

              <Popover
                arrowPointAtCenter
                trigger="hover"
                placement="right"
                content={<Tooltip type="reload" />}
              >
                <Button
                  type="primary"
                  icon={<SyncOutlined />}
                  onClick={handleGetBoards}
                />
              </Popover>
            </div>

            <div className="msg-board-list">
              <List
                loading={{
                  spinning: loadingBoards,
                  indicator: null
                }}
                dataSource={filter ? filteredList : messages.list}
                renderItem={(item) => {
                  const cursor = getLoading ? 'not-allowed' : 'pointer';
                  const messages = item.Messages;
                  const recentMessage = messages[messages.length - 1];

                  let messageDate;
                  if (recentMessage) {
                    messageDate = recentMessage.date;
                  } else if (item.LastMessageDate) {
                    messageDate = Number(item.LastMessageDate);
                  }

                  return (
                    <List.Item
                      key={item.Id}
                      className={`msg-list-item ${
                        isActive(item) ? 'is-active' : ''
                      }  ${getLoading ? 'is-loading' : ''}`}
                      style={{ cursor }}
                      onClick={() => handleSelectBoard(item)}
                    >
                      <List.Item.Meta
                        title={truncateText(
                          `${v.capitalize(
                            item.FirstName.trim()
                          )} ${v.capitalize(item.LastName.trim())}`,
                          36
                        )}
                      />

                      {!item.TherapistRead && item.Messages.length > 0 && (
                        <div className="msg-badge">
                          <strong style={{ fontSize: 12, color: 'red' }}>
                            NEW
                          </strong>
                        </div>
                      )}

                      {messageDate && (
                        <span className="msg-board-last-msg-date">
                          <label style={{ cursor }}>
                            {new Date(messageDate).toLocaleString('en-US', {
                              dateStyle: 'medium'
                            })}
                          </label>
                        </span>
                      )}
                    </List.Item>
                  );
                }}
              />
            </div>
          </Col>

          <Col xl={18} lg={16} md={24}>
            {current ? (
              <div className="msg-board-item">
                <MessageBoard id={current.Id} showProfile={true} />
              </div>
            ) : (
              <div className="no-conversation">
                <label>Select a patient to start a conversation.</label>
              </div>
            )}
          </Col>
        </Row>
      </div>

      <NewBoardModal
        open={openModal}
        loading={addLoading}
        sub={sub}
        onClose={handleOpenNewBoardModal}
        onAddNewBoard={handleAddNewBoard}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  timerData: state.monitorTimer.currentMonitoredPatient,
  monitoring: state.monitorTimer.isMonitoring
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentMonitoring: (data) => dispatch(setCurrentMonitoredPatient(data)),
  exitCurrentMonitoring: () => dispatch(exitCurrentMonitoredPatient()),
  startGetLoading: () => dispatch(startLoadingMonitoredPatient()),
  doneGetLoading: () => dispatch(endLoadingMonitoredPatient())
});

export default connect(mapStateToProps, mapDispatchToProps)(Messages);

import React from 'react';
import { Empty, Skeleton } from 'antd';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { colors } from '../../pages/UsageReports/data';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  Filler
);

const BarChart = ({ chartTitle, loading = false, data }) => {
  if (loading) {
    return <Skeleton active />;
  }

  if (!data || !data.datasets.length) {
    return <Empty description="No data to display on chart." />;
  }

  const datasets =
    data.datasets.length > 0 &&
    data.datasets.map((dataset, i) => {
      const color = colors[i];

      return {
        ...dataset,
        borderColor: color.border,
        backgroundColor: color.background,
        borderWidth: 1,
      };
    });
  const height = data.labels.length * (30 * datasets.length) + 100;

  return (
    <div
      style={{
        position: 'relative',
        height: height,
        width: '100%',
      }}
    >
      <Bar
        id="bar-chart"
        data={{ ...data, datasets }}
        options={{
          backgroundColor: '#fff',
          indexAxis: 'y',
          responsive: true,
          aspectRatio: 1,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              onClick: null,
              position: 'top',
              labels: {
                boxWidth: 20,
                boxHeight: 12,
                useBorderRadius: true,
              },
            },
            title: {
              display: true,
              text: chartTitle,
            },
            datalabels: {
              display: false,
              align: 'end',
              anchor: 'start',
              color: '#fff',
              borderRadius: 4,
              textAlign: 'right',
            },
          },
        }}
      />
    </div>
  );
};

export default BarChart;

import React, { Component } from 'react';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import ReactGA from "react-ga";
class ReviewWidget extends Component {
    constructor(props){
        super(props)
        this.state={
            showWidgetPage: false,
            widgetUrl: null
        }
        this.navigateLink = this.navigateLink.bind(this)
    }

    componentDidMount() {
        const {groupName} = this.props;
        ReactGA.event({
            category: 'Mobile',
            action: 'View Rating Widget',
            label: groupName
        });
    }

    navigateLink(url){

        this.setState({
            showWidgetPage: true,
            widgetUrl: url,
        })
    }

    clickGoogle=()=>{
        const {groupName} = this.props;
        ReactGA.event({
            category: 'Mobile',
            action: 'Click Google Link',
            label:groupName
        });
    }

    clickFacebook=()=>{
        const {groupName} = this.props;
        ReactGA.event({
            category: 'Mobile',
            action: 'Click Facebook Link',
            label:groupName
        });
    }

    render(){
        const {facebook, google, groupName} = this.props;

        return(<div className={"reviewWidgetModal"}>
            <div className={"backgroundImage"} style={{backgroundColor:"grey",zIndex:"3", position:"absolute",
                width:"100%", height:"100%", left:"0px",top:"0px", opacity:"0.9" }}></div>
            <Modal.Dialog>
                <Modal.Header closeButton={true} onHide={this.props.onHide}>
                    <Modal.Title style={{fontSize:'24px'}}>Congratulations on your Achievement!</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{paddingTop:"0px"}}>
                    <h4 style={{fontSize:'24px'}}>Do we deserve 5 stars at {groupName}?</h4>
                    Please take 30 seconds to leave us a review. Simply click on the links below!
                </Modal.Body>
                <Modal.Body>
                <Row>
                <Col xsOffset={1}xs={10}>
                    <a style={{ fontSize:'20px'}} href={google} target={"_blank"}>
                        <Button bsStyle={'success'} block style={{color:'white',height:"45px",borderRadius:'10px',
                            marginBottom:'20px', backgroundColor:'#db3236', borderColor:'#f4c20d', lineHeight:1}} onClick={this.clickGoogle}>
                        Google</Button></a>
                </Col>
                </Row>
                <Row>
                <Col xsOffset={1}xs={10}>
                    <a style={{fontSize:'20px'}} href={facebook} target={"_blank"}>
                        <Button block style={{height:"45px",borderRadius:'10px', backgroundColor:'#3b5998',
                            borderColor:'#8b9dc3', lineHeight:1, color:"white"}} onClick={this.clickFacebook}>
                            Facebook
                        </Button></a>
                </Col>
                </Row>
                </Modal.Body>

            </Modal.Dialog>
        </div>)
    }
}


export default ReviewWidget;
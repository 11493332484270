import React, { useEffect, useState } from 'react';
import { Row, Col, Space, Typography, Skeleton } from 'antd';
import V from 'voca';
import {
  MessageFilled,
  PhoneFilled,
  TrophyFilled,
  YoutubeFilled
} from '@ant-design/icons';

import PDFFooter from '../../../patients/Modals/PDFFooter';
import services from '../../../../services';
import ExerciseItem from './ExerciseItem';
import useTranslate from '../../../../I18n/useTranslate';

const generatePages = (currLayout, exercises) => {
  const pages = [];
  let exPerPage = 4;

  if (currLayout === 'B') {
    exPerPage = 2;
  } else if (currLayout === 'C') {
    exPerPage = 1;
  }

  if (exercises) {
    const filtered = exercises.filter((e) => !e.deleted);

    const numberOfPages = Math.ceil(filtered.length / exPerPage);

    for (let i = 0; i < numberOfPages; i++) {
      const start = i * exPerPage;
      const end = i * exPerPage + exPerPage;
      const cut = filtered.slice(start, end);
      pages.push(cut);
    }
  }

  return pages;
};

const PrintPreview = ({
  printRef,
  printProps,
  settingProps,
  raintreePDF = false
}) => {
  const {
    currentPatient: { FirstName, LastName, GroupId },
    visibleProfile,
    siteDesign,
    prescription
  } = printProps;
  const {
    currLocale = 'en',
    currLayout = 'A',
    currEmail,
    currPhone
  } = settingProps;

  const { exercises, perdiem, frequency, instructions } = prescription;

  const pageContents = {
    createdFor: useTranslate('Created for', currLocale, !raintreePDF),
    preparedBy: useTranslate('Prepared by', currLocale, !raintreePDF),
    haveQuestions: useTranslate(
      'Have Questions? Contact Us',
      currLocale,
      !raintreePDF
    ),
    phone: useTranslate('Phone', currLocale, !raintreePDF),
    email: useTranslate('Email', currLocale, !raintreePDF),
    homeExerciseProgram: useTranslate(
      'Home Exercise Program',
      currLocale,
      !raintreePDF
    ),
    instructions: useTranslate('Instructions', currLocale, !raintreePDF),
    youCanAlsoAccess: useTranslate(
      'You Can Also Access On Our Mobile App',
      currLocale
    ),
    byUsingYourApp: useTranslate(
      'By using your app you can access...',
      currLocale
    ),
    hdExerciseVideos: useTranslate(
      'HD Exercise Videos',
      currLocale,
      !raintreePDF
    ),
    earnAchievements: useTranslate(
      'Earn Achievements',
      currLocale,
      !raintreePDF
    ),
    instantMessaging: useTranslate(
      'Instant Messaging',
      currLocale,
      !raintreePDF
    ),
    muchMore: useTranslate('Much More!', currLocale, !raintreePDF),
    howDoIAccess: useTranslate('How Do I Access?', currLocale, !raintreePDF),
    checkYourEmail: useTranslate(
      '1. Check your email for an email from admin@ptwired.com. Open the email.',
      currLocale
    ),
    selectActivate: useTranslate(
      '2. Select "Activate My Account".',
      currLocale
    ),
    setYourPassword: useTranslate(
      '3. Set your password.',
      currLocale,
      !raintreePDF
    ),
    linkedToAppStore: useTranslate(
      "4. You'll be linked to our App Store page to download our app and start accessing! You can also access via web on your computer.",
      currLocale
    ),
    havingTroubleAccessing: useTranslate(
      'Having trouble accessing?',
      currLocale
    ),
    emailUs: useTranslate(
      "Email us at admin@ptwired.com and we'll assist you.",
      currLocale
    ),
    sets: useTranslate('Sets', currLocale, !raintreePDF),
    reps: useTranslate('Reps', currLocale, !raintreePDF),
    hold: useTranslate('Hold', currLocale, !raintreePDF),
    frequency: useTranslate('Frequency', currLocale, !raintreePDF),
    per: useTranslate('per', currLocale, !raintreePDF)
  };

  const exPageContents = exercises.map((ex) => {
    return {
      ...ex,
      exerciseName: useTranslate(ex.exerciseName, currLocale, !raintreePDF),
      sets: useTranslate(ex.sets, currLocale, !raintreePDF),
      reps: useTranslate(ex.reps, currLocale, !raintreePDF),
      hold: useTranslate(ex.hold, currLocale, !raintreePDF),
      frequency: useTranslate(ex.frequency, currLocale, !raintreePDF),
      instructions: useTranslate(ex.instructions, currLocale, !raintreePDF)
    };
  });

  const pages = generatePages(raintreePDF ? 'B' : currLayout, exPageContents);

  const truncateText = (text) => {
    const maxLimit = 28;

    if (text.length >= maxLimit) {
      return text.substring(0, maxLimit - 3).trim() + '...';
    } else {
      return text;
    }
  };

  class ComponentToPrint extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      const { pageContents } = this.props;
      return (
        <div id="divToPrint">
          <div className={raintreePDF ? '' : 'first-page'}>
            {raintreePDF && (
              <div
                style={{
                  height: 48
                }}
              />
            )}

            <Row gutter={[16, 16]} className="header-container">
              <Col lg={12} md={12} sm={12} xs={12}>
                <Space direction="vertical" size={18}>
                  <img src={siteDesign.logoUrl} alt="logo" />
                  <Space direction="vertical" size={2}>
                    <Typography.Text>
                      <strong>{pageContents.createdFor} </strong>
                      {V.titleCase(FirstName)} {V.titleCase(LastName)}
                    </Typography.Text>

                    {visibleProfile && (
                      <Typography.Text>
                        <strong>{pageContents.preparedBy} </strong>
                        <Typography.Text
                          style={{ textDecoration: 'capitalize' }}
                        >
                          {truncateText(
                            `${visibleProfile.FirstName} ${visibleProfile.LastName}`
                          )}
                        </Typography.Text>
                      </Typography.Text>
                    )}
                  </Space>
                </Space>
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                <PDFFooter
                  pageContents={pageContents}
                  currEmail={currEmail}
                  currPhone={currPhone}
                />
              </Col>
            </Row>
            <Row
              gutter={[16, 16]}
              className={raintreePDF ? '' : 'home-exercise-program-container'}
              style={
                GroupId &&
                GroupId === 'b592cd97-5fb7-4d73-b4de-255af54d7df9' && {
                  marginTop: 40
                }
              }
            >
              <Col lg={24} md={24} sm={24} xs={24}>
                <Typography.Title level={2}>
                  {pageContents.homeExerciseProgram}
                </Typography.Title>
                <Space direction="vertical" size={12}>
                  {exercises.length !== 0 && !exercises[0].frequency && (
                    <Space direction="vertical" size={2}>
                      <Typography.Text strong>
                        {' '}
                        {pageContents.frequency}
                      </Typography.Text>
                      <Typography.Text>{`${frequency}x ${pageContents.per} ${perdiem}`}</Typography.Text>
                    </Space>
                  )}
                  <Space direction="vertical" size={2}>
                    <Typography.Text strong>
                      {' '}
                      {pageContents.instructions}
                    </Typography.Text>
                    <Typography.Text>{instructions}</Typography.Text>
                  </Space>
                </Space>
              </Col>
            </Row>
            {GroupId && GroupId !== 'b592cd97-5fb7-4d73-b4de-255af54d7df9' && (
              <div>
                <Row gutter={[16, 16]} className="app-features-container">
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <Typography.Title level={4}>
                      {pageContents.youCanAlsoAccess}
                    </Typography.Title>
                    <Typography.Text>
                      {pageContents.byUsingYourApp}
                    </Typography.Text>
                    <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <Space direction="vertical" size={16}>
                          <Space size={16} className="full-width">
                            <YoutubeFilled style={{ fontSize: 24 }} />
                            <Typography.Text strong>
                              {pageContents.hdExerciseVideos}
                            </Typography.Text>
                          </Space>
                          <Space size={16} className="full-width">
                            <TrophyFilled style={{ fontSize: 24 }} />
                            <Typography.Text strong>
                              {pageContents.earnAchievements}
                            </Typography.Text>
                          </Space>
                        </Space>
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <Space direction="vertical" size={16}>
                          <Space size={16} className="full-width">
                            <MessageFilled style={{ fontSize: 24 }} />
                            <Typography.Text strong>
                              {pageContents.instantMessaging}
                            </Typography.Text>
                          </Space>
                          <Space size={16} className="full-width">
                            <PhoneFilled style={{ fontSize: 24 }} />
                            <Typography.Text strong>
                              {pageContents.muchMore}
                            </Typography.Text>
                          </Space>
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row
                  gutter={[16, 16]}
                  className="access-instructions-container"
                >
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <Space direction="vertical" size={2}>
                      <Typography.Title level={4}>
                        {pageContents.howDoIAccess}
                      </Typography.Title>
                      <Typography.Text>
                        {pageContents.checkYourEmail}
                      </Typography.Text>
                      <Typography.Text>
                        {pageContents.selectActivate}
                      </Typography.Text>
                      <Typography.Text>
                        {pageContents.setYourPassword}
                      </Typography.Text>
                      <Typography.Text>
                        {pageContents.linkedToAppStore}
                      </Typography.Text>
                    </Space>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <Space direction="vertical" size={2}>
                      <Typography.Title level={4}>
                        {pageContents.havingTroubleAccessing}
                      </Typography.Title>
                      <Typography.Text>{pageContents.emailUs}</Typography.Text>
                    </Space>
                  </Col>
                </Row>
              </div>
            )}
          </div>

          {pages.map((exercisePage, i) => {
            const colSize = currLayout === 'A' ? 12 : 24;
            const colSpan = raintreePDF ? 24 : colSize;
            const rowSpan = raintreePDF ? 'B' : currLayout;

            return (
              <React.Fragment key={i}>
                <Row
                  gutter={[24, 24]}
                  className={`exercise-page ${rowSpan}`}
                  id={`p-${i - 1}`}
                  style={{
                    pageBreakBefore: raintreePDF ? 'always' : 'auto'
                  }}
                >
                  {exercisePage.map((exercise, j) => (
                    <Col
                      lg={colSpan}
                      md={colSpan}
                      sm={colSpan}
                      xs={colSpan}
                      key={`${i - 1}-${j}`}
                    >
                      <ExerciseItem
                        pageContents={pageContents}
                        currLocale={currLocale}
                        exercise={exercise}
                        raintreePDF={raintreePDF}
                      />
                    </Col>
                  ))}
                </Row>
              </React.Fragment>
            );
          })}
        </div>
      );
    }
  }

  return <ComponentToPrint pageContents={pageContents} ref={printRef} />;
};

export default PrintPreview;

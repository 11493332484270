import React from 'react';
import { Modal, Button, Select, Divider, Typography, Space } from 'antd';

const FilterPatientsModal = props => {
  const {
    openFilterPatient,
    handleOpenFilterPatient,
    admins,
    selectedProviders,
    renderProviderItems,
    renderProviderOptions,
    handleSelectProviders,
    handleSelectAllProviders,
    handleRemoveAllProviders,
    handleSaveFilter,
  } = props;

  return (
    <Modal
      title="Filter Providers"
      visible={openFilterPatient}
      onCancel={handleOpenFilterPatient}
      maskClosable
      footer={[
        <Button
          key="cancel-key"
          className="ptw-btn"
          type="default"
          htmlType="submit"
          size="large"
          onClick={handleOpenFilterPatient}
        >
          Cancel
        </Button>,
        <Button
          key="save-key"
          className="ptw-btn btn-primary"
          type="primary"
          htmlType="submit"
          size="large"
          onClick={handleSaveFilter}
        >
          Save Changes
        </Button>,
      ]}
      width={700}
    >
      {admins ? (
        <React.Fragment>
          <Select
            size="large"
            placeholder="Filter RTM patients by their assigned providers..."
            showArrow
            showSearch
            style={{ width: '100%' }}
            value={null}
            onChange={handleSelectProviders}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children[0]
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0 ||
              option.props.value[2]
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0 ||
              option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {renderProviderOptions()}
          </Select>
          <Divider />
          <div className="tab-header">
            <Typography.Title level={4}>
              <span style={{ fontSize: 15 }}>
                Selected Providers{' '}
                {selectedProviders && selectedProviders.length > 0
                  ? `(${selectedProviders.length})`
                  : '(0)'}
              </span>
            </Typography.Title>
            <Space size={24} className="pull-right">
              <Typography.Link
                strong
                underline
                onClick={handleSelectAllProviders}
              >
                Select All
              </Typography.Link>
            </Space>
          </div>

          {selectedProviders.length > 0 ? (
            <div className="providers-tags-container">
              {renderProviderItems()}
              <div
                className="ptw-btn provider-tag provider-clear-all-btn"
                onClick={handleRemoveAllProviders}
              >
                <Typography.Text>Clear All</Typography.Text>
              </div>
            </div>
          ) : (
            <Typography.Title level={4} style={{ marginLeft: 5 }}>
              All Providers
            </Typography.Title>
          )}
        </React.Fragment>
      ) : (
        <Typography.Text>Loading providers...</Typography.Text>
      )}
    </Modal>
  );
};

export default FilterPatientsModal;

import { atom } from 'recoil';

const defaultState = {
  analytics: [],
  dashboard: {
    page: 1,
    status: null,
    search: '',
    sortFilters: []
  }
};

const RTM_STATE = atom({
  key: 'RTM_STATE',
  default: defaultState
});

export default RTM_STATE;

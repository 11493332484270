import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { getRecoil } from 'recoil-nexus';

import isEmail from 'validator/lib/isEmail';
import config from '../config';
import states from '../states';

const regExp =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

const asyncValidate = (values, dispatch, { initialValues }, key) =>
  new Promise((resolve, reject) => {
    if (!key) {
      resolve();
    }

    const value = values[key].trim();
    const contact = key === 'contact' ? 'email or phone number' : 'email';

    if (!value || value === initialValues[key]) {
      resolve();
    } else {
      if (!isEmail(value) && !value.match(regExp)) {
        reject({ [key]: `Invalid ${contact}.` });
      }

      let input = value.trim().toLowerCase();
      if (!isEmail(input)) {
        const phoneNumber = input.replace(/[()\s\-]/g, '');
        if (phoneNumber.length < 11 && !phoneNumber.startsWith('+1')) {
          input = '+1' + phoneNumber;
        } else if (phoneNumber.length < 12 && !phoneNumber.startsWith('+')) {
          input = '+' + phoneNumber;
        }
      }

      fetch(
        `${config.root}/users/external?input=${encodeURIComponent(input)}`
      ).then((response) => {
        response.json().then((data) => {
          if (data.sub === input) {
            resolve();
          } else {
            const groupState = getRecoil(states.groups);
            const isEmailAdd = isEmail(value);
            const type = isEmailAdd ? 'email address' : 'phone number';

            let message = null;
            if (data.role === 'p') {
              message = `This ${type} is already used by a patient in another group. Please use another ${type} or contact PT Wired support for assistance.`;

              if (data.group === groupState.user?.id) {
                message = (
                  <Typography.Text type="danger">
                    This {type} is already used by another patient in your
                    group.{' '}
                    <Link to={'/patients/' + data.sub}>
                      <Typography.Link>Click here</Typography.Link>
                    </Link>{' '}
                    to access the existing account.
                  </Typography.Text>
                );
              }
            } else if (
              data.role === 'pt' ||
              data.role === 'ga' ||
              data.role === 'sa'
            ) {
              message = `This ${type} is already registered in the system as a provider account.`;
            }

            reject({
              [key]: message
            });
          }
        });
      });
    }
  });

export default asyncValidate;

/* global RSSParser */
import React, { Component } from 'react';
import $ from 'jquery';
import Article from './Article';
import Feed from './Feed';
import 'rss-parser/dist/rss-parser.min.js';
import {connect} from "react-redux";
import {withRouter} from "react-router";
import ReactGA from "react-ga";

const CORS_PROXY = "https://cors-anywhere.herokuapp.com/";

class NewsFeed extends Component{
    constructor(props){
        super(props);
        this.state= {
            isLoading: false,
            unavailable: false,
            blogData: [],
            currentPage: null,
            showModal: false,
            selectedPage: null,
        }
        this.processRss = this.processRss.bind(this);
        this.goBack = this.goBack.bind(this);
        this.thumbClick = this.thumbClick.bind(this);
    }

    checkTag(tagName){

        switch (tagName){
            case "B":
            case "H1":
            case "H2":
            case "H3":
            case "H4":
            case "H5":
                return true;
            default:
                return false;
        }
    }

    processContent(content){

        const img = [];

        $(content).each((index,item)=>{
            const src = $(item).attr('src');
            if(src){
                img.push(src);
            }
        });
        // const img = $(content).find('img').attr('src');
        const jQuery = $(content);

        const items = {src: img[0], text:[]};

        jQuery.each((index, item)=>{

            let obj = {};

            if(this.checkTag($(item).context.tagName)){

                obj.text = <h4>{ $(item).text() }</h4>;

            }else{
                obj.text = $(item).text();

            }


            items.text[index] = obj;
        });

        return items;
    }

    processObjects(blogObjects){

        blogObjects.map((item)=>{
            item.content = this.processContent(item['content:encoded']);
            return item;
        });

        return blogObjects;
    }

    async processRss(blogUrl){


        let Parser = new RSSParser();


        let feed = await Parser.parseURL(CORS_PROXY+blogUrl);

        let blogObjects = [];

        feed.items.forEach(item => {
            blogObjects.push(item);
        });

        const processedBlogObjects = this.processObjects(blogObjects);

        this.setState({blogData:processedBlogObjects});

        this.setState({isLoading: false});


    }

    componentDidMount(){
        const {clinic} = this.props;
        const blogUrl = clinic.BlogUrl;

        this.setState({isLoading: true});
        this.processRss(blogUrl);
    }

    thumbClick(item){
        const {publicPage} = this.props;

        ReactGA.event({
            category: 'Mobile',
            action: 'Blog Navigation',
            label: publicPage.name,
        });

        this.setState({selectedPage: item,showModal: true})
    }

    goBack(){
        this.setState({selectedPage: null,showModal: false})
    }

    render(){
        const {isLoading, unavailable, blogData, showModal,selectedPage} = this.state;

        if(!showModal) {return (<Feed isLoading={isLoading} unavailable={unavailable} blogData={blogData} thumbClick={this.thumbClick}/>)}
        return (<Article item={selectedPage} goBack={this.goBack}/>)
    }
}

const mapStateToProps = (state) =>{
    return{
        ...state,
        clinic: state.clinics.currentClinic,
        publicPage: state.publicPage,
    }
}
export default connect(mapStateToProps)(withRouter(NewsFeed))

import {
  minLength,
  maxLength,
  email,
  alphaNumericAndSpecial,
  compoundValidator,
  phone
} from '../../validators/generic'

const minLength1 = minLength(1)
const maxLength30 = maxLength(30)
const maxLength50 = maxLength(50)
const maxLength254 = maxLength(254)

export const firstNameValidator = compoundValidator([maxLength30, minLength1, alphaNumericAndSpecial], 'Invalid First Name')
export const lastNameValidator = compoundValidator([maxLength50, minLength1, alphaNumericAndSpecial], 'Invalid Last Name')
export const phoneValidator = compoundValidator([phone], 'Invalid phone number')
export const emailValidator = compoundValidator([email, maxLength254], 'Invalid email')
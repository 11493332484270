import React, { useState, useEffect } from 'react';
import { Typography, Divider, Col } from 'antd';

const formatNumToTime = (num) => (num.toString().length > 1 ? num : `0${num}`);

const TotalTime = (props) => {
  const { streamDuration, recentMonitoredLogs } = props;

  const [totalTime, setTotalTime] = useState(0);

  useEffect(() => {
    let recentTotalTime = 0;

    if (recentMonitoredLogs) {
      recentTotalTime = recentMonitoredLogs.totalClinicalMonitoringTime;
    }

    // console.log('recentMonitoredLogs', recentMonitoredLogs);
    setTotalTime(recentTotalTime);
  }, [recentMonitoredLogs]);

  useEffect(() => {
    if (streamDuration.current !== 0) {
      setTotalTime(totalTime + 1000);
    }
  }, [streamDuration.current]);

  const convertTotalTime = () => {
    const hours = formatNumToTime(Math.floor(totalTime / (1000 * 60 * 60)));
    const minutes = formatNumToTime(Math.floor(totalTime / (1000 * 60)) % 60);
    const seconds = formatNumToTime(Math.floor(totalTime / 1000) % 60);

    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <React.Fragment>
      <Col span={17}>
        <Typography.Text strong>Total Time</Typography.Text>
      </Col>
      <Col span={7}>
        <Typography.Text strong>{convertTotalTime()}</Typography.Text>
      </Col>
      <Divider />
    </React.Fragment>
  );
};

export default TotalTime;

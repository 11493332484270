import React, { useRef } from 'react';
import moment from 'moment';
import { Modal, Button, Input } from 'antd';
import LoadingPage from '../../../../components/LoadingPage';
import setNotification from '../../../../utils/setNotification.utils';

const getDateTime = value => {
  const start = value.indexOf('{') + 1;
  const end = value.indexOf('}');

  return value.substring(start, end);
};

const formatDateTime = (string, datetime) => {
  if (string.includes('date')) {
    return moment(Number(datetime)).format('L');
  } else if (string.includes('time')) {
    return moment(Number(datetime)).format('LT');
  }
};

const formatDocument = document => {
  return document
    .split(' ')
    .map(doc => {
      if (doc.includes('{') && doc.includes('}')) {
        const value = getDateTime(doc);

        if (doc.includes('\n')) {
          return doc
            .split('\n')
            .map(doc2 => {
              if (doc2.includes('{') && doc2.includes('}')) {
                const value = getDateTime(doc2);
                return formatDateTime(doc2, value);
              }

              return doc2;
            })
            .join('\n');
        } else {
          return formatDateTime(doc, value);
        }
      }

      return doc;
    })
    .join(' ');
};

const BillingDocumentModal = ({
  openBillingDocument,
  setOpenBillingDocument,
  billingDocumentDetails,
  date,
  bill_number,
  fromPopup,
  refreshBillingList,
}) => {
  if (!billingDocumentDetails) {
    return null;
  }

  const { state, data } = billingDocumentDetails;
  const documentRef = useRef();
  const billingDoc = data && formatDocument(data);

  const handleCopyToClipboard = () => {
    const el = document.createElement('textarea');
    el.value = billingDoc;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    setNotification('success', 'Success!', 'Copied to clipboard.');
  };

  const handleCloseModal = () => {
    setOpenBillingDocument(!openBillingDocument);

    if (fromPopup) {
      refreshBillingList(true);
    }
  };

  return (
    <Modal
      title={`${date} (${bill_number})`}
      visible={openBillingDocument}
      onCancel={handleCloseModal}
      maskClosable
      footer={[
        <Button
          key="copy-key"
          className="ptw-btn btn-primary"
          type="primary"
          htmlType="submit"
          size="large"
          onClick={handleCopyToClipboard}
        >
          Copy to Clipboard
        </Button>,
        <Button
          key="cancel-key"
          className="ptw-btn"
          type="default"
          htmlType="submit"
          size="large"
          onClick={handleCloseModal}
        >
          Cancel
        </Button>,
      ]}
      width={700}
    >
      {state === 'loading' ? (
        <LoadingPage type="list" />
      ) : (
        <Input.TextArea
          className="textarea-copy-to-clipboard"
          ref={documentRef}
          value={billingDoc}
          readOnly={true}
          style={{ height: 550 }}
        />
      )}
    </Modal>
  );
};

export default BillingDocumentModal;

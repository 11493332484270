import React, { Fragment, useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import {
  Button,
  Card,
  Col,
  Pagination,
  Progress,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import {
  CalendarOutlined,
  FieldTimeOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faPhoneSlash } from '@fortawesome/free-solid-svg-icons';
import { withinMonth, toUTCDate } from './PatientDetailDrawer/mixins';

import moment from 'moment';
import v from 'voca';
import states from '../../../../states';

const getCardColorIndicator = status => {
  switch (status) {
    case 'GREEN':
      return {
        title: 'Ready for Billing',
        className: 'card-ready-to-bill',
      };
    case 'BLUE':
      return {
        title: 'On Track for Billing',
        className: 'card-high-engagement',
      };
    case 'YELLOW':
      return {
        title: 'Not On Track for Billing',
        className: 'card-low-engagement',
      };
    case 'RED':
      return {
        title: 'No Activity Reported',
        className: 'card-no-engagement',
      };
    default:
      return {
        title: '',
        className: '',
      };
  }
};

const setStringEllipsis = input => {
  if (!input) return;
  if (input.length >= 30) {
    return input.substring(0, 30) + '...';
  }

  return input;
};

const PatientsTable = ({
  searchTerm,
  selectedView,
  patients,
  openDrawer,
  view,
  visibleProfile,
}) => {
  const [rtmState, setRtmState] = useRecoilState(states.rtm);
  const [pagination, setPagination] = useState({
    pageSize: 24,
    currentPage: 1,
    min: 0,
    max: 0,
  });

  useEffect(() => {
    const page = rtmState.dashboard.page;

    if (!!searchTerm || !!selectedView) {
      setPagination({
        ...pagination,
        min: 0,
        max: pagination.pageSize,
        currentPage: 1,
      });
    } else if (page > 1) {
      setPagination({
        ...pagination,
        currentPage: page,
        min: (page - 1) * pagination.pageSize,
        max: page * pagination.pageSize,
      });
    } else {
      setPagination({
        ...pagination,
        min: 0,
        max: pagination.pageSize,
      });
    }
  }, [searchTerm, selectedView]);

  const handleChangePage = page => {
    setRtmState(prevState => ({
      ...prevState,
      dashboard: {
        ...prevState.dashboard,
        page,
      },
    }));

    setPagination({
      ...pagination,
      currentPage: page,
      min: (page - 1) * pagination.pageSize,
      max: page * pagination.pageSize,
    });
  };

  const handleShowTotal = (total, range) =>
    `${range[0]}-${range[1]} of ${total} items`;

  if (view === 'LIST') {
    return (
      <Fragment>
        <Table
          scroll={{
            x: 'max-content',
          }}
          pagination={{ showSizeChanger: false }}
          size="small"
          tableLayout="fixed"
          columns={[
            {
              title: 'RTM Status',
              dataIndex: 'Status',
              key: 'status',
              render: data => (
                <Tooltip title={getCardColorIndicator(data).title}>
                  <div
                    className={`rtm-indicator randomIndicatorSelector ${
                      getCardColorIndicator(data).className
                    }`}
                  />
                </Tooltip>
              ),
            },
            {
              title: 'Patient',
              dataIndex: 'Patient',
              key: 'patient',
              render: data => {
                const { GroupInfo } = visibleProfile;
                const { EmailAddress, PhoneNumber } = data;
                const contact = !!GroupInfo.EnablePhoneNumber
                  ? EmailAddress || PhoneNumber
                  : EmailAddress || 'NOT AVAILABLE';

                return (
                  <Space direction="vertical" size={4}>
                    <Typography.Text strong>{data.Name}</Typography.Text>
                    <Typography.Text type="secondary">
                      {contact}
                    </Typography.Text>
                  </Space>
                );
              },
            },
            {
              title: 'Enrollment Date',
              dataIndex: 'EnrollStart',
              key: 'enrollStart',
            },
            {
              title: 'Time remaining in 20-min threshold',
              dataIndex: 'MinsLeft',
              key: 'minsLeft',
            },
            {
              title: 'Call Status',
              dataIndex: 'LiveCallDate',
              key: 'liveCallDate',

              render: data => (
                <Typography.Text type={data ? 'success' : 'danger'}>
                  {data ? 'Done' : 'Not Called Yet'}
                </Typography.Text>
              ),
            },
            {
              title: '',
              dataIndex: 'Action',
              key: 'Action',

              render: patient => (
                <Tooltip title="View Patient RTM Details">
                  <Button
                    type="text"
                    size="large"
                    shape="round"
                    onClick={() => openDrawer(patient)}
                  >
                    <RightOutlined style={{ fontSize: 26 }} />
                  </Button>
                </Tooltip>
              ),
            },
          ]}
          dataSource={
            patients &&
            patients.map((patient, i) => {
              const {
                Status,
                EnrollStart,
                LiveCallDate,
                MinsLeft,
                FirstName,
                LastName,
                EmailAddress,
                PhoneNumber,
                BillCode,
                TimerDuration,
              } = patient;

              return {
                key: i,
                Status: Status,
                Patient: {
                  Name: `${v.capitalize(FirstName)} ${v.capitalize(LastName)}`,
                  EmailAddress: EmailAddress || PhoneNumber,
                },
                EnrollStart: moment(EnrollStart).format('MMMM D, YYYY'),
                MinsLeft: `${
                  BillCode === 98980 && TimerDuration === 1200000 ? 0 : MinsLeft
                }m left`,
                LiveCallDate: withinMonth(LiveCallDate),
                Action: patient,
              };
            })
          }
        />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Row gutter={[16, 16]}>
        {patients &&
          patients.slice(pagination.min, pagination.max).map((patient, i) => {
            const {
              EnrollStart,
              TimerDate,
              TimerDuration,
              LiveCallDate,
              MinsLeft,
              BillCode,
            } = patient;
            let percent = 0;

            if (withinMonth(TimerDate)) {
              const progress = Math.floor(TimerDuration / 60000) / 0.2;
              const str = progress.toString();

              percent = progress;
              if (progress > 100) {
                percent = progress % 100 || 100;
              } else if (progress === 100 && withinMonth(LiveCallDate)) {
                percent = 0;
              }
            }

            const progressStrokeColor =
              BillCode === 98980 ? '#009E60' : '#0F52BA';

            return (
              <Col key={i} xl={6} lg={12} md={12} sm={12} xs={24}>
                <Card className="rtm-card" onClick={() => openDrawer(patient)}>
                  <Tooltip
                    title={`Billing Code ${BillCode}`}
                    align={{
                      offset: [0, 16],
                    }}
                  >
                    <Progress
                      percent={percent}
                      strokeColor={{
                        from: progressStrokeColor,
                        to: progressStrokeColor,
                      }}
                      showInfo={false}
                      strokeLinecap="square"
                      className="rtm-progress"
                    />
                  </Tooltip>
                  <div className="rtm-card-header">
                    <div
                      className={`rtm-indicator randomIndicatorSelector ${
                        getCardColorIndicator(patient.Status).className
                      }`}
                    />
                    <Space size={0}>
                      <Tooltip title="The monitoring time remaining until this patient is eligible for the next 98980 or 98981 code.">
                        <Tag className="rtm-time">
                          {BillCode === 98980 && TimerDuration === 1200000
                            ? 0
                            : MinsLeft}
                          m left <FieldTimeOutlined />
                        </Tag>
                      </Tooltip>

                      <Tooltip title="The date this patient was enrolled in RTM.">
                        <Tag className="rtm-time">
                          {toUTCDate(EnrollStart)} <CalendarOutlined />
                        </Tag>
                      </Tooltip>
                    </Space>
                  </div>
                  <div className="rtm-card-body">
                    <Space direction="vertical" size={4}>
                      <Typography.Text strong>
                        {setStringEllipsis(
                          `${v.capitalize(patient.FirstName)} ${v.capitalize(
                            patient.LastName
                          )}`
                        )}
                      </Typography.Text>
                      <Typography.Text type="secondary">
                        {setStringEllipsis(
                          patient.EmailAddress || patient.PhoneNumber
                        )}
                      </Typography.Text>
                    </Space>

                    {withinMonth(LiveCallDate) ? (
                      <Space>
                        <div className="rtm-call-badge">
                          <FontAwesomeIcon icon={faPhone} />
                        </div>
                      </Space>
                    ) : (
                      withinMonth(TimerDate) &&
                      BillCode === 98980 &&
                      TimerDuration >= 1200000 && (
                        <Tooltip title="The patient has been monitored for the required 20 minutes, but a live interaction is still needed to bill code 98980.">
                          <Space>
                            <div className="rtm-need-call-badge">
                              <FontAwesomeIcon icon={faPhoneSlash} />
                            </div>
                          </Space>
                        </Tooltip>
                      )
                    )}
                  </div>
                </Card>
              </Col>
            );
          })}
      </Row>

      <div className="pagination-container align-right">
        <Pagination
          responsive
          total={patients.length}
          pageSize={pagination.pageSize}
          current={pagination.currentPage}
          showSizeChanger={false}
          showTotal={handleShowTotal}
          onChange={handleChangePage}
          style={{ bottom: '0px' }}
        />
      </div>
    </Fragment>
  );
};

export default PatientsTable;

import { minLength, maxLength, email, alphaNumeric, alphaNumericAndSpecial, compoundValidator, phone } from './generic'
import { getClinic } from "../actions/clinics";
import voca from 'voca'

const minLength1 = minLength(1)
const maxLength254 = maxLength(254)
const maxLength128 = maxLength(128)
const minLength5 = minLength(5)

export const clinicNameValidator = compoundValidator([maxLength254, alphaNumericAndSpecial], 'Invalid clinic name')
export const addressValidator = compoundValidator([maxLength254, minLength1, alphaNumericAndSpecial], 'Invalid address')
export const cityValidator = compoundValidator([maxLength128, minLength1, alphaNumeric], 'Invalid city')
export const contactNameValidator = compoundValidator([maxLength254, minLength1, alphaNumericAndSpecial], 'Invalid contact name')
export const contactEmailValidator = compoundValidator([email], 'Invalid contact email')
export const zipValidator = compoundValidator([minLength5], 'Invalid zip')
export const phoneValidator = compoundValidator([phone], 'Invalid phone number')

export const asyncValidate = (values, dispatch, props) => {
  return new Promise((resolve, reject) => {
    if(values.clinicName && values.clinicName.trim() !== props.initialValues.clinicName){
      dispatch(getClinic(props.groupName, voca.lowerCase(values.clinicName))).then(({value}) => {
        resolve();
        return;
        // if(value.message === "Success"){
        //   reject({clinicName: "Clinic Name already exists for this Group"})
        // }else{
        //   resolve()
        // }
      }).catch(errorResponse => {
        if (errorResponse.status === 404) {
          resolve()
        } else {
          resolve()
        }
      })
    } else {
      resolve()
    }
  })
}
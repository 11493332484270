import React, {useEffect, useState} from 'react';
import { AddGroupAdmin} from './components';
import {Button, Row, Col, Table, Modal, Alert} from 'react-bootstrap';
import {adminCreateUser, adminUpdateUser} from "../actions/groups";
import Loading from '../../../components/Loading'
import SearchBox from '../../../components/SearchBox'
import {getAdmins} from "../../users/actions/users";
import {success} from 'react-notification-system-redux';
export default function GroupAdminsPage ({dispatch, currentGroup}){

    const [isLoading, setIsLoading] = useState(true);
    const [userState, setUserState] =useState(null);
    const [groupAdmins, setGroupAdmins] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState(null);
    const [currentEdit, setCurrentEdit] = useState(null);
    const [edit, setEdit] = useState(false)

    const load = ()=>{
        const {id} = currentGroup;
        dispatch(getAdmins(id)).then(({value})=>{
            setGroupAdmins(value);
            setIsLoading(false);
        }).catch(err=>setError(err))
    }

    /**
     * Initialize data
     */

    useEffect(()=>{

        load();

    },[]);
    /**
     * Check Errors
     */
    useEffect(()=>{
        if(error){
            console.log('Error',error)
        }
        setTimeout(()=>{
            setError(null)
        },5000)
    },[error]);

    /**
     * Form Submit
     * @param form
     */
    const submit =(form)=>{

        let response;

        if(userState === 'update'){
            console.log('update', form)
            response = dispatch(adminUpdateUser(form))
        }else if(userState === 'new'){
            console.log('new', form)
            response = dispatch(adminCreateUser(form))
        }else{
            console.log('Invalid user state');
            return;
        }

        response.then(({value:{message}})=>{

            dispatch(success({title:message, position:"tc"}))
            load();
            setShowModal(false);
        }).catch(error=>{
            setError(error);
            setShowModal(false);
        })
    };

    if(isLoading){ return <Loading isLoading={true}/>}

    return (
        <div>
            { error &&
            <Alert variant={'warning'}>
                Error Adding User
            </Alert>
            }
            {( Array.isArray(groupAdmins) && groupAdmins.length > 0 ) &&
                <div>
                    <Row>
                        <Col xs={12} md={8}>
                            <Button className="btn-primary btn-icon pull-right" onClick={()=>{
                                setEdit(false)
                                setCurrentEdit(null);
                                setUserState('new');
                                setShowModal(true);
                            }}>
                                <i className="fa fa-plus" aria-hidden="true"></i>
                            </Button>
                        </Col>
                        <Col xs={12} md={4}>
                            <SearchBox onChange={()=>{}} placeholder='Admin Name' />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Username</th>
                                    <th>Role</th>
                                </tr>
                                </thead>
                                <tbody>
                                {groupAdmins.map((item, i)=>{
                                    const {FirstName, LastName, EmailAddress, Active, Role} = item;

                                    return (
                                    <tr onClick={()=>{
                                        setEdit(true)
                                        setUserState('update');
                                        setCurrentEdit(item);
                                        setShowModal(true)

                                    }}>
                                        <td style={(Active === 'true' || Active === true) ? {} : {color:'lightgrey'}}>
                                            {i+1}
                                        </td>
                                        <td style={(Active === 'true' || Active === true) ? {} : {color:'lightgrey'}}>
                                            {FirstName}
                                        </td>
                                        <td style={(Active === 'true' || Active === true) ? {} : {color:'lightgrey'}}>
                                            {LastName}
                                        </td>
                                        <td style={(Active === 'true' || Active === true) ? {} : {color:'lightgrey'}}>
                                            {EmailAddress}
                                        </td>
                                        <td style={(Active === 'true' || Active === true) ? {} : {color:'lightgrey'}}>
                                            {Role}
                                        </td>
                                    </tr>
                                    )})
                                }
                                </tbody>
                            </Table>
                            {(groupAdmins.length === 0) &&
                                <div className="text-center">No Results Found</div>
                            }
                        </Col>
                    </Row>

                </div>
            }
            <Row>
                <Col md={4} mdOffset={4} className="ptw-action-block">
                    <Button className="btn-primary btn-block" onClick={()=>{
                        setEdit(false)
                        setCurrentEdit(null);
                        setUserState('new');
                        setShowModal(true);
                    }}>Add Admin</Button>
                </Col>
            </Row>
            {showModal &&
                <Modal show={true} onHide={()=>{setShowModal(false)}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add/Edit Admin</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AddGroupAdmin onSubmit={submit} currentGroup={currentGroup} user={currentEdit} edit={edit} />
                    </Modal.Body>
                </Modal>
            }
        </div>
    )
}


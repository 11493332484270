import React from 'react';
import { Space, Switch, Typography } from 'antd';

const FormSwitch = props => {
  const {
    tooltip,
    feedbackIcon,
    label,
    placeholder,
    componentClass,
    hideErrorMessage,
    isRequired,
    defaultChecked,
    checkedChildren,
    unCheckedChildren,
    input,
    meta: { error, warning, validating, touched },
    children,
    onSearch = null,
    ...rest
  } = props;

  let message;
  if (!hideErrorMessage && touched && (error || warning || validating)) {
    message = error || warning || validating;
  }

  return (
    <React.Fragment>
      <Space size={8}>
        <Switch
          defaultChecked={defaultChecked}
          checkedChildren={checkedChildren}
          unCheckedChildren={unCheckedChildren}
          checked={input.checked}
          onChange={input.onChange}
          {...rest}
        />
        <Typography.Text>{label}</Typography.Text>
      </Space>
    </React.Fragment>
  );
};

export default FormSwitch;

import React,{useState} from 'react'
import _ from 'lodash';
import {Row, Col, FormGroup, ControlLabel, FormControl, Button, ButtonGroup, Radio} from 'react-bootstrap'

const defaultForm = {
    firstName: null,
    lastName: null,
    emailAddress: null,
    role: 'pt',
    billingRole: 'PT',
    active: true,
};

let AddGroupAdmin = ({ onSubmit, currentGroup, user, edit = false }) => {
    const {id:GroupId} = currentGroup;

    let initialData;

    if(user){
        initialData = user;
    }else{
        initialData = defaultForm;
    }

    const {emailAddress, firstName, lastName} = initialData;

    const omitted = _.omit(initialData, ['EmailAddress', 'FirstName', 'LastName', 'Role', 'BillingRole', 'Active']);

    const [form, setForm] = useState({...omitted, groupId: GroupId});
    const [disabled, setDisabled] = useState(false);

    const editForm = (field, value)=>{

        const edit = {...form, };
        edit[field] = value;
        setForm(edit);
    };

    return (<div>
        <Row>
            <Col xs={6}>
                <FormGroup>
                    <ControlLabel>First Name</ControlLabel>
                    <FormControl type={'text'} label={'first-name'} defaultValue={firstName}
                                 onChange={(e)=>editForm( 'firstName', e.target.value)}/>
                </FormGroup>
            </Col>
            <Col xs={6}>
                <FormGroup>
                    <ControlLabel>Last Name</ControlLabel>
                    <FormControl type={'text'} label={'last-name'} defaultValue={lastName}
                                 onChange={(e)=>editForm( 'lastName', e.target.value)}/>
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col xs={6}>
                <FormGroup>
                    <ControlLabel>Email Address</ControlLabel>
                    <FormControl type={'text'} label={'email'} defaultValue={emailAddress}
                                 disabled={edit}
                                 onChange={(e)=>editForm( 'emailAddress', e.target.value)}/>
                </FormGroup>
            </Col>
            <Col xs={6}>
                <FormGroup>
                    <ControlLabel>Role</ControlLabel>
                    <FormControl componentClass="select" label={'role'}  onChange={(e)=>editForm( 'role', e.target.value)}>
                        <option value={'pt'} key={1}>PT</option>
                        <option value={'ga'} key={2}>Group Admin</option>
                    </FormControl>
                </FormGroup>
            </Col>
            <Col xs={6}>
                <FormGroup>
                    <ControlLabel>Billing Role</ControlLabel>
                    <FormControl componentClass="select" label={'billing-role'}  onChange={(e)=>editForm( 'billingRole', e.target.value)}>
                        <option value={'PT'} key={1}>PT</option>
                        <option value={'GA'} key={2}>Group Admin</option>
                        <option value={'TECH'} key={3}>Technician</option>
                    </FormControl>
                </FormGroup>
            </Col>
            <Col xs={6}>
                <ControlLabel>Active</ControlLabel>
                <FormGroup onChange={(e)=>editForm( 'active', e.target.value)}>
                    <Radio name="radioGroup" inline value={true}>
                        True
                    </Radio>{' '}
                    <Radio name="radioGroup" inline value={false}>
                        False
                    </Radio>{' '}

                </FormGroup>
            </Col>
            <Col xs={6}>
                <FormGroup>
                    <ControlLabel>Password</ControlLabel>
                    <FormControl type={'text'} label={'password'} defaultValue={''} disabled={edit}
                                 onChange={(e)=>editForm( 'password', e.target.value)}/>
                </FormGroup>
            </Col>
            <Col xsOffset={10} xs={2}>
                <ButtonGroup>
                    <Button disabled={disabled} onClick={()=>{
                        setDisabled(true);
                        onSubmit(form)
                    }}>Submit</Button>
                </ButtonGroup>
            </Col>
        </Row>
    </div >)
}

export default AddGroupAdmin;
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Form, Input, Popover, Select, Space, Switch, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import v from 'voca';
import states from '../../../states';

const exerciseFrequencyContent = (
  <span>
    Rather than choosing a single frequency for all exercises in the
    prescription, <br />
    use Exercise Level Frequency to choose a specific frequency for each
    exercise.
  </span>
);

const EditPrescriptionDetailsForm = ({
  isExerciseFrequency,
  handleIsExerciseFrequency,
}) => {
  const [prescription, setPrescription] = useRecoilState(states.prescription);
  const { form } = prescription;

  const patients = useRecoilValue(states.patients);
  const fname = patients.details?.firstName || '';
  const lname = patients.details?.lastName || 'Patient';
  const patientName = `${v.capitalize(fname)} ${v.capitalize(lname)}`;
  const prescriptionName = `${patientName}'s Home Exercise Program`;

  useEffect(() => {
    setPrescription({
      ...prescription,
      form: {
        ...form,
        name: form.name || prescriptionName,
      },
    });
  }, []);

  if (!form) return null;

  return (
    <Form layout="vertical" className="mt-3">
      <Form.Item name="name" label="Prescription Name" required>
        <Input
          type="text"
          size="large"
          name="name"
          placeholder="Enter prescription name"
          defaultValue={form.name || prescriptionName}
          value={form.name || prescriptionName}
          onChange={e => {
            setPrescription({
              ...prescription,
              form: {
                ...form,
                name: e.target.value,
              },
            });
          }}
        />
      </Form.Item>
      <Space direction="horizontal" className="hide-mb mb-3">
        <div>
          <Typography.Paragraph>Prescription Frequency</Typography.Paragraph>
          <div className="mt-1">
            <Select
              size="large"
              value={form.prescription.frequency}
              defaultValue={form.prescription.frequency}
              virtual={false}
              name="frequency"
              placeholder="Enter frequency"
              onChange={val =>
                setPrescription({
                  ...prescription,
                  form: {
                    ...form,
                    prescription: {
                      ...form.prescription,
                      frequency: val,
                    },
                  },
                })
              }
              style={{ minWidth: 100 }}
              disabled={isExerciseFrequency}
            >
              <Select.Option value={1}>1x</Select.Option>
              <Select.Option value={2}>2x</Select.Option>
              <Select.Option value={3}>3x</Select.Option>
              <Select.Option value={4}>4x</Select.Option>
              <Select.Option value={5}>5x</Select.Option>
              <Select.Option value={6}>6x</Select.Option>
              <Select.Option value={7}>7x</Select.Option>
              <Select.Option value={8}>8x</Select.Option>
              <Select.Option value={9}>9x</Select.Option>
              <Select.Option value={10}>10x</Select.Option>
            </Select>
            <span className="ml-2 mr-2">Every</span>
            <Select
              size="large"
              value={form.prescription.perdiem}
              defaultValue={form.prescription.perdiem}
              virtual={false}
              name="perdiem"
              placeholder="Enter per diem"
              onChange={val =>
                setPrescription({
                  ...prescription,
                  form: {
                    ...form,
                    prescription: {
                      ...form.prescription,
                      perdiem: val,
                    },
                  },
                })
              }
              style={{ minWidth: 100 }}
              disabled={isExerciseFrequency}
            >
              <Select.Option value={'day'}>Day</Select.Option>
              <Select.Option value={'week'}>Week</Select.Option>
              <Select.Option value={'month'}>Month</Select.Option>
            </Select>
          </div>
        </div>
        <div className="ml-3">
          <Typography.Paragraph>
            Exercise-level Frequency{' '}
            <Popover
              title="Exercise-level Frequency"
              content={exerciseFrequencyContent}
            >
              <InfoCircleOutlined />
            </Popover>
          </Typography.Paragraph>
          <Switch
            checked={isExerciseFrequency}
            size="large"
            checkedChildren="On"
            unCheckedChildren="Off"
            onChange={() => handleIsExerciseFrequency(!isExerciseFrequency)}
          />
        </div>
      </Space>
      <Space direction="vertical" className="hide-dt show-mb">
        <div className="mb-2">
          <Typography.Paragraph>Prescription Frequency</Typography.Paragraph>
          <div className="mt-1">
            <Select
              size="large"
              value={form.prescription.frequency}
              defaultValue={form.prescription.frequency}
              virtual={false}
              name="frequency"
              placeholder="Enter frequency"
              onChange={val =>
                setPrescription({
                  ...prescription,
                  form: {
                    ...form,
                    prescription: {
                      ...form.prescription,
                      frequency: val,
                    },
                  },
                })
              }
              style={{ minWidth: 100 }}
              disabled={isExerciseFrequency}
            >
              <Select.Option value={1}>1x</Select.Option>
              <Select.Option value={2}>2x</Select.Option>
              <Select.Option value={3}>3x</Select.Option>
              <Select.Option value={4}>4x</Select.Option>
              <Select.Option value={5}>5x</Select.Option>
              <Select.Option value={6}>6x</Select.Option>
              <Select.Option value={7}>7x</Select.Option>
              <Select.Option value={8}>8x</Select.Option>
              <Select.Option value={9}>9x</Select.Option>
              <Select.Option value={10}>10x</Select.Option>
            </Select>
            <span className="ml-2 mr-2">Every</span>
            <Select
              size="large"
              value={form.prescription.perdiem}
              defaultValue={form.prescription.perdiem}
              virtual={false}
              name="perdiem"
              placeholder="Enter per diem"
              onChange={val =>
                setPrescription({
                  ...prescription,
                  form: {
                    ...form,
                    prescription: {
                      ...form.prescription,
                      perdiem: val,
                    },
                  },
                })
              }
              style={{ minWidth: 100 }}
              disabled={isExerciseFrequency}
            >
              <Select.Option value={'day'}>Day</Select.Option>
              <Select.Option value={'week'}>Week</Select.Option>
              <Select.Option value={'month'}>Month</Select.Option>
            </Select>
          </div>
        </div>
        <div className="mb-2">
          <Typography.Paragraph>
            Exercise-level Frequency{' '}
            <Popover
              title="Exercise-level Frequency"
              content={exerciseFrequencyContent}
            >
              <InfoCircleOutlined />
            </Popover>
          </Typography.Paragraph>
          <Switch
            checked={isExerciseFrequency}
            size="large"
            checkedChildren="On"
            unCheckedChildren="Off"
            onChange={() => handleIsExerciseFrequency(!isExerciseFrequency)}
          />
        </div>
      </Space>
      <Form.Item
        name="instructions"
        label="Prescription Instructions"
        rules={[
          {
            validator: async (_, value) => {
              setPrescription({
                ...prescription,
                form: {
                  ...form,
                  prescription: {
                    ...form.prescription,
                    formError: value.length > 1000 || !value.length,
                  },
                },
              });
              if (!value.length) {
                return Promise.reject('Please input your instructions.');
              }

              if (value.length > 1000) {
                return Promise.reject('Max length is 1000 characters.');
              }

              return Promise.resolve();
            },
          },
        ]}
      >
        <Input.TextArea
          size="large"
          placeholder="Enter instructions"
          rows={4}
          defaultValue={form.prescription.instructions}
          value={form.prescription.instructions}
          onChange={e =>
            setPrescription({
              ...prescription,
              form: {
                ...form,
                prescription: {
                  ...form.prescription,
                  instructions: e.target.value,
                },
              },
            })
          }
        />
      </Form.Item>
    </Form>
  );
};

export default EditPrescriptionDetailsForm;

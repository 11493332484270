import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Drawer,
  Tabs,
  Tooltip,
  Typography,
  Spin,
  notification,
  Tag,
  Row
} from 'antd';
import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  MessageOutlined,
  UserOutlined,
  LoadingOutlined,
  CalendarOutlined
} from '@ant-design/icons';
import _ from 'lodash';

import MessageModal from '../../../../../components/MessageModal';
import LoadingPage from '../../../../../components/LoadingPage';
import PatientDataLog from './PatientDataLog';
import ClinicalMonitoringLog from './ClinicalMonitoringLog';
import BillingLog from './BillingLog';
import {
  getPatientDataPoints,
  getClinicMonitorLogs,
  deleteClinicMonitorLogs,
  setManualTimer,
  startLoadingTimer,
  stopLoadingTimer,
  getRTMBilling,
  getRTMDocument,
  updateTimerLogQuery,
  setPatientAnalyticsData,
  loadAnalytics
} from '../../../actions/patients';
import {
  setCurrentMonitoredPatient,
  exitCurrentMonitoredPatient,
  startLoadingMonitoredPatient,
  endLoadingMonitoredPatient,
  clearCurrentMonitoredPatient
} from '../../../../monitor-timer/actions';
import { delay, toUTCDate } from './mixins';

import moment from 'moment';
import v from 'voca';
import services from '../../../../../services';
import states from '../../../../../states';

const { TabPane } = Tabs;

const PatientDetailDrawer = (props) => {
  const {
    dispatch,
    history,
    openPatientDetail,
    handleOpenPatientDetail,
    selectedPatient,
    setSelectedPatient,
    loadingDataPoints,
    dataPoints,
    loadingClinigLogs,
    clinicLogs,
    loadingBilling,
    rtmBilling,
    groupName,
    provider,
    source,
    currentMonitoredPatient,
    isMonitoring,
    selectedProviders,
    setLoadingAnalytics,
    visibleProfile
  } = props;

  const [showMessage, setShowMessage] = useState(null);
  const [dataLogsDate, setDataLogsDate] = useState(null);
  const [clinicLogsDate, setClinicLogsDate] = useState(moment());
  const [isBillingUpdated, setIsBillingUpdated] = useState(false);
  const [prevPatient, setPrevPatient] = useState(null);
  const [billingDocument, setbillingDocument] = useState({
    state: null,
    data: null
  });

  const [rtm, setRTM] = useRecoilState(states.rtm);
  const [patients, setPatients] = useRecoilState(states.patients);

  const prescriptionState = useRecoilValue(states.prescription);
  const group = useRecoilValue(states.groups);

  const stepsFlagColor = dataPoints?.stepTracking === 'enabled' && 'green';

  useEffect(() => {
    if (openPatientDetail === true) {
      setExitActivity('View Patient RTM Details');
      initData(selectedPatient);

      if (selectedPatient) {
        setPrevPatient(selectedPatient);
      }
    }
  }, [openPatientDetail]);

  useEffect(() => {
    fetchItems(selectedPatient);
  }, [clinicLogsDate, dataLogsDate]);

  useEffect(() => {
    if (selectedPatient && prevPatient) {
      if (selectedPatient.Sub !== prevPatient.Sub) {
        setDataLogsDate(null);
      }
    }
  }, [selectedPatient]);

  useEffect(() => {
    if (showMessage === null) return;

    if (showMessage === false) {
      setExitActivity('View Patient RTM Details');
    } else {
      setExitActivity('Message Patient');
    }
  }, [showMessage]);

  const setExitActivity = async (activity = null) => {
    await dispatch(startLoadingMonitoredPatient());

    if (currentMonitoredPatient && isMonitoring) {
      await dispatch(exitCurrentMonitoredPatient());
    }

    await delay(1500);

    if (activity) {
      const payload = {
        patient: selectedPatient,
        activity,
        groupName,
        provider
      };

      await dispatch(setCurrentMonitoredPatient(payload));
    }
    await dispatch(endLoadingMonitoredPatient());
  };

  const refetchLogs = async () => {
    window.location.reload();
  };

  const initData = async (patient) => {
    const sub = patient.Sub;
    const groupId = patient.GroupId;

    dispatch(loadAnalytics(true));
    await services.rtm.runAnalytics(sub, groupId).then(async () => {
      await services.rtm.getAnalyticsBySub(sub).then((response) => {
        setSelectedPatient(response.data);
        setRTM((prevState) => ({
          ...prevState,
          analytics: [...prevState.analytics].map((item) => {
            if (item.Sub !== sub) {
              return item;
            }

            return response.data;
          })
        }));
      });

      fetchItems(patient);
    });
  };

  const fetchItems = async (patient) => {
    if (!patient) {
      return;
    }

    let qryStart = patient.BillingStart;
    let qryEnd = patient.BillingEnd;

    if (dataLogsDate) {
      qryStart = dataLogsDate.startOf('month').valueOf();
      qryEnd = dataLogsDate.endOf('month').valueOf();
    }

    fetchRMTBillingData(patient);
    fetchClinicMonitorLogs(patient);
    fetchPatientDataPoints({
      ...patient,
      BillingStart: qryStart,
      BillingEnd: qryEnd
    });
  };

  const fetchPatientDataPoints = async (patient) => {
    try {
      const query = {
        start: patient.BillingStart,
        end: patient.BillingEnd
      };

      await dispatch(
        getPatientDataPoints(groupName, patient.Sub, patient.GroupId, query)
      );
    } catch (error) {
      notification.warning({
        message: 'Sorry',
        description: (
          <>
            <Typography.Text>
              We seem to be having issues with fetching data.
            </Typography.Text>{' '}
            <Typography.Link strong onClick={refetchLogs}>
              Click here
            </Typography.Link>{' '}
            <Typography.Text>
              to refresh the application and try again. If the issue persists,
              please check your internet connectivity or contact support.
            </Typography.Text>
          </>
        )
      });
    }
  };

  const fetchClinicMonitorLogs = async (patient) => {
    try {
      const start = clinicLogsDate.startOf('month').toDate().getTime();
      const end = clinicLogsDate.endOf('month').toDate().getTime();
      const query = { start, end };

      dispatch(updateTimerLogQuery(query));
      await dispatch(
        getClinicMonitorLogs(groupName, patient.Sub, patient.GroupId, query)
      );
    } catch (error) {
      notification.warning({
        message: 'Sorry',
        description: (
          <>
            <Typography.Text>
              We seem to be having issues with fetching data.
            </Typography.Text>{' '}
            <Typography.Link strong onClick={refetchLogs}>
              Click here
            </Typography.Link>{' '}
            <Typography.Text>
              to refresh the application and try again. If the issue persists,
              please check your internet connectivity or contact support.
            </Typography.Text>
          </>
        )
      });
    }
  };

  const handleDeleteCLinicMonitorLogs = async (logId) => {
    try {
      const { Sub } = selectedPatient;
      await dispatch(deleteClinicMonitorLogs(groupName, logId, Sub));
      await delay(1200);

      notification.success({
        message: 'Success',
        description: 'Monitoring Log Activity has been deleted.'
      });

      await Promise.all([
        services.rtm.getAnalyticsBySub(Sub),
        fetchClinicMonitorLogs(selectedPatient),
        fetchRMTBillingData(selectedPatient)
      ]).then(([response]) => {
        const listCopy = rtm.analytics.slice();
        const index = listCopy.findIndex((c) => c.Sub === Sub);

        if (index >= 0) {
          setRTM((prevState) => ({
            ...prevState,
            analytics: [...prevState.analytics].map((item) => {
              if (item.Sub !== Sub) {
                return item;
              }

              return response.data;
            })
          }));

          if (source !== 'dashboard') {
            dispatch(setPatientAnalyticsData(response.data));
          }
        }
      });
    } catch (error) {
      console.log(error);
      notification.warning({
        message: 'Sorry',
        description:
          'We seem to be having issues with deleting clinic logs. Please check your internet connectivity and try again later. If the issue persists, please contact support.'
      });
    }
  };

  const handleSetManualTimer = async (timerActivity) => {
    try {
      let startDate = timerActivity.start;
      let endDate = timerActivity.end;

      if (timerActivity.date) {
        startDate = `${timerActivity.date} ${timerActivity.start}`;
        endDate = `${timerActivity.date} ${timerActivity.end}`;
      }

      dispatch(startLoadingTimer());

      const startTime = new Date(startDate).getTime();
      const endTime = new Date(endDate).getTime();
      const duration =
        startTime > endTime
          ? endTime + 1000 * 60 * 60 * 24 - startTime
          : endTime - startTime;

      await dispatch(
        setManualTimer(
          groupName,
          selectedPatient.EmailAddress || selectedPatient.PhoneNumber,
          selectedPatient.GroupId,
          selectedPatient.Sub,
          duration,
          timerActivity.activity,
          endTime,
          provider,
          timerActivity.notes
        )
      );

      await delay(1200);
      dispatch(stopLoadingTimer());

      notification.success({
        description: 'RTM time entry was saved successfully.'
      });

      const { Sub } = selectedPatient;
      await Promise.all([
        services.rtm.getAnalyticsBySub(Sub),
        fetchClinicMonitorLogs(selectedPatient),
        fetchRMTBillingData(selectedPatient)
      ]).then(([response]) => {
        const listCopy = rtm.analytics.slice();
        const index = listCopy.findIndex((c) => c.Sub === Sub);

        if (index >= 0) {
          setRTM((prevState) => ({
            ...prevState,
            analytics: [...prevState.analytics].map((item) => {
              if (item.Sub !== Sub) {
                return item;
              }

              return response.data;
            })
          }));

          if (source !== 'dashboard') {
            dispatch(setPatientAnalyticsData(response.data));
          }
        }
      });
    } catch (error) {
      notification.warning({
        message: 'Sorry',
        description: 'We seem to be having issues with saving RTM time entry.'
      });
      dispatch(stopLoadingTimer());
    }
  };

  const fetchRMTBillingData = async (patient) => {
    try {
      const { Sub, GroupId, EnrollStart, BillingEnd } = patient;
      const monthEnd = moment().endOf('month').valueOf();
      const queryEnd = BillingEnd > monthEnd ? BillingEnd : monthEnd;

      const billing = await dispatch(
        getRTMBilling(groupName, Sub, GroupId, {
          start: EnrollStart,
          end: queryEnd
        })
      );

      return billing;
    } catch (error) {
      notification.warning({
        message: 'Sorry',
        description: (
          <>
            <Typography.Text>
              We seem to be having issues with fetching data.
            </Typography.Text>{' '}
            <Typography.Link strong onClick={refetchLogs}>
              Click here
            </Typography.Link>{' '}
            <Typography.Text>
              to refresh the application and try again. If the issue persists,
              please check your internet connectivity or contact support.
            </Typography.Text>
          </>
        )
      });
    }
  };

  const fetchRTMDocument = async (
    id,
    code,
    status,
    patient,
    start,
    end,
    hasPending
  ) => {
    const { visibleProfile } = props;
    const { Sub } = visibleProfile;

    setbillingDocument({
      state: 'loading',
      data: null
    });

    try {
      const firstName =
        patient.FirstName.charAt(0).toUpperCase() + patient.FirstName.slice(1);
      const lastName =
        patient.LastName.charAt(0).toUpperCase() + patient.LastName.slice(1);
      const patientName = encodeURIComponent(`${firstName} ${lastName}`);
      const query = encodeURIComponent(JSON.stringify({ start, end }));

      let additionalQuery = `hasPending=${hasPending}&groupId=${patient.GroupId}&query=${query}`;
      if (code === 98975) {
        const prevCode = rtmBilling?.prevCodes?.find((prev) => prev.Id === id);
        const date = !!prevCode ? prevCode.Start : selectedPatient.EnrollStart;

        additionalQuery = `${additionalQuery}&setupDate=${date}`;
      }

      const document = await dispatch(
        getRTMDocument(
          groupName,
          id,
          patient.Sub,
          code,
          status,
          patientName,
          Sub,
          additionalQuery
        )
      );

      setbillingDocument({
        state: 'success',
        data: document.value
      });
    } catch (error) {
      console.log(error);
      setbillingDocument({
        state: 'error',
        data: error
      });
    }
  };

  const handleRefreshBillingList = async (updated) => {
    const newBilling = await fetchRMTBillingData(selectedPatient).then(
      (response) => response.value
    );

    const newCodes = newBilling.currCodes || [];
    const hasCurrMarked = newCodes.some((curr) => curr.Status === 'marked');

    const rtmCodes = rtmBilling.currCodes || [];
    const hasPrevMarked = rtmCodes.some((prev) => prev.Status === 'marked');

    if (!hasCurrMarked || (!hasPrevMarked && hasCurrMarked)) {
      setIsBillingUpdated(updated);
    }
  };

  const clearMonitoredPatient = async () => {
    if (currentMonitoredPatient && isMonitoring) {
      await dispatch(exitCurrentMonitoredPatient());
    }

    await dispatch(clearCurrentMonitoredPatient());
  };

  const handleCloseDrawer = async () => {
    handleOpenPatientDetail({ patient: null });

    if (currentMonitoredPatient) {
      await dispatch(exitCurrentMonitoredPatient());
    }

    const isEditingPrescription = history
      ? history.location.pathname.endsWith('/prescription')
      : false;
    const activity = isEditingPrescription
      ? 'Edit Exercise Program'
      : 'View Adherence Log';

    let monitoredPatient;
    if (!isEditingPrescription && prescriptionState.list.length > 1) {
      monitoredPatient = null;
    } else {
      monitoredPatient = {
        patient: selectedPatient,
        activity,
        groupName,
        provider
      };
    }

    if (source && source === 'dashboard') {
      monitoredPatient = null;
    }

    await dispatch(startLoadingMonitoredPatient());
    await dispatch(setCurrentMonitoredPatient(monitoredPatient));
    await dispatch(endLoadingMonitoredPatient());

    if (isBillingUpdated) {
      try {
        setLoadingAnalytics(true);

        const providers = selectedProviders.map((prov) => prov.Sub);
        const activeAdmins = group.providers.filter(
          ({ Active }) => Active === true || Active === 'true'
        );
        const providerFilters = _.isEqual(
          [...providers].sort(),
          [...activeAdmins.map((p) => p.Sub)].sort()
        )
          ? []
          : providers;

        const response = await services.rtm.getAnalytics(providerFilters);

        if (response.status === 200) {
          setRTM((prevState) => ({
            ...prevState,
            analytics: [...response.data]
          }));
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingAnalytics(false);
      }
    }
  };

  const contactDetails = () => {
    const { EmailAddress, PhoneNumber } = selectedPatient;
    const { GroupInfo } = visibleProfile;

    if (!!GroupInfo.EnablePhoneNumber) {
      if (EmailAddress && PhoneNumber) {
        return `${EmailAddress} | ${PhoneNumber}`;
      } else {
        return EmailAddress || PhoneNumber;
      }
    } else {
      return EmailAddress || 'NOT AVAILABLE';
    }
  };

  return (
    <Fragment>
      {showMessage && (
        <MessageModal
          open={showMessage}
          onClose={() => {
            setShowMessage(false);

            if (patients.details) {
              setPatients((prevState) => ({
                ...prevState,
                details: null
              }));
            }
          }}
          sub={selectedPatient.Sub}
        />
      )}
      <Drawer
        title="Patient RTM Details"
        placement="right"
        width={736}
        bodyStyle={{ padding: '0px', overflowY: 'auto' }}
        className="patient-rtm-detail-drawer"
        onClose={handleCloseDrawer}
        visible={openPatientDetail}
        maskClosable={false}
        zIndex={4}
      >
        <div className="patient-detail-container">
          <div className="patient-detail-header">
            {selectedPatient ? (
              <Fragment>
                <div className="patient-detail-header-info">
                  <Row justify="start" align="middle">
                    <Typography.Title
                      level={4}
                      style={{ marginBottom: 0, marginRight: 8 }}
                    >
                      {v.capitalize(selectedPatient.FirstName)}{' '}
                      {v.capitalize(selectedPatient.LastName)}
                    </Typography.Title>
                    {!loadingDataPoints &&
                      dataPoints &&
                      dataPoints.stepTracking && (
                        <Tag
                          color={stepsFlagColor}
                          style={{
                            cursor: 'default'
                          }}
                        >
                          STEPS {String(dataPoints.stepTracking).toUpperCase()}
                        </Tag>
                      )}
                    <Tooltip title="The date this patient was enrolled in RTM.">
                      <Tag
                        className="rtm-time"
                        style={{
                          cursor: 'default'
                        }}
                      >
                        {toUTCDate(selectedPatient.EnrollStart)}{' '}
                        <CalendarOutlined />
                      </Tag>
                    </Tooltip>
                  </Row>
                  <span>{contactDetails()}</span>
                </div>
                <div className="patient-detail-header-controls">
                  {/* <Tooltip title="Phone Call">
                    <PhoneOutlined className="patient-detail-header-controls-icon" />
                  </Tooltip> */}

                  {source === 'dashboard' && selectedPatient && (
                    <>
                      <Tooltip title="Message">
                        <MessageOutlined
                          className="patient-detail-header-controls-icon"
                          onClick={() => {
                            setShowMessage(true);
                            setPatients((prevState) => ({
                              ...prevState,
                              details: {
                                firstName: selectedPatient.FirstName,
                                lastName: selectedPatient.LastName,
                                emailAddress: selectedPatient.EmailAddress,
                                sub: selectedPatient.Sub
                              }
                            }));
                          }}
                        />
                      </Tooltip>

                      <Link
                        to={`/patients/${selectedPatient.Sub}`}
                        onClick={clearMonitoredPatient}
                      >
                        <Tooltip title="Access Profile">
                          <UserOutlined className="patient-detail-header-controls-icon" />
                        </Tooltip>
                      </Link>
                    </>
                  )}
                  {/* <Button className="patient-detail-header-controls-button">
                    Pending bill
                    <RightOutlined />
                  </Button> */}
                </div>
              </Fragment>
            ) : (
              <Typography.Text>
                <Spin indicator={<LoadingOutlined spin />} /> Loading patient
                details...
              </Typography.Text>
            )}
          </div>
          <div className="patient-detail-body">
            <Tabs defaultActiveKey="1" centered className="patient-detail-tab">
              <TabPane
                tab="Patient Data Log"
                key="1"
                className="patient-detail-tab-container"
              >
                {loadingDataPoints ? (
                  <LoadingPage type="list" />
                ) : (
                  dataPoints && (
                    <PatientDataLog
                      patientDataLog={dataPoints}
                      selectedPatient={selectedPatient}
                      dataLogsDate={dataLogsDate}
                      setDataLogsDate={setDataLogsDate}
                      getDataPoints={(data) => fetchPatientDataPoints(data)}
                    />
                  )
                )}
              </TabPane>
              <TabPane
                tab="Clinical Monitoring Log"
                key="2"
                className="patient-detail-tab-container"
              >
                {loadingClinigLogs ? (
                  <LoadingPage type="list" />
                ) : (
                  clinicLogs && (
                    <ClinicalMonitoringLog
                      handleDeleteCLinicMonitorLogs={
                        handleDeleteCLinicMonitorLogs
                      }
                      clinicalMonitoringLog={clinicLogs}
                      saveManualTimer={handleSetManualTimer}
                      filteredDate={clinicLogsDate}
                      changeFilteredDate={(date) => setClinicLogsDate(date)}
                      selectedPatient={selectedPatient}
                      getClinicLogs={() =>
                        fetchClinicMonitorLogs(selectedPatient)
                      }
                      visibleProfile={visibleProfile}
                    />
                  )
                )}
              </TabPane>
              <TabPane
                tab="Billing"
                key="3"
                className="patient-detail-tab-container"
              >
                {loadingBilling ? (
                  <LoadingPage type="list" />
                ) : (
                  rtmBilling && (
                    <BillingLog
                      rtmBillingData={rtmBilling}
                      rtmPreviousBillingData={props.rtmPrevBilling}
                      billingDocument={billingDocument}
                      getBillingDocument={(
                        id,
                        code,
                        status,
                        start,
                        end,
                        hasPending
                      ) =>
                        fetchRTMDocument(
                          id,
                          code,
                          status,
                          selectedPatient,
                          start,
                          end,
                          hasPending
                        )
                      }
                      refreshBillingList={handleRefreshBillingList}
                      patient={selectedPatient}
                    />
                  )
                )}
              </TabPane>
            </Tabs>
          </div>
        </div>
      </Drawer>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.patients.isLoading,
    loadingDataPoints: state.patients.loadingDataPoints,
    dataPoints: state.patients.dataPoints,
    monitorTimer: state.monitorTimer,
    loadingClinigLogs: state.patients.loadingClinigLogs,
    clinicLogs: state.patients.clinicLogs,
    loadingBilling: state.patients.loadingBilling,
    rtmBilling: state.patients.rtmBilling,
    currentMonitoredPatient: state.monitorTimer.currentMonitoredPatient,
    isMonitoring: state.monitorTimer.isMonitoring,
    visibleProfile: state.visibleProfile
  };
};

export default connect(mapStateToProps)(PatientDetailDrawer);

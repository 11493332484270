import React from 'react';
import { Empty, Skeleton } from 'antd';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { colors } from '../../pages/RTMReports/data';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const StackedBarChart = ({ loading = false, chartTitle, data }) => {
  if (loading) {
    return <Skeleton active />;
  }

  if (!data) {
    return <Empty description="No data to display on chart." />;
  }

  const labels = data.labels || [];
  const datasets =
    data.datasets && Array.isArray(data.datasets)
      ? data.datasets.map((dataset, i) => {
          const color = colors[i];

          return {
            ...dataset,
            borderColor: color.border,
            backgroundColor: color.background,
            borderWidth: 1,
          };
        })
      : [];

  const height = data.labels.length * 30 + 100;

  return (
    <div
      style={{
        position: 'relative',
        height: height,
        width: '100%',
      }}
    >
      <Bar
        id="stacked-chart"
        options={{
          backgroundColor: '#fff',
          responsive: true,
          aspectRatio: 1,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: chartTitle,
            },
            legend: {
              onClick: null,
            },
          },
          indexAxis: 'y',
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
        }}
        data={{ labels, datasets }}
      />
    </div>
  );
};

export default StackedBarChart;

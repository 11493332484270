import './styles/bootstrap.min.css';
import './styles/ie10-viewport-bug-workaround.css';
import './styles/font-awesome.min.css';
import './styles/custom-PT-Wired-min.css';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
import App from './components/App';
import { createHashHistory as createHistory } from 'history';
import { ConnectedRouter, routerMiddleware } from 'react-router-redux';
import promiseMiddleware from 'redux-promise-middleware';
import jwtMiddleware from './features/membership/middleware';
import { REQUEST_DEAUTHENTICATION } from './features/membership/actions';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/es/integration/react';
import { thunk } from 'redux-middleware';
import version from './components/version';
import 'antd/dist/antd.css';
import { RecoilRoot } from 'recoil';
import RecoilNexus, { getRecoil } from 'recoil-nexus';

import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache
} from '@apollo/client';

import Auth from '@aws-amplify/auth';
import usePendo from './hooks/usePendo';
import states from './states';
import appConfig from './config';

const [mount] = usePendo(); // eslint-disable-line react-hooks/rules-of-hooks
mount();

const config = {
  region: 'us-east-1',
  userPoolId: 'us-east-1_0aoTkP3bt',
  userPoolWebClientId: '4hdal6uapcnurin3fftno98akl',
  mandatorySignIn: true
};
Auth.configure(config);

const getGraphQLEndpoint = () => {
  let id = '';
  if (appConfig.appSync === 'red') {
    id = '4durrozkqnhhzg2rb4gzgtmxva';
  } else if (appConfig.appSync === 'green') {
    id = 'bklrhmw65bbpdkhjoolpdeqcam';
  } else {
    id = 'pedimqjko5adnef6gmmyonlehi';
  }

  return `https://${id}.appsync-api.us-east-1.amazonaws.com/graphql`;
};

const getAuthToken = async () => {
  try {
    const currentSession = await Auth.currentSession();
    const idToken = currentSession.getIdToken();
    const jwtToken = idToken.getJwtToken();

    return jwtToken;
  } catch (error) {
    if (error === 'No current user') {
      const auth = getRecoil(states.authentication);
      const ssoToken = auth.token;
      if (ssoToken) {
        return ssoToken;
      }
    }
  }
};

const apolloConfig = {
  url: getGraphQLEndpoint(),
  region: config.region,
  auth: {
    type: 'AMAZON_COGNITO_USER_POOLS',
    jwtToken: async () => await getAuthToken()
  }
};

const apolloHttpLink = new HttpLink({ uri: apolloConfig.url });

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    createAuthLink(apolloConfig),
    createSubscriptionHandshakeLink(apolloConfig, apolloHttpLink)
  ]),
  cache: new InMemoryCache()
});

const history = createHistory();

const store = createStore(
  //root reducer wrapper used to logoff and clear state
  (state, action) => {
    let refreshState = false;

    if (state && state.publicPage) {
      refreshState = state.publicPage.version !== version;
    }

    if (action.type === REQUEST_DEAUTHENTICATION || refreshState) {
      if (refreshState) {
        console.log('New version detected. Refreshing Store.');
      }

      const { routerReducer, publicPage } = state;

      publicPage.version = version;

      let newState;

      setTimeout((x) => {
        localStorage.clear();
      }, 100);
      newState = {
        routerReducer,
        publicPage,
        currentUser: {
          hasLoggedOut: true
        }
      };
      return rootReducer(newState, action);
    } else {
      return rootReducer(state, action);
    }
  },
  compose(
    applyMiddleware(
      thunk,
      jwtMiddleware,
      routerMiddleware(history),
      promiseMiddleware()
    ),
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
      process.env.NODE_ENV === 'development'
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
);

const persistor = persistStore(store);
const startApp = () =>
  render(
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <PersistGate
          persistor={persistor}
          loading={<div className="loader">Loading...</div>}
        >
          <ConnectedRouter history={history}>
            <RecoilRoot>
              <RecoilNexus />
              <App />
            </RecoilRoot>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </ApolloProvider>,
    document.getElementById('root')
  );

startApp();

window.onerror = function (msg, url, line, col, error) {
  localStorage.clear();

  console.log(
    'Potential application state corruption.\nClearing persisted state from local storage.'
  );
  console.log(msg, url, line, col, error);

  var suppressErrorAlert = true;
  // If you return true, then error alerts (like in older versions of
  // Internet Explorer) will be suppressed.
  return suppressErrorAlert;
};

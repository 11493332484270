import React, { useState } from 'react';
import {
  Drawer,
  Collapse,
  Button,
  Row,
  Popconfirm,
  notification,
  Typography,
  Space,
} from 'antd';
import { UndoOutlined } from '@ant-design/icons';
import { useRecoilState, useRecoilValue } from 'recoil';

import moment from 'moment';
import v from 'voca';

import { toCamelCaseObjKeys } from '../../../utils/object.utils';

import PrescriptionDetails from '../components/PrescriptionDetails';
import states from '../../../states';
import services from '../../../services';

const PrescriptionHistoryDrawer = ({ currentRx, isOpen, handleClose }) => {
  const [loading, setLoading] = useState(false);

  const [rx, setRx] = useRecoilState(states.prescription);
  const { history, list } = rx;

  const group = useRecoilValue(states.groups);

  const handleRestorePrescription = async rxItem => {
    try {
      setLoading(true);

      const rxForm = {
        active: true,
        created: rxItem.Created,
        groupId: rxItem.GroupId,
        id: currentRx.id,
        name: rxItem.Name,
        prescription: rxItem.Data,
        sub: rxItem.Sub,
        updated: currentRx.updated,
      };
      const isNewPatient = false;

      setRx(prevRx => ({
        ...prevRx,
        action: 'EDIT',
        form: rxForm,
      }));

      const response = await services.prescription.update(
        isNewPatient,
        rxItem.Sub
      );

      if (response.status !== 200) {
        throw new Error(JSON.stringify(response));
      }

      const updatedList = [...list];
      const updatedItem = {
        ...toCamelCaseObjKeys(response.data),
      };

      if (list.length > 1) {
        if (list.some(rx => rx.id === updatedItem.id)) {
          const index = list.findIndex(rx => rx.id === updatedItem.id);

          updatedList[index] = updatedItem;
        } else {
          const index = list.findIndex(rx => !rx.id);

          updatedList.splice(index, 1);
          updatedList.unshift(updatedItem);
        }
      } else {
        updatedList[0] = updatedItem;
      }

      setRx(prevRx => ({
        ...prevRx,
        list: updatedList,
        form: updatedItem,
        rxDrawer: prevRx.rxDrawer ? updatedItem : null,
        action: '',
      }));

      notification.success({
        message: 'Success',
        description: 'Prescription restored successfully!',
        duration: 6,
      });
      handleClose();
    } catch (error) {
      console.error('Error restoring prescription:', error);
      notification.error({
        message: 'Error!',
        description: 'An error occurred while restoring prescription.',
      });
    } finally {
      setLoading(false);
    }
  };

  const renderCreator = sub => {
    const item = group.providers.find(admin => admin.Sub === sub);

    let creator = '';
    if (item) {
      const fname = v.capitalize(item.FirstName);
      const lname = v.capitalize(item.LastName);

      creator = `${fname} ${lname}`;
    }

    return creator;
  };

  // sort history by date
  return (
    <Drawer
      destroyOnClose
      title="Prescription History"
      placement="right"
      visible={isOpen}
      width="100%"
      bodyStyle={{ padding: 0 }}
      onClose={handleClose}
    >
      {!!history.length && (
        <Collapse accordion>
          {history.map((item, index) => {
            const prescriptionItem = {
              ...item,
              name: item.Name,
              prescription: item.Data,
            };

            return (
              <Collapse.Panel
                header={`${renderCreator(item.Provider)} - ${moment(
                  item.Updated
                ).format('MMMM DD, YYYY - hh:mm A')}`}
                key={index}
              >
                <Row align="top" justify="space-between" className="mb-2">
                  <div />

                  <Popconfirm
                    placement="left"
                    title="Are you sure you want to restore this prescription?
                    This will replace the current prescription with the selected one."
                    okText="Yes"
                    onConfirm={() => handleRestorePrescription(item)}
                  >
                    <Button
                      type="primary"
                      size="large"
                      className="ptw-btn btn-primary"
                      icon={<UndoOutlined />}
                      loading={loading}
                    >
                      Restore Prescription
                    </Button>
                  </Popconfirm>
                </Row>

                <PrescriptionDetails
                  isHistory
                  prescriptionItem={prescriptionItem}
                />
              </Collapse.Panel>
            );
          })}
        </Collapse>
      )}
    </Drawer>
  );
};

export default PrescriptionHistoryDrawer;

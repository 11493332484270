import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router';
// import { Amplify } from 'aws-amplify';
// import { AmazonAIPredictionsProvider } from '@aws-amplify/predictions';
import Header from './header/Header';
import ExercisesProgress from './prescription/ExercisesProgress';
import AchievementList from './prescription/AchievementList';
import ReRoute from './ReRoute';
import MobileHome from './MobileHome';
import './styles.css';
import BottomNav from './BottomNav';
import NewsFeed from './NewsFeed';
import { withRouter } from 'react-router';
import { requestProfile } from '../membership/actions';
import DischargePage from './DischargePage';
import {
  getCreatorPreferences,
  sendLoginEvent,
  setCurrentRx,
  setLocale,
  setRxList,
} from './actions/patient';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import Comment from './Comment';
import Messaging from './Messaging';
import RequestAppointment from './RequestAppointment';
import DispatchLogoutItem from '../../../src/components/DispatchLogoutItem';
import RxList from './prescription/RxList';
import TextTranslation from '../../I18n/TranslateText';
// import awsconfig from '../../aws-exports';

// Amplify.configure(awsconfig);
// Amplify.addPluggable(new AmazonAIPredictionsProvider());

class MobileApp extends Component {
  componentDidMount() {
    const { dispatch, visibleProfile, publicPage } = this.props;

    let currLocale = localStorage.getItem('locale');

    if (currLocale) {
      dispatch(setLocale(currLocale));
    }

    if (
      visibleProfile &&
      visibleProfile.Prescriptions &&
      Array.isArray(visibleProfile.Prescriptions)
    ) {
      if (visibleProfile.Prescriptions.length === 1) {
        const rxItem = visibleProfile.Prescriptions[0];
        dispatch(setCurrentRx(this.formatRxItem(rxItem)));
        dispatch(setRxList([this.formatRxItem(rxItem)]));
      } else {
        dispatch(setRxList([...visibleProfile.Prescriptions]));
      }
    }

    ReactGA.event({
      category: 'Mobile',
      action: 'Patient Session',
      label: publicPage.name,
    });

    if (visibleProfile.Creator) {
      dispatch(
        getCreatorPreferences(visibleProfile.Creator, visibleProfile.GroupId)
      );
    }

    setTimeout(() => {
      dispatch(
        requestProfile(visibleProfile.EmailAddress.toLowerCase()),
        visibleProfile.Role
      );
    }, 1000 * 60 * 5);

    this.loggedEvent(visibleProfile);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(setCurrentRx(null));
  }

  formatRxItem = rxItem => {
    return !rxItem.hasOwnProperty('Prescription')
      ? { Prescription: rxItem }
      : rxItem;
  };

  loggedEvent = async profile => {
    await this.props.dispatch(
      sendLoginEvent(
        profile.GroupInfo.Name,
        profile.Sub,
        profile.GroupId,
        profile.EmailAddress
      )
    );
  };

  render() {
    const {
      visibleProfile: {
        Discharge,
        CancelDate,
        GroupInfo: { Active },
      },
      locale,
    } = this.props;

    if (Discharge) {
      if (Date.now() > CancelDate) {
        return <DischargePage />;
      }
    }

    if (!JSON.parse(Active)) {
      return (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <h1>
            <TextTranslation
              currLocale={locale}
              currText={'Forbidden Access'}
            />
          </h1>
          <h3>
            <TextTranslation
              currLocale={locale}
              currText={'The group you belong is now inactive!'}
            />
          </h3>
          <br />
          <button>
            <DispatchLogoutItem />
          </button>
        </div>
      );
    }

    return (
      <div className="ptw-main-scroll-container">
        <div className="ptw-main-body">
          <Header />
          <Switch>
            <Route path="/exercises" component={RxList} />
            <Route path="/requestAppointment" component={RequestAppointment} />
            <Route path="/more" component={MobileHome} />
            <Route path="/messaging" component={Messaging} />
            <Route path="/progress" component={ExercisesProgress} />
            <Route path="/achievements" component={AchievementList} />
            <Route path="/blog" component={NewsFeed} />
            <Route path="/comment" component={Comment} />
            <Redirect from="/*" to="/exercises" />
            <Route path="/*" component={ReRoute} />
          </Switch>
          <BottomNav />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
    visibleProfile: state.visibleProfile,
    publicPage: state.publicPage,
    socket: state.socket,
    currentRx: state.mobile.currentRx,
    locale: state.mobile.locale,
    Messaging: state.mobile.Messaging,
  };
};

export default connect(mapStateToProps)(withRouter(MobileApp));

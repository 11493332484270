/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getRecoil } from 'recoil-nexus';
import { Row, Col, Drawer } from 'antd';
import _, { debounce } from 'lodash';

import {
  calculateHalfChecked,
  filterByOnlyClinicExercises,
  filterExercises,
  mapTagsToFilters,
  exercisesListWithSelectedExercises,
  filterByOnlyMyExercises,
} from '../../utils/exercises.utils';
import { toCamelCaseObjKeys } from '../../utils/object.utils';

import services from '../../services';
import states from '../../states';
import setNotification from '../../utils/setNotification.utils';

import ExerciseFilter from './ExerciseFilter';
import ExerciseAction from './ExerciseAction';
import ExerciseFilterTags from './ExerciseFilterTags';
import ExerciseListDisplay from './ExerciseListDisplay';
import ExerciseDetailsModal from '../ExerciseDetailsModal';
import AddTemplateModal from '../../pages/EditPrescription/PrescriptionExercises/AddTemplateModal';
import SelectionPanel from '../SelectionPanel';
import ExerciseFormModal from '../ExerciseFormModal';

const ExerciseList = ({
  visibleProfile,
  isEditing,
  selectedExercises,
  handleSelectExercise,
  handleAddTemplateModal,
  handleRemoveExerciseItem,
  handleRemoveAllExerciseItem,
  onDragEnd,
  addTemplateToSelectionPanel,
  fromTemplates = false,
}) => {
  const [filters, setFilters] = useRecoilState(states.filters);
  const [exercises, setExercises] = useRecoilState(states.exercises);
  const [user, setUser] = useRecoilState(states.user);

  const [currentView, setCurrentView] = useState('card');
  const [loading, setLoading] = useState(false);
  const [displayFilter, setDisplayFilter] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [favoritesSelected, setFavoritesSelected] = useState(false);
  const [clinicExercisesSelected, setClinicExercisesSelected] = useState(false);
  const [myExercisesSelected, setMyExercisesSelected] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState({
    checked: [],
    halfChecked: [],
  });
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [tags, setTags] = useState([]);
  const [exerciseFormModal, setExerciseFormModal] = useState(false);
  const [displaySelectionPanel, setDisplaySelectionPanel] = useState(false);

  const setModals = useSetRecoilState(states.modal);
  const favorites = useRecoilValue(states.favorites);

  let abortController = new AbortController();
  let exercisesList = [];

  useEffect(() => {
    setLoading(true);
    fetchCustomExercises();
    setDisplayFilter(filters?.ShowExerciseTray ?? true);

    if (!visibleProfile.FavoriteId) {
      assignFavoriteId();
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  useEffect(() => {
    debouncedSearch(searchTerm, tags);
  }, [searchTerm, tags]);

  const handleDisplayFilter = async displayFilter => {
    setDisplayFilter(displayFilter);

    const { Sub, GroupId } = visibleProfile;
    const payload = {
      ...filters,
      ShowExerciseTray: displayFilter,
    };

    if (!filters) {
      payload.Sub = Sub;
      payload.GroupId = GroupId;
    }

    await services.filters.addFilters(payload).then(res => {
      if (res.status === 200) {
        setFilters(prevState => ({
          ...prevState,
          ...res.data,
        }));
      }
    });
  };

  const handleSelectionPanel = () => {
    setDisplaySelectionPanel(prevState => !prevState);
  };

  const assignFavoriteId = async () => {
    try {
      const { Sub } = visibleProfile;
      const response = await services.exercises.addFavoriteId(Sub);

      if (response.status === 200) {
        setUser({
          ...user,
          details: {
            emailAddress: visibleProfile.EmailAddress,
            sub: Sub,
            role: visibleProfile.Role,
            firstName: visibleProfile.FirstName,
            lastName: visibleProfile.LastName,
            active: visibleProfile.Active,
            clinic: visibleProfile.Clinic,
            billingRole: visibleProfile.BillingRole,
            preferences: {
              ...toCamelCaseObjKeys(visibleProfile.Preferences),
            },
            favoriteId: response.data.id,
          },
        });
      }
    } catch (error) {
      setNotification(
        'error',
        'Error!',
        'An error occurred while assigning favorite ID.'
      );
    }
  };

  const fetchExerciseList = async (searchVal, inputTags = tags) => {
    try {
      setLoading(true);

      const term = searchVal || null;
      const filter = mapTagsToFilters(inputTags);

      abortController.abort();
      abortController = new AbortController();

      const abortOptions = {
        abortSignal: abortController.signal,
      };

      const { GroupId } = visibleProfile;
      const response = await services.exercises.searchExercises(
        GroupId,
        term,
        filter,
        abortOptions
      );

      if (!response || !response.data) {
        setLoading(true);
        return;
      }

      if (response && response.status === 200) {
        const responseData = [...response.data.exercises];

        if (term && !responseData.length) {
          const exercisesState = getRecoil(states.exercises);
          const customParams = exercisesState.custom.parameter.filter(param =>
            param.ExerciseName.trim()
              .toLowerCase()
              .includes(term?.trim().toLowerCase())
          );

          if (customParams.length) {
            const fromCustomParams = await Promise.all(
              customParams.map(param =>
                services.exercises.getExerciseDetails(param.ExerciseId)
              )
            ).then(res => res.map(r => r.data));

            for (let i = 0; i < fromCustomParams.length; i++) {
              if (!fromCustomParams[i]) {
                continue;
              }

              responseData.push(fromCustomParams[i]);
            }
          }
        }

        setExercises(prevState => {
          const fromCustomEx = [...prevState.custom.exercises].filter(item => {
            if (!term && !filter.length) {
              return true;
            }

            const search = term?.toLowerCase();
            const onSearch = item?.ExerciseName?.toLowerCase().includes(search);

            const tags = item?.Tag?.split(',').map(t => t?.trim());
            const filters = filter.map(f => f?.toLowerCase());
            const onTag = tags?.some(t => filters.includes(t?.toLowerCase()));

            return onSearch || onTag;
          });

          const result = [
            ...fromCustomEx.map(ex => toCamelCaseObjKeys(ex)),
            ...responseData.map(ex => toCamelCaseObjKeys(ex)),
          ].map(item => {
            const exercise = {
              ...item,
            };

            delete exercise.cPTCode;
            return exercise;
          });

          return {
            ...prevState,
            unmapped: result,
            list: result.map(item => {
              const parameters = [...prevState.custom.parameter];
              const params = parameters.find(p => p.ExerciseId === item.id);

              if (!params) {
                return item;
              }

              return {
                ...item,
                exerciseName: params.ExerciseName,
                instructions: params.Instructions || '',
                sets: params.Sets || '',
                reps: params.Reps || '',
                hold: params.Hold || '',
              };
            }),
          };
        });
      }

      setLoading(false);
    } catch (err) {
      console.log(err);

      setLoading(false);
      setNotification(
        'error',
        'Error!',
        'An error occurred while fetching exercises list.'
      );
    }
  };

  const fetchCustomExercises = async () => {
    try {
      const { Sub } = visibleProfile;
      await Promise.all([
        services.exercises.getCustomExercises(Sub),
        services.exercises.getCustomParameter(Sub),
      ]).then(response => {
        if (response.every(res => res.status === 200)) {
          const [exercises, parameter] = response;

          setExercises(prevState => ({
            ...prevState,
            custom: {
              exercises: exercises.data,
              parameter: parameter.data,
            },
          }));
        }
      });
    } catch (error) {
      setNotification(
        'error',
        'Error!',
        'An error occurred while fetching custom exercises and parameters.'
      );
    }
  };

  const handleSearch = value => {
    setSearchTerm(value);
  };

  const debouncedSearch = useRef(
    debounce(async (value, tags) => {
      await fetchExerciseList(value, tags);
    }, 500)
  ).current;

  const handleExerciseDetailsModal = exerciseDetails => {
    setModals(prevState => ({
      ...prevState,
      exerciseDetails: !prevState.exerciseDetails,
    }));

    setExercises(prevState => ({
      ...prevState,
      details: exerciseDetails,
    }));
  };

  const removeTag = tag => {
    const keysCopy = checkedKeys.checked.slice();

    _.remove(tags, item => {
      return item.key === tag.key;
    });

    _.pull(keysCopy, tag.key);

    const halfChecked = calculateHalfChecked(keysCopy);

    setTags(() => {
      fetchExerciseList(searchTerm, tags);
      return tags;
    });
    setCheckedKeys({ checked: keysCopy, halfChecked: halfChecked });
  };

  const removeAllTags = () => {
    setTags(() => {
      fetchExerciseList(searchTerm, []);
      return [];
    });
    setCheckedKeys({ checked: [], halfChecked: [] });
  };

  exercisesList = filterExercises(
    exercises.list,
    searchTerm,
    favorites.list,
    favoritesSelected,
    tags
  );

  exercisesList = exercisesListWithSelectedExercises(
    exercisesList,
    visibleProfile.GroupId,
    selectedExercises,
    exercises.custom.exercises
  );

  exercisesList = filterByOnlyClinicExercises(
    clinicExercisesSelected,
    exercisesList
  );

  exercisesList = filterByOnlyMyExercises(
    myExercisesSelected,
    exercisesList,
    exercises.custom.exercises
  );

  return (
    <React.Fragment>
      <ExerciseFormModal
        isOpen={exerciseFormModal}
        onCancel={() => setExerciseFormModal(false)}
        selectedExercises={selectedExercises}
        fromTemplates={fromTemplates}
      />
      <AddTemplateModal
        addTemplateToSelectionPanel={addTemplateToSelectionPanel}
      />
      <ExerciseDetailsModal
        handleExerciseDetailsModal={handleExerciseDetailsModal}
      />
      <SelectionPanelDrawer
        isOpen={displaySelectionPanel}
        handleClose={handleSelectionPanel}
        selectedExercises={selectedExercises}
        handleRemoveExerciseItem={handleRemoveExerciseItem}
        handleOpenExerciseModal={() => setExerciseFormModal(true)}
        handleAddTemplateModal={handleAddTemplateModal}
        handleRemoveAllExerciseItem={handleRemoveAllExerciseItem}
        onDragEnd={onDragEnd}
      />
      <Row gutter={[24, 0]} className="mb-4">
        {displayFilter && (
          <Col xl={4} lg={5} md={7} sm={24} xs={24}>
            {/* filter drawer */}
            <ExerciseFilter
              type="dt"
              setFavoritesSelected={setFavoritesSelected}
              setClinicExercisesSelected={setClinicExercisesSelected}
              setMyExercisesSelected={setMyExercisesSelected}
              checkedKeys={checkedKeys}
              setCheckedKeys={setCheckedKeys}
              expandedKeys={expandedKeys}
              setExpandedKeys={setExpandedKeys}
              tags={tags}
              setTags={setTags}
              removeTag={removeTag}
            />
          </Col>
        )}
        <Col
          xl={displayFilter ? 20 : 24}
          lg={displayFilter ? 19 : 24}
          md={displayFilter ? 17 : 24}
          sm={24}
          xs={24}
        >
          <Row gutter={[16, 16]}>
            <Col
              lg={isEditing ? 17 : 24}
              md={isEditing ? 13 : 24}
              sm={24}
              xs={24}
            >
              {/* search field, filter and view toggles */}
              <ExerciseAction
                isEditing={isEditing}
                handleSearch={handleSearch}
                searchTerm={searchTerm}
                displayFilter={displayFilter}
                handleDisplayFilter={handleDisplayFilter}
                currentView={currentView}
                setCurrentView={setCurrentView}
                handleSelectionPanel={handleSelectionPanel}
              />
              {/* filter tags */}
              <ExerciseFilterTags
                tags={tags}
                removeTag={removeTag}
                removeAllTags={removeAllTags}
              />
              {displayFilter && (
                <ExerciseFilter
                  type="mb"
                  setFavoritesSelected={setFavoritesSelected}
                  setClinicExercisesSelected={setClinicExercisesSelected}
                  setMyExercisesSelected={setMyExercisesSelected}
                  checkedKeys={checkedKeys}
                  setCheckedKeys={setCheckedKeys}
                  expandedKeys={expandedKeys}
                  setExpandedKeys={setExpandedKeys}
                  tags={tags}
                  setTags={setTags}
                  removeTag={removeTag}
                />
              )}
              {/* exercises list display */}
              <ExerciseListDisplay
                loading={loading}
                currentView={currentView}
                favorites={favorites}
                favoritesSelected={favoritesSelected}
                exercisesList={exercisesList}
                handleSelectExercise={handleSelectExercise}
                handleExerciseDetailsModal={handleExerciseDetailsModal}
              />
            </Col>
            {isEditing && (
              <Col lg={7} md={11} sm={0} xs={0} className="hide-sm-tablet">
                {/* prescription exercises list */}
                <SelectionPanel
                  selectedExercises={selectedExercises}
                  handleOpenExerciseModal={() => setExerciseFormModal(true)}
                  handleAddTemplateModal={handleAddTemplateModal}
                  handleRemoveAllExerciseItem={handleRemoveAllExerciseItem}
                  handleRemoveExerciseItem={handleRemoveExerciseItem}
                  onDragEnd={onDragEnd}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const SelectionPanelDrawer = ({
  isOpen,
  handleClose,
  selectedExercises,
  handleOpenExerciseModal,
  handleAddTemplateModal,
  handleRemoveAllExerciseItem,
  handleRemoveExerciseItem,
  onDragEnd,
}) => {
  return (
    <Drawer
      destroyOnClose
      placement="right"
      visible={isOpen}
      bodyStyle={{
        padding: 0,
      }}
      width="100%"
      className="hide-dt show-lg-tablet"
      onClose={handleClose}
    >
      <SelectionPanel
        selectedExercises={selectedExercises}
        handleOpenExerciseModal={handleOpenExerciseModal}
        handleAddTemplateModal={handleAddTemplateModal}
        handleRemoveAllExerciseItem={handleRemoveAllExerciseItem}
        handleRemoveExerciseItem={handleRemoveExerciseItem}
        onDragEnd={onDragEnd}
      />
    </Drawer>
  );
};

const mapStateToProps = state => ({
  visibleProfile: state.visibleProfile,
  formType: state.groupExercises.formType,
});

export default connect(mapStateToProps)(ExerciseList);

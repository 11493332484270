import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Form, Select } from 'antd';
import { Field, reduxForm } from 'redux-form';
import MaskedInput from 'react-maskedinput';
import FormInput from '../Input';
import USStates from '../USStates';
import { required } from '../validators/generic';
import {
  clinicNameValidator,
  addressValidator,
  cityValidator,
  zipValidator,
  phoneValidator,
} from '../validators/clinics';
import { zipCodeNormalizer } from '../inputNormalizers';

const ClinicForm = ({ handleSubmit }) => (
  <Form onFinish={handleSubmit}>
    <Row>
      <Col md={4}>
        <Field
          name="ClinicName"
          label="Clinic Name"
          component={FormInput}
          type="text"
          validate={[required, clinicNameValidator]}
          isRequired
        />
      </Col>
      <Col md={4}>
        <Field
          name="ClinicPhoneNumber"
          label="Clinic Phone"
          component={FormInput}
          componentClass={MaskedInput}
          validate={[required, phoneValidator]}
          mask="111-111-1111"
          placeholder="123-456-7890"
          isRequired
        />
      </Col>
      <Col md={4}>
        <Field
          name="ClinicEmail"
          label="Clinic Email"
          component={FormInput}
          placeholder="example@email.com"
          isRequired
        />
      </Col>
    </Row>

    <Row>
      <Col md={3}>
        <Field
          name="Address"
          label="Address"
          component={FormInput}
          type="text"
          validate={[required, addressValidator]}
          isRequired
        />
      </Col>
      <Col md={3}>
        <Field
          name="City"
          label="City"
          component={FormInput}
          type="text"
          validate={[required, cityValidator]}
          isRequired
        />
      </Col>
      <Col md={3}>
        <Field
          name="State"
          label="State"
          component={FormInput}
          type="select"
          isRequired
        >
          {Object.keys(USStates).map((abbrev) => (
            <Select.Option value={abbrev} key={abbrev}>
              {USStates[abbrev]}
            </Select.Option >
          ))}
        </Field>
      </Col>
      <Col md={3}>
        <Field
          name="Zip"
          label="Zip"
          component={FormInput}
          type="text"
          validate={[required, zipValidator]}
          normalize={zipCodeNormalizer}
          isRequired
        />
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <Field
          name="FacebookUrl"
          label="Facebook Url"
          component={FormInput}
          componentClass={'input'}
        />
      </Col>
      <Col md={6}>
        <Field
          name="GoogleUrl"
          label="GoogleUrl Url"
          component={FormInput}
          componentClass={'input'}
        />
      </Col>
    </Row>
  </Form>
);

export default reduxForm({
  form: 'clinic',
  asyncBlurFields: ['clinicName'],
  enableReinitialize: true
})(ClinicForm);

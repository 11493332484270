import React, { Component } from 'react';
import { connect } from 'react-redux';
import { success } from 'react-notification-system-redux';
import { codeValidation, updatePasswordByCode } from './actions';
import {
  Grid,
  Row,
  Col,
  FormGroup,
  Button,
  ControlLabel
} from 'react-bootstrap';
import { Form, Input, Typography } from 'antd';

import R from 'ramda';
import Loading from '../../components/Loading';
import _ from 'lodash';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';

//https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript
function formatPhoneNumber(phoneNumberString) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (!match) {
    return null;
  }

  const intlCode = match[1] ? '+1 ' : '';
  return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
}
class TextInput extends Component {
  state = {
    touched: false
  };

  render() {
    const { onChange, name, label, placeholder, error, ...rest } = this.props;
    const { touched } = this.state;

    return (
      <Form.Item
        label={label}
        validateStatus={touched && error ? 'error' : null}
      >
        <Input.Password
          type="password"
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          onBlur={() => this.setState({ touched: true })}
          size="large"
          {...rest}
        />
        {touched && (
          <Typography.Paragraph type="danger">
            {Array.isArray(error) ? error[0] : error}
          </Typography.Paragraph>
        )}
        {touched && (
          <Typography.Paragraph type="danger">
            {Array.isArray(error) ? error[1] : undefined}
          </Typography.Paragraph>
        )}
        {touched && (
          <Typography.Paragraph type="danger">
            {Array.isArray(error) ? error[2] : undefined}
          </Typography.Paragraph>
        )}
        {touched && (
          <Typography.Paragraph type="danger">
            {Array.isArray(error) ? error[3] : undefined}
          </Typography.Paragraph>
        )}
      </Form.Item>
    );
  }
}

class ValidateAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validationCode: R.path(['match', 'params', 'code'], props),
      formValues: {
        password: '',
        passwordConfirmation: ''
      },
      formErrors: {},
      codeConfirmed: false,
      isLoading: false,
      isSubmitting: false,
      emailAddress: '',
      phoneNumber: ''
    };
  }

  validators = {
    password(value, allValues) {
      let errorMessage = [];

      if (!/[a-z]/.test(value)) errorMessage.push('Missing lowercase');
      if (!/[A-Z]/.test(value)) errorMessage.push('Missing uppercase');
      if (!/[0-9]/.test(value)) errorMessage.push('Missing digit ');
      if (value.length < 8) errorMessage.push('Password too short');
      if (errorMessage.length > 0) return errorMessage;

      return undefined;
    },
    passwordConfirmation(value, allValues) {
      return value === allValues.password
        ? undefined
        : ['Password does not match'];
    }
  };

  validateValues = (formValues) =>
    _.fromPairs(
      _.toPairs(formValues)
        .map(([k, v]) => [k, this.validators[k](v, formValues)])
        .filter(([k, v]) => v !== undefined)
    );

  componentDidMount() {
    const { dispatch } = this.props;

    // initialize errors
    this.setState((prevState, props) => ({
      formErrors: this.validateValues(prevState.formValues),
      isLoading: true
    }));

    dispatch(codeValidation(this.state.validationCode))
      .then(({ value }) => {
        this.setState({
          hasError: !value.valid,
          codeConfirmed: value.valid,
          emailAddress: value.EmailAddress || value.emailAddress,
          phoneNumber: value.PhoneNumber || value.phoneNumber,
          isLoading: false
        });
      })
      .catch((err) => {
        this.setState({ codeConfirmed: false, isLoading: false });
      });
  }

  submitPasswordUpdate = () => {
    const { history, dispatch } = this.props;
    const {
      formValues: { password },
      validationCode
    } = this.state;

    this.setState({
      isLoading: true
    });

    ReactGA.event({
      category: 'Patient',
      action: 'Activated an Account',
      label: `Group Url: ${window.location.origin}`
    });

    dispatch(updatePasswordByCode(password, validationCode)).then(
      (response) => {
        if (response.value.role === 'p') {
          history.push('/?from=validate');
          dispatch(
            success({
              title:
                'Congratulations! Your account is now active. Please login to continue.',
              position: 'tr'
            })
          );
        } else {
          history.push('/?role=pt');
          dispatch(
            success({
              title: 'Account Activated!',
              message: 'Your account is now ready to use. Log in Now!',
              position: 'tr'
            })
          );
        }

        this.setState({
          isLoading: false
        });
      },
      (err) =>
        this.setState({
          errorMessage: err,
          isLoading: false
        })
    );
  };

  handleChange = ({ target }) =>
    this.setState((prevState) => {
      const updatedValues = {
        ...prevState.formValues,
        [target.name]: target.value
      };

      return {
        formValues: updatedValues,
        formErrors: this.validateValues(updatedValues)
      };
    });

  render() {
    const {
      formErrors,
      isLoading,
      codeConfirmed,
      errorMessage,
      emailAddress,
      phoneNumber,
      isSubmitting
    } = this.state;
    const { design } = this.props;
    let readyToSubmit = _.isEmpty(formErrors);

    return (
      <div>
        <Grid>
          <Row>
            <Col sm={6} smOffset={3}>
              <div className="panel panel-default ptw-panel-login">
                <div className="panel-body ptw-panel">
                  <div className="ptw-panel-margin">
                    <Row>
                      <Col md={12} className="brand-image login-logo">
                        {design.brandType === 'logo' ? (
                          <img
                            src={design.logoUrl}
                            alt="logo"
                            className="img-responsive center-block"
                          />
                        ) : (
                          <h2 className="text-center">{design.brandText}</h2>
                        )}
                      </Col>
                    </Row>
                    <Loading isLoading={isLoading}>
                      <div>
                        {!codeConfirmed ? (
                          <div style={{ textAlign: 'center' }}>
                            <p
                              className="text-danger"
                              style={{ textAlign: 'center' }}
                            >
                              Oops! Looks like the link you are using has
                              expired.
                            </p>
                            <Button
                              bsStyle="primary"
                              block
                              href="/#/forgot-password"
                            >
                              Request New Link
                            </Button>
                            <br />
                            <Link className="small" to="/">
                              Back to Login
                            </Link>
                          </div>
                        ) : (
                          <div>
                            {errorMessage && (
                              <p className="text-danger">{errorMessage}</p>
                            )}

                            <p>
                              Please set your password to complete your account
                              setup.
                            </p>

                            <Form
                              onFinish={this.submitPasswordUpdate}
                              layout="vertical"
                            >
                              <FormGroup>
                                <ControlLabel>
                                  {emailAddress ? 'Email' : 'Phone Number'}
                                </ControlLabel>

                                <p>
                                  <strong>
                                    {emailAddress ||
                                      formatPhoneNumber(phoneNumber)}
                                  </strong>
                                </p>
                              </FormGroup>

                              <TextInput
                                size="large"
                                name="password"
                                type="password"
                                label="Password"
                                placeholder="Enter your password"
                                onChange={this.handleChange}
                                error={formErrors.password}
                              />

                              <TextInput
                                name="passwordConfirmation"
                                type="password"
                                label="Confirm Password"
                                placeholder="Confirm your password"
                                onChange={this.handleChange}
                                error={formErrors.passwordConfirmation}
                              />

                              <div className="password-requirements">
                                Your password must adhere to the following:
                                <ul>
                                  <li>1 upper case character</li>
                                  <li>1 lower case character</li>
                                  <li>1 number</li>
                                </ul>
                                Password must be at least 8 characters.
                              </div>

                              <FormGroup>
                                <Button
                                  type="submit"
                                  bsStyle="primary"
                                  className="btn-block"
                                  disabled={!readyToSubmit}
                                >
                                  Submit
                                  {isSubmitting && (
                                    <i className="fa fa-spinner fa-pulse fa-fw text-icon" />
                                  )}
                                </Button>
                              </FormGroup>
                            </Form>
                          </div>
                        )}
                      </div>
                    </Loading>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  design: state.publicPage.design
});

export default connect(mapStateToProps)(ValidateAccount);

import React from 'react';
import ReactGA from 'react-ga';
import { List, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import imagePlaceholder from '../../../img/image-placeholder.png';

const PrescriptionExerciseItem = ({ exercise, handleRemoveExerciseItem }) => {
  return (
    <List.Item
      className="ptw-pres-exercise-list-item draggble"
      actions={[
        <Button
          shape="circle"
          type="text"
          size="large"
          icon={<CloseOutlined />}
          onClick={() => handleRemoveExerciseItem(exercise.exerciseName)}
        />
      ]}
    >
      <List.Item.Meta
        avatar={
          <img
            style={{ width: 80, aspectRatio: 16 / 10 }}
            alt="exercise"
            src={
              exercise.imgBase64 ||
              exercise.image ||
              exercise.imageUrl ||
              `https://d2p805pqn3eul9.cloudfront.net/${exercise.groupId}/${exercise.id}.jpg`
            }
            onError={(e) => {
              ReactGA.event({
                category: 'ImageError',
                action: `${exercise.groupId}/${exercise.id}`
              });
              const proxy = `https://s3.amazonaws.com/ptwired-exercise-images-prod/${exercise.groupId}/${exercise.id}.jpg`;
              if (e.target.src === proxy) {
                e.target.src = imagePlaceholder;
              } else {
                e.target.src = proxy;
              }
            }}
          />
        }
        title={exercise.exerciseName}
      />
    </List.Item>
  );
};

export default PrescriptionExerciseItem;

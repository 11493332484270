import apiFetch from '../utils/fetch.utils';
import states from '../states';
import { getRecoil } from 'recoil-nexus';

export const getAnalytics = async (providers, group = null) => {
  try {
    const groupsState = getRecoil(states.groups);
    const groupName = group ? group.name : groupsState.user.name;
    const groupId = group ? group.id : groupsState.user.id;

    const method = 'GET';
    let uri = `groups/${groupName}/rtm?groupId=${groupId}`;

    if (providers && providers.length > 0) {
      uri = uri + `&providers=${encodeURIComponent(JSON.stringify(providers))}`;
    }

    return apiFetch(uri, method);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getAnalyticsBySub = async (sub, group = null) => {
  try {
    const groupsState = getRecoil(states.groups);
    const groupName = group ? group.name : groupsState.user.name;
    const groupId = group ? group.id : groupsState.user.id;

    const method = 'GET';
    const uri = `groups/${groupName}/rtm?groupId=${groupId}&sub=${sub}`;

    return apiFetch(uri, method);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getDatapoints = async (groupId, sub, query) => {
  try {
    const method = 'GET';
    const uri = `groups/${groupId}/rtm/datapoints?sub=${sub}&groupId=${groupId}&query=${encodeURIComponent(
      JSON.stringify(query)
    )}&offset=${new Date().getTimezoneOffset()}`;

    return apiFetch(uri, method);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getBillableCodes = async (groupId, sub, query) => {
  try {
    const method = 'GET';
    const uri = `groups/${groupId}/rtm/billing?sub=${sub}&groupId=${groupId}&query=${encodeURIComponent(
      JSON.stringify(query)
    )}&offset=${new Date().getTimezoneOffset()}`;

    return apiFetch(uri, method);
  } catch (error) {
    throw error;
  }
};

export const runAnalytics = async (sub, groupId) => {
  try {
    const uri = `groups/${groupId}/rtm/analytics?action=RUN_ANALYTICS`;
    const method = 'POST';

    return apiFetch(uri, method, {
      body: {
        Sub: sub,
        GroupId: groupId
      }
    });
  } catch (error) {
    throw error;
  }
};

export const updateBillingPeriod = async (payload) => {
  try {
    const uri = `groups/${payload.groupId}/rtm/analytics?action=UPDATE_PERIOD`;
    const method = 'POST';

    return apiFetch(uri, method, {
      body: payload
    });
  } catch (error) {
    throw error;
  }
};

export const editClinicLogNotes = async (payload) => {
  try {
    const groupsState = getRecoil(states.groups);
    const { name } = groupsState.user;

    const uri = `groups/${name}/rtm/cliniclogs`;
    const method = 'PUT';

    return apiFetch(uri, method, {
      body: payload
    });
  } catch (error) {
    throw error;
  }
};

export const unmarkBilledCode = async (id, sub, groupId) => {
  try {
    const uri = `groups/${groupId}/rtm/billing/documents?status=unmarked&id=${id}&sub=${sub}&groupId=${groupId}`;
    const method = 'GET';

    return apiFetch(uri, method);
  } catch (error) {
    throw error;
  }
};

//todo: combine with add
import React from 'react'
import { Field, reduxForm, Form } from 'redux-form'
import { Row, Col, FormGroup, ControlLabel } from 'react-bootstrap'
import Input from '../../Input'
import { required } from '../../validators/generic'
import {
    firstNameValidator,
    phoneValidator,
    lastNameValidator
} from '../validators/groupAdmins'
import MaskedInput from 'react-maskedinput'

let EditGroupAdminForm = ({ handleSubmit, onChangePassword, initialValues }) => {
    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col xs={6}>                    
                    <Field name="firstName" label="First Name *" component={Input} type="text" validate={[required, firstNameValidator]} />
                </Col>
                <Col xs={6}>
                    <Field name="lastName" label="Last Name *" component={Input} type="text" validate={[required, lastNameValidator]} />
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <Field name="phoneNumber" label="Phone" component={Input} type="text" validate={[required, phoneValidator]} componentClass={MaskedInput} mask="111-111-1111" placeholder="111-111-1111" />
                </Col>
                <Col xs={6}>
                    <Field name="emailAddress" label="Email *" component={Input} type="email" disabled />
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                <FormGroup>
                    <ControlLabel className="required">Active</ControlLabel>
                    <Field name="active" component="input" type="checkbox" style={{ display: 'block' }} />
                </FormGroup>
                </Col>
                <Col md={6}>
                    <a className='pull-right clickable' onClick={e => {e.preventDefault(); onChangePassword(initialValues);}}>Reset Password</a>
                </Col>
            </Row>
        </Form >
    )
}

EditGroupAdminForm = reduxForm({
    // a unique name for the form
    // used by save button to remote submit by
    // dispatching an action    
    form: 'EditGroupAdmin'
})(EditGroupAdminForm);

export default EditGroupAdminForm;
import { Button, Input, Modal, Typography, notification } from 'antd';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { toCamelCaseObjKeys } from '../../utils/object.utils';

import services from '../../services';
import states from '../../states';

const CaseIdModal = ({ component, prescriptionId, patientSub }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [caseId, setCaseId] = useState('');

  const [rxState, setRxState] = useRecoilState(states.prescription);

  const rxList = rxState.list;
  const rxItem = rxList.find((rx) => rx.id === prescriptionId);

  const handleShowModal = () => {
    setShowModal(!showModal);

    if (showModal) {
      setCaseId('');
    } else {
      setCaseId(rxItem?.caseId || '');
    }
  };

  const handleInputCaseId = (e) => {
    setCaseId(e.target.value);
  };

  const handleAssignCases = async () => {
    try {
      setLoading(true);

      const response = await services.prescription.assignCases({
        id: prescriptionId,
        sub: patientSub,
        caseId
      });

      if (response.status === 200) {
        const data = toCamelCaseObjKeys(response.data);
        const newList = rxList.map((item) => {
          if (item.id === prescriptionId) {
            return data;
          }

          return item;
        });

        setRxState({
          ...rxState,
          form: data,
          list: newList
        });

        notification.success({
          message: 'Success!',
          description: 'HEP Case Id assigned successfully.'
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error!',
        description: 'An error occurred while assigning case id.'
      });
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  };

  return (
    <>
      <div onClick={handleShowModal}>{component}</div>

      <Modal
        destroyOnClose
        title="Assign Case Id"
        okText="Assign"
        open={showModal}
        onCancel={handleShowModal}
        footer={[
          <Button key="cancel" onClick={handleShowModal}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={!caseId || rxItem?.caseId === caseId}
            loading={loading}
            onClick={handleAssignCases}
          >
            Assign
          </Button>
        ]}
      >
        <div style={{ marginBottom: 8 }}>
          <Typography.Text>Case Id:</Typography.Text>
          <Input
            placeholder="Enter Case Id"
            value={caseId}
            onChange={handleInputCaseId}
          />
        </div>
      </Modal>
    </>
  );
};

export default CaseIdModal;

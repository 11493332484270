import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  Button,
  Col,
  Divider,
  Popover,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
} from 'antd';
import {
  CaretRightOutlined,
  FieldTimeOutlined,
  InfoCircleFilled,
  LoadingOutlined,
  MinusOutlined,
} from '@ant-design/icons';

import v from 'voca';
import moment from 'moment';

import {
  setIsMonitoring,
  setTimerEvent,
  getRecentMonitoredLogs,
  clearCurrentMonitoredPatient,
  clearRecentMonitoredLogs,
  endLoadingMonitoredPatient,
} from './actions';
import {
  delay,
  checkDischarged,
} from '../patients/RTMDashboard/components/PatientDetailDrawer/mixins';

import services from '../../services';
import states from '../../states';
import useRtmIdleTimer from './hooks/useRtmIdleTimer';

import InactivityPrompt from './components/InactivityPrompt';
import RecentMonitoredActivities from './components/RecentMonitoredActivities';
import TotalTime from './components/TotalTime';

const popoverContent = () => (
  <Space direction="vertical">
    <div className="popover-title-container">
      <Space direction="horizontal" size={24}>
        <Typography.Text strong>RTM Timer</Typography.Text>
        <a
          className={'pull-right btn-link'}
          href={'https://ptwired.zendesk.com/hc/en-us/articles/8352988938259'}
          target={'_blank'}
          rel="noopener noreferrer"
        >
          Help Center
        </a>
      </Space>
    </div>
  </Space>
);

const MonitorTimer = props => {
  const prescription = useRecoilValue(states.prescription);
  const user = useRecoilValue(states.user);
  const setRTM = useSetRecoilState(states.rtm);

  const [openTimer, setOpenTimer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [renderStreamDuration, setRenderStreamDuration] = useState('00:00:00');
  const streamDuration = useRef(0);
  const previousTime = useRef(0);
  const requestAnimationFrameId = useRef(null);

  const { dispatch, monitorTimer, history } = props;
  const {
    currentMonitoredPatient,
    recentMonitoredLogs,
    exitActivity,
    loadingMonitoredPatient,
    isMonitoring,
  } = monitorTimer;

  const {
    isInactive,
    remainingTime,
    openInactivePrompt,
    handleResumeMonitoring,
  } = useRtmIdleTimer(isMonitoring, currentMonitoredPatient, streamDuration);

  const isDischarged = checkDischarged(currentMonitoredPatient);

  useEffect(() => {
    if (
      isInactive &&
      !openInactivePrompt &&
      isMonitoring &&
      currentMonitoredPatient
    ) {
      handleSetMonitoring(false);
    }
  }, [isInactive, openInactivePrompt]);

  const updateTimer = useCallback(() => {
    let now = performance.now();
    let dt = now - previousTime.current;

    if (dt >= 1000) {
      streamDuration.current = streamDuration.current + Math.round(dt / 1000);
      const isoFormat = new Date(streamDuration.current * 1000).toISOString();
      const formattedStreamDuration = isoFormat.substring(
        isoFormat.indexOf('T') + 1,
        isoFormat.indexOf('.')
      );

      setRenderStreamDuration(formattedStreamDuration);
      previousTime.current = now;
    }

    requestAnimationFrameId.current = requestAnimationFrame(updateTimer);
  }, []);

  const startTimer = useCallback(() => {
    previousTime.current = performance.now();
    requestAnimationFrameId.current = requestAnimationFrame(updateTimer);
  }, [updateTimer]);

  useEffect(() => {
    dispatch(clearCurrentMonitoredPatient());
    dispatch(clearRecentMonitoredLogs());
    dispatch(endLoadingMonitoredPatient());
  }, []);

  useEffect(() => {
    if (currentMonitoredPatient) {
      // Start timer when monitored patient exists and monitoring is true and loading is false
      if (isMonitoring && !loading) {
        resetTimer();
        startTimer();
      }

      fetchRecentMonitoredLogs();
    }

    if (
      (!currentMonitoredPatient && !isMonitoring) ||
      (!currentMonitoredPatient && isMonitoring)
    ) {
      resetTimer();
      dispatch(clearRecentMonitoredLogs());
    }
  }, [currentMonitoredPatient, isMonitoring, loading]);

  useEffect(() => {
    if (exitActivity) {
      if (isMonitoring) {
        addLogAndGetLatest();
      }
      dispatch(clearCurrentMonitoredPatient());
    }
  }, [exitActivity, isMonitoring]);

  useEffect(() => {
    if (isMonitoring && isDischarged) {
      resetTimer();
      dispatch(setIsMonitoring(false));
      handleSetTimerEvent();
    }
  }, [isDischarged]);

  const resetTimer = () => {
    streamDuration.current = 0;
    cancelAnimationFrame(requestAnimationFrameId.current);
    setRenderStreamDuration('00:00:00');
  };

  const addLogAndGetLatest = async () => {
    if (currentMonitoredPatient) {
      try {
        setLoading(true);
        await handleSetTimerEvent();
        await delay(1000);
        await fetchRecentMonitoredLogs();
      } catch (error) {
        console.log('[addLogAndGetLatest]', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSetTimerEvent = async () => {
    const patient = currentMonitoredPatient.patient;
    const contact = patient.EmailAddress || patient.PhoneNumber;

    await dispatch(
      setTimerEvent(
        currentMonitoredPatient.groupName,
        contact,
        patient.GroupId,
        patient.Sub,
        streamDuration.current * 1000,
        currentMonitoredPatient.activity,
        currentMonitoredPatient.provider
      )
    );

    try {
      const { patient } = currentMonitoredPatient;
      const response = await services.rtm.getAnalyticsBySub(patient.Sub);

      setRTM(prevState => ({
        ...prevState,
        analytics: [...prevState.analytics].map(item => {
          if (item.Sub !== patient.Sub) {
            return item;
          }

          return response.data;
        }),
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRecentMonitoredLogs = async () => {
    if (currentMonitoredPatient.patient) {
      const query = {
        start: moment().startOf('month').toDate().getTime(),
        end: moment().endOf('month').toDate().getTime(),
      };

      await dispatch(
        getRecentMonitoredLogs(
          currentMonitoredPatient.groupName,
          currentMonitoredPatient.patient.Sub,
          currentMonitoredPatient.patient.GroupId,
          query
        )
      );
    }
  };

  const handleSetMonitoring = async value => {
    await dispatch(setIsMonitoring(value));

    if (currentMonitoredPatient) {
      if (value === false) {
        setLoading(true);
        setRenderStreamDuration('00:00:00');
        cancelAnimationFrame(requestAnimationFrameId.current);
        await handleSetTimerEvent();
        streamDuration.current = 0;
        await delay(1000);
      }

      await fetchRecentMonitoredLogs();
      setLoading(false);
    }
  };

  const renderMonitoredPatient = () => {
    return (
      <React.Fragment>
        <Typography.Text className="timer-patient-name">
          Current Patient:{' '}
          {currentMonitoredPatient.patient && (
            <strong>
              {v.capitalize(currentMonitoredPatient.patient.FirstName)}{' '}
              {v.capitalize(currentMonitoredPatient.patient.LastName)}
            </strong>
          )}
        </Typography.Text>
        <Row justify="space-between">
          {loading ? (
            <Col span={24}>
              <Typography.Text
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '50px',
                }}
              >
                <Spin
                  indicator={
                    <LoadingOutlined spin style={{ fontSize: '36px' }} />
                  }
                />
              </Typography.Text>
              <Divider />
            </Col>
          ) : (
            <React.Fragment>
              <Col span={17}>
                <Typography.Text strong>Current Activity</Typography.Text>
              </Col>
              <Col span={7}>
                <Typography.Text strong>Duration</Typography.Text>
              </Col>
              <Col span={17}>
                <Typography.Text>
                  {currentMonitoredPatient.activity}
                </Typography.Text>
              </Col>
              <Col span={7}>
                <Typography.Text type={isMonitoring && 'success'}>
                  {renderStreamDuration}
                </Typography.Text>
              </Col>
              <Divider />
              <TotalTime
                recentMonitoredLogs={recentMonitoredLogs}
                streamDuration={streamDuration}
                isMonitoring={isMonitoring}
              />
            </React.Fragment>
          )}
          <RecentMonitoredActivities
            recentMonitoredLogs={recentMonitoredLogs}
          />
        </Row>
      </React.Fragment>
    );
  };

  const renderDischargedPatient = () => {
    return (
      <React.Fragment>
        <Typography.Text>
          This patient has been discharged. To track RTM time please re-admit
          the patient.
        </Typography.Text>
      </React.Fragment>
    );
  };

  const renderNoPatientSelected = () => {
    return (
      <React.Fragment>
        <Typography.Text>
          {history &&
          history.location.pathname.includes('/patients/') &&
          prescription.list.length > 1
            ? 'There is no RTM eligible activity at the moment.'
            : 'No RTM patient selected at the moment.'}
        </Typography.Text>
      </React.Fragment>
    );
  };

  return (
    <Fragment>
      <InactivityPrompt
        remainingTime={remainingTime}
        openInactivePrompt={openInactivePrompt}
        handleResumeMonitoring={handleResumeMonitoring}
      />
      <div className="timer-container">
        <div className={`timer-modal ${openTimer ? 'open' : 'close'}`}>
          <div className="timer-modal-header">
            <Typography.Title level={3}>
              RTM Timer{' '}
              <Popover
                content={popoverContent()}
                trigger="hover"
                placement="right"
                arrowPointAtCenter
                zIndex={9999}
              >
                <InfoCircleFilled className="popover-icon" />
              </Popover>
            </Typography.Title>
          </div>

          <div className="timer-modal-body">
            {user && user.details && user.details.billingRole === 'TECH' ? (
              <Typography.Text>
                Only <strong>therapists</strong> and{' '}
                <strong>therapist assistants</strong> are able to log billable
                RTM time.
              </Typography.Text>
            ) : (
              <React.Fragment>
                {loadingMonitoredPatient ? (
                  <Typography.Text>
                    <Spin indicator={<LoadingOutlined spin />} /> Loading
                    current patient...
                  </Typography.Text>
                ) : (
                  <React.Fragment>
                    {currentMonitoredPatient ? (
                      <React.Fragment>
                        {isDischarged
                          ? renderDischargedPatient()
                          : renderMonitoredPatient()}
                      </React.Fragment>
                    ) : (
                      renderNoPatientSelected()
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
          <div className="timer-modal-footer">
            <Space direction="horizontal" size={7}>
              <React.Fragment>
                <Button
                  danger={isMonitoring ? true : false}
                  shape="circle"
                  type="primary"
                  className="btn-primary"
                  icon={
                    isMonitoring ? (
                      <Typography.Text style={{ color: '#fff' }}>
                        ●
                      </Typography.Text>
                    ) : (
                      <CaretRightOutlined />
                    )
                  }
                  disabled={
                    (user &&
                      user.details &&
                      user.details.billingRole === 'TECH') ||
                    isDischarged
                  }
                  onClick={() =>
                    handleSetMonitoring(isMonitoring ? false : true)
                  }
                />
                <Typography>
                  {isMonitoring ? 'Stop' : 'Start'} Monitoring
                </Typography>
              </React.Fragment>
            </Space>
          </div>
        </div>
        <Button
          shape="circle"
          type={openTimer ? 'default' : 'primary'}
          size="large"
          className={`timer-btn ${!openTimer && 'btn-primary'}`}
          onClick={() => setOpenTimer(!openTimer)}
          icon={openTimer ? <MinusOutlined /> : <FieldTimeOutlined />}
        />
        {/* display when modal is closed and monitoring started */}
        {isMonitoring && !openTimer && (
          <Tag color="#f47373" className="timer-indicator">
            ● Monitoring
          </Tag>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    monitorTimer: state.monitorTimer,
  };
};

export default connect(mapStateToProps)(MonitorTimer);

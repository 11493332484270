import { Space, Typography } from 'antd';
import React from 'react';

const formatPhoneNumber = phoneNumber => {
  if (phoneNumber.includes('-')) {
    return phoneNumber;
  }

  return (
    '(' +
    phoneNumber.slice(0, 3) +
    ')' +
    phoneNumber.slice(3, 6) +
    '-' +
    phoneNumber.slice(6)
  );
};

const PDFFooter = ({ pageContents, currEmail, currPhone }) => {
  return (
    <Space direction="vertical" size={2}>
      <Typography.Title level={4}>
        {pageContents.haveQuestions}
      </Typography.Title>
      <Typography.Text>
        <strong>{pageContents.phone} </strong>
        {currPhone ? formatPhoneNumber(currPhone) : 'N/A'}
      </Typography.Text>
      <Typography.Text>
        <strong>{pageContents.email} </strong>
        {currEmail || 'N/A'}
      </Typography.Text>
    </Space>
  );
};

export default PDFFooter;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { Well, Panel, Button } from 'react-bootstrap';

import UploadProfilePhotoModal from '../UploadProfilePhotoModal';
import MobileApp from '../../features/mobile';
import MonitorTimer from '../../features/monitor-timer';
import PlayerZero from '@goplayerzero/sdk-web';

import {
  disableProfilePhotoPopup,
  requestDeauth,
  requestProfile
} from '../../features/membership/actions';
import { exitCurrentMonitoredPatient } from '../../features/monitor-timer/actions';
import { toCamelCaseObjKeys } from '../../utils/object.utils';

import routes from '../../routes';
import states from '../../states';
import services from '../../services';
import MessageSubscription from '../MessageSubscription';

import '../loadingPage.css';

const parseData = (data) => {
  try {
    return JSON.parse(data);
  } catch (error) {
    return data;
  }
};

const PrivateRoutes = ({
  dispatch,
  currentUser,
  currentMonitoredPatient,
  currentLocation,
  visibleProfile,
  publicPage,
  history
}) => {
  const [fetchedUser, setFetchedUser] = useState(false);
  const [hasImgError, setHasImgError] = useState(false);

  const setExercises = useSetRecoilState(states.exercises);
  const setFavorites = useSetRecoilState(states.favorites);
  const setFilters = useSetRecoilState(states.filters);
  const setGroups = useSetRecoilState(states.groups);
  const setMessages = useSetRecoilState(states.messages);
  const setTemplates = useSetRecoilState(states.templates);
  const setUser = useSetRecoilState(states.user);

  useEffect(() => {
    const { username, role, sub } = currentUser;

    Promise.all([
      dispatch(requestProfile(username, role)),
      services.filters.getFilters(sub)
    ]).then(([profile, filters]) => {
      const { value } = profile;

      PlayerZero.identify(value.Sub, {
        name: `${value.FirstName} ${value.LastName}`,
        email: value.EmailAddress,
        group: publicPage.name
      });

      setUser((prevState) => ({
        ...prevState,
        details: {
          emailAddress: value.EmailAddress,
          sub: value.Sub,
          role: value.Role,
          firstName: value.FirstName,
          lastName: value.LastName,
          active: value.Active,
          clinic: value.Clinic,
          billingRole: value.BillingRole,
          favoriteId: value.FavoriteId,
          preferences: {
            ...toCamelCaseObjKeys(value.Preferences)
          }
        }
      }));

      if (role === 'pt' || role === 'ga') {
        if (filters.status === 200) {
          setFilters(() => filters.data);
        }

        setFetchedUser(true);
        initData(value);
      } else {
        setFetchedUser(true);
      }

      const isMessagingEnabled =
        value.Preferences &&
        value.Preferences.Messaging &&
        value.Preferences.Messaging === 'enabled';

      if (role === 'p' || isMessagingEnabled) {
        services.message.getBoards(role, value.Sub).then((res) => {
          if (res && Array.isArray(res.data) && res.data.length > 0) {
            setMessages((prevState) => ({
              ...prevState,
              list: [...res.data]
            }));
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    if (currentMonitoredPatient && !currentLocation.includes('/patients')) {
      dispatch(exitCurrentMonitoredPatient());
    }
  }, [currentMonitoredPatient, currentLocation]);

  useEffect(() => {
    if (visibleProfile && publicPage) {
      const { EmailAddress, Role } = visibleProfile;
      const { name } = publicPage;

      ReactGA.set({ userId: EmailAddress }, [
        `Role: ${Role}`,
        `GroupName: ${name}`
      ]);

      ReactGA.pageview('/login');

      ReactGA.event({
        category: 'Login',
        action: 'User Login',
        label: `Role: ${Role} & GroupName:${name}`
      });
    }
  }, [visibleProfile, publicPage]);

  const initData = async (data) => {
    const { GroupId, GroupInfo, FavoriteId, Sub, EmailAddress } = data;

    await Promise.allSettled([
      services.exercises.getCustomExercises(Sub),
      services.exercises.getCustomParameter(Sub),
      services.exercises.getFavorites(FavoriteId),
      services.groups.getClinics(GroupId),
      services.groups.getProviders(GroupId),
      services.templates.getList(GroupId, ['MY_TEMPLATES'], '', EmailAddress)
    ]).then((response) => {
      const values = response.map((res) => res.value);
      const [exercises, parameter, favorites, clinics, providers, templates] =
        values;

      const favExercises = favorites.data.exercises;
      const hasFavorites =
        Array.isArray(favExercises) && favExercises.length > 0;

      const templateList = templates.data;
      const hasTemplates =
        Array.isArray(templateList) && templateList.length > 0;

      setExercises((prevState) => ({
        ...prevState,
        custom: {
          exercises: exercises.data,
          parameter: parameter.data
        }
      }));

      if (hasFavorites) {
        setFavorites((prevState) => ({
          ...prevState,
          list: favExercises.map((ex) => ({
            ...toCamelCaseObjKeys(ex)
          }))
        }));
      }

      setGroups((prevState) => ({
        ...prevState,
        user: {
          id: GroupId,
          ...toCamelCaseObjKeys(GroupInfo)
        },
        clinics: clinics.data,
        providers: providers.data
      }));

      if (hasTemplates) {
        setTemplates((prevState) => ({
          ...prevState,
          list: templateList
        }));
      }
    });
  };

  if (!fetchedUser) {
    return (
      <div className="loading-wrapper">
        <div className="lds-ring">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  }

  if (
    visibleProfile &&
    Object.keys(visibleProfile).length > 0 &&
    !parseData(visibleProfile.Active)
  ) {
    return (
      <Well bsSize="large">
        <Panel style={{ textAlign: 'center' }}>
          <Panel.Heading>
            <img
              alt=""
              src={publicPage.design.logoUrl}
              width={250}
              onError={(e) =>
                (e.target.src = `https://mq6e62qpo5.execute-api.us-east-1.amazonaws.com/1/logo.svg`)
              }
            />

            <br />
            <br />

            <Panel.Title componentClass="h3">
              Your account has been deactivated.
            </Panel.Title>
          </Panel.Heading>

          <Panel.Body>
            <p>Please contact your account admin to activate your account.</p>
          </Panel.Body>
        </Panel>

        <Button
          block
          variant="primary"
          size="lg"
          onClick={() => dispatch(requestDeauth())}
        >
          Log Out
        </Button>
      </Well>
    );
  }

  const { Sub, GroupId, GroupInfo, imgUrl, Preferences, Role } = visibleProfile;
  const profileImg =
    imgUrl ||
    ` https://ptwired-exercise-images-prod.s3.amazonaws.com/${GroupId}/${Sub}.jpg`;
  const onPrintPreview = history.location.pathname === '/print-preview';

  return (
    <div>
      <img
        alt="empty"
        src={imgUrl ? profileImg : profileImg.replace('@', '%40')}
        style={{ display: 'none' }}
        onError={() => setHasImgError(true)}
      />

      {Role && (
        <Fragment>
          {Role !== 'p' ||
            Role !== 'sa' ||
            ((!Preferences || !Preferences.DisableProfilePhotoPopup) && (
              <UploadProfilePhotoModal
                imgError={hasImgError}
                setImgError={(hasError) => setHasImgError(hasError)}
                dispatch={dispatch}
                visibleProfile={visibleProfile}
                disableProfilePhotoPopup={disableProfilePhotoPopup}
              />
            ))}

          {Role === 'p' && <MobileApp />}

          {Role !== 'p' && (
            <Helmet>
              <script
                id="ze-snippet"
                src="https://static.zdassets.com/ekr/snippet.js?key=def14d34-c188-4965-802d-692ca269d45b"
              ></script>
            </Helmet>
          )}

          {Role === 'sa' && routes(Role, visibleProfile)}

          {(Role === 'pt' || Role === 'ga') && (
            <Fragment>
              <MessageSubscription sub={visibleProfile.Sub} />

              {routes(Role, visibleProfile)}

              {!!parseData(GroupInfo.EnableRTM) && !onPrintPreview && (
                <MonitorTimer history={history} />
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  currentMonitoredPatient: state.monitorTimer.currentMonitoredPatient,
  currentLocation: state.routerReducer.location.pathname,
  visibleProfile: state.visibleProfile,
  publicPage: state.publicPage
});

export default connect(mapStateToProps)(withRouter(PrivateRoutes));

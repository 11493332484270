import React, { Fragment, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  DatePicker,
  Table,
  Spin,
  Button,
  Row,
  Col,
  Radio,
  Popover,
  Typography,
  Form,
  Space,
} from 'antd';
import {
  ClearOutlined,
  DownloadOutlined,
  LoadingOutlined,
  MailOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import v from 'voca';
import './styles.css';

import DiagnosisSelect from './DiagnosisSelect';
import LocationSelect from './LocationSelect';
import ProvidersSelect from './ProvidersSelect';
import EmailDistroModal from './EmailDistroModal';

import services from '../../services';
import states from '../../states';
import setNotification from '../../utils/setNotification.utils';
import { downloadFile } from '../../utils/csv.utils';

const capitalize = (fname, lname) =>
  `${v.capitalize(fname)} ${v.capitalize(lname)}`;
const formatDate = date => moment(date).format('DD-MMM-YY');
const tableHeader = (title, index, width) => ({
  title,
  dataIndex: index,
  key: index,
  width,
  className: 'nps-reports-tbl-cell',
});

const NPSReports = () => {
  const [loadingReports, setLoadingReports] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [noQuery, setNoQuery] = useState(false);
  const [query, setQuery] = useState(null);
  const [diagnosis, setDiagnosis] = useState(null);
  const [clinic, setClinic] = useState(null);
  const [provider, setProvider] = useState(null);
  const [reviewSender, setReviewSender] = useState(null);
  const [savingSender, setSavingSender] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [form] = Form.useForm();

  const [careplan, setCarePlan] = useRecoilState(states.careplan);
  const [groups, setGroups] = useRecoilState(states.groups);
  const [nps, setNPS] = useRecoilState(states.nps);

  useEffect(() => {
    getFilters();

    const user = groups.user;
    setReviewSender(user.reviewSender || 'provider');
  }, []);

  const getFilters = async () => {
    try {
      setLoadingFilters(true);

      if (!careplan.programs.length) {
        await services.careplan.getPrograms().then(response => {
          if (response.data) {
            const { data } = response.data;
            setCarePlan({
              ...careplan,
              programs: data,
            });
          }
        });
      }

      let clinicsList = groups.clinics;
      if (!groups.clinics.length) {
        const groupClinics = await services.groups.getClinics();
        if (groupClinics.message === 'Success') {
          clinicsList = groupClinics.data;
        }
      }

      let providersList = groups.providers;
      if (!groups.providers.length) {
        const groupProviders = await services.groups.getProviders();
        if (groupProviders.message === 'Success') {
          providersList = groupProviders.data;
        }
      }

      setGroups({
        ...groups,
        clinics: clinicsList,
        providers: providersList,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingFilters(false);
    }
  };

  const handleSaveReviewSender = async e => {
    const notif = {
      type: 'success',
      message: 'Success!',
      description: 'Review sender successfully updated.',
    };

    try {
      setSavingSender(true);

      const user = groups.user;
      const sender = e.target.value;
      await services.groups.updateReviewSender({
        groupId: user.id,
        reviewSender: sender,
      });

      setReviewSender(sender);
      setGroups({
        ...groups,
        user: {
          ...user,
          reviewSender: sender,
        },
      });
    } catch (error) {
      console.log(error);

      notif.type = 'error';
      notif.message = 'Update Failed!';
      notif.description = 'An error occurred while updating review sender.';
    } finally {
      setSavingSender(false);
      setNotification(notif.type, notif.message, notif.description);
    }
  };

  const handleSearch = async () => {
    try {
      if (query) {
        setLoadingReports(true);
        setNPS(prevState => ({
          ...prevState,
          reports: [],
        }));

        const providerObj = {
          sub: '',
          email: '',
        };

        if (provider) {
          const providers = groups.providers;
          const p = provider;
          const profile = providers.find(prov => prov.EmailAddress === p);

          providerObj.sub = profile.Sub;
          providerObj.email = profile.EmailAddress;
        }

        const response = await services.reports.getNPS(
          query,
          diagnosis,
          clinic,
          provider ? providerObj : provider
        );

        const { data } = response;
        if (Array.isArray(data) && data.length) {
          const results = [];

          for (let i = 0; i < data.length; i++) {
            const item = data[i];

            const clinicId = clinic || item.Location;
            const clinicItem = groups.clinics.find(c => c.Id === clinicId);
            const clinicName = clinicItem?.ClinicName || '';

            const providers = groups.providers;
            const providerList = item.Providers.map(p => {
              if (!p) {
                return '';
              }

              let profile;
              if (p.includes('@')) {
                profile = providers.find(prov => prov.EmailAddress === p);
              } else {
                profile = providers.find(prov => prov.Sub === p);
              }

              return profile
                ? capitalize(profile.FirstName, profile.LastName)
                : p;
            });

            results.push({
              startDate: formatDate(item.StartDate),
              patientName: item.PatientName.split(' ')
                .map(v.capitalize)
                .join(' '),
              patientProgram: item.PatientProgram,
              location: clinicName,
              providerName: providerList.join(', '),
              npsDate: formatDate(item.Date),
              npsScore: item.Score,
              npsComments: item.Comment,
            });
          }

          setNPS(prevState => ({
            ...prevState,
            reports: results,
          }));
        }
      } else {
        setNoQuery(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingReports(false);
    }
  };

  const handleDownload = () => {
    const filename = query
      ? `${formatDate(query.start)}_TO_${formatDate(query.end)}`
      : 'NPS_Report';
    const reports = nps.reports.map(item => {
      const newItem = {};

      Object.keys(item).forEach(key => {
        let newKey = '';
        switch (key) {
          case 'startDate':
            newKey = 'Start Date';
            break;
          case 'patientName':
            newKey = 'Patient Name';
            break;
          case 'patientProgram':
            newKey = 'Patient Program';
            break;
          case 'location':
            newKey = 'Location';
            break;
          case 'providerName':
            newKey = 'Provider Name';
            break;
          case 'npsDate':
            newKey = 'NPS Date';
            break;
          case 'npsScore':
            newKey = 'NPS Score';
            break;
          case 'npsComments':
            newKey = 'NPS Comments';
            break;
          default:
            newKey = key;
            break;
        }

        newItem[newKey] = item[key];
      });

      return newItem;
    });

    try {
      downloadFile(reports, filename);
    } catch (error) {
      console.log(error);
      setNotification(
        'error',
        'Error!',
        'An error occurred while trying to download file.'
      );
    }
  };

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="ptw-main-body">
      {/* desktop */}
      <Space className="hide-mb" size={4}>
        <Space size={2}>
          <label style={{ color: '#000', paddingLeft: 4 }}>
            Send Review By: &nbsp;&nbsp;
          </label>

          <Radio.Group
            disabled={savingSender}
            value={reviewSender}
            onChange={handleSaveReviewSender}
          >
            <Radio value="provider">Provider</Radio>
            <Radio value="group">Group</Radio>
          </Radio.Group>
        </Space>

        <Popover
          arrowPointAtCenter
          trigger="hover"
          placement="right"
          content={
            <Typography.Text>
              Create a list of providers who will be notified via email
              <br />
              when a patient submits a score of 7 or below.
            </Typography.Text>
          }
        >
          <Button
            type="primary"
            icon={<MailOutlined />}
            onClick={handleShowModal}
            style={{ marginLeft: 8 }}
          >
            DETRACTOR EMAILS
          </Button>
        </Popover>
      </Space>
      {/* mobile */}
      <Space direction="vertical" className="hide-dt flex-mb" size={12}>
        <Space direction="vertical" size={2}>
          <label style={{ color: '#000', paddingLeft: 4 }}>
            Send Review By: &nbsp;&nbsp;
          </label>

          <Radio.Group
            disabled={savingSender}
            value={reviewSender}
            onChange={handleSaveReviewSender}
          >
            <Radio value="provider">Provider</Radio>
            <Radio value="group">Group</Radio>
          </Radio.Group>
        </Space>

        <Popover
          arrowPointAtCenter
          trigger="hover"
          placement="right"
          content={
            <Typography.Text>
              Create a list of providers who will be notified via email
              <br />
              when a patient submits a score of 7 or below.
            </Typography.Text>
          }
        >
          <Button
            type="primary"
            icon={<MailOutlined />}
            onClick={handleShowModal}
            style={{ marginLeft: 8 }}
          >
            DETRACTOR EMAILS
          </Button>
        </Popover>
      </Space>

      <Fragment>
        <div className="nps-reports-filter">
          {noQuery ? (
            <strong style={{ color: '#FF0000', paddingLeft: 4 }}>
              Date range is required.
            </strong>
          ) : (
            <div style={{ height: 22 }} />
          )}

          <div>
            <div className="hide-lg-tablet pull-right">
              <Button
                type="primary"
                disabled={!nps.reports.length}
                icon={<DownloadOutlined />}
                onClick={handleDownload}
              >
                DOWNLOAD FILE
              </Button>
            </div>

            <Form form={form}>
              <Row gutter={[8, 8]}>
                <Col>
                  <Form.Item name="dateRange">
                    <DatePicker.RangePicker
                      style={{ borderColor: noQuery ? '#FF0000' : '' }}
                      onChange={values => {
                        if (values) {
                          setQuery({
                            start: new Date(values[0]).setHours(0, 0, 0, 0),
                            end: new Date(values[1]).setHours(23, 59, 59, 999),
                          });
                        } else {
                          setQuery(null);
                        }

                        setNoQuery(false);
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col>
                  <DiagnosisSelect
                    loading={loadingFilters}
                    value={diagnosis}
                    onChange={value => setDiagnosis(value)}
                  />
                </Col>

                <Col>
                  <LocationSelect
                    loading={loadingFilters}
                    value={clinic}
                    onChange={value => setClinic(value)}
                  />
                </Col>

                <Col>
                  <ProvidersSelect
                    loading={loadingFilters}
                    value={provider}
                    onChange={value => setProvider(value)}
                  />
                </Col>

                <Col>
                  <Button
                    type="primary"
                    icon={<SearchOutlined />}
                    disabled={loadingFilters || loadingReports}
                    onClick={handleSearch}
                  >
                    SEARCH NPS
                  </Button>
                </Col>

                <Col>
                  <Button
                    style={{ backgroundColor: '#F5F5F5' }}
                    type="default"
                    icon={<ClearOutlined />}
                    disabled={loadingFilters}
                    onClick={() => {
                      setDiagnosis(null);
                      setClinic(null);
                      setProvider(null);
                      setQuery(null);

                      form.resetFields();
                    }}
                  >
                    CLEAR FILTERS
                  </Button>
                </Col>
                <Col className="hide-dt show-lg-tablet">
                  <Button
                    type="primary"
                    disabled={!nps.reports.length}
                    icon={<DownloadOutlined />}
                    onClick={handleDownload}
                  >
                    DOWNLOAD FILE
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>

        <Table
          size="small"
          pagination={false}
          scroll={{ x: 'max-content' }}
          locale={{
            emptyText: loadingReports ? (
              <div className="nps-reports-loading">
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
                />
              </div>
            ) : null,
          }}
          columns={[
            tableHeader('Start Date', 'startDate', '6%'),
            tableHeader('Patient Name', 'patientName', '15%'),
            tableHeader('Patient Program', 'patientProgram', '15%'),
            tableHeader('Location', 'location', '12%'),
            tableHeader('Provider Name', 'providerName', '15%'),
            tableHeader('NPS Date', 'npsDate', '6%'),
            tableHeader('NPS Score', 'npsScore', '6%'),
            tableHeader('NPS Comments', 'npsComments', '25%'),
          ]}
          dataSource={nps.reports.map((item, i) => ({
            ...item,
            key: i,
          }))}
        />
      </Fragment>

      <EmailDistroModal
        loadingProviders={loadingFilters}
        open={showModal}
        onClose={handleShowModal}
      />
    </div>
  );
};

export default NPSReports;

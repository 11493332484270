import React from 'react';
import moment from 'moment';
import { Typography, Col } from 'antd';

const RecentMonitoredActivities = props => {
  const { recentMonitoredLogs } = props;
  const clinicLogs = [];

  if (recentMonitoredLogs && recentMonitoredLogs.clinicalMonitoringLogs) {
    const { clinicalMonitoringLogs } = recentMonitoredLogs;
    for (let i = 0; i < clinicalMonitoringLogs.length; i++) {
      const item = clinicalMonitoringLogs[i];
      if (
        item.Start >= moment().startOf('day').toDate().getTime() &&
        item.End <= moment().endOf('day').toDate().getTime()
      ) {
        clinicLogs.push(item);
      }
    }
  }

  if (!clinicLogs.length) {
    return (
      <Typography.Text>No recent monitored activity today.</Typography.Text>
    );
  }

  return (
    <React.Fragment>
      <Col span={17}>
        <Typography.Text strong>Recent Monitored Activity</Typography.Text>
      </Col>

      <Col span={7}>
        <Typography.Text strong>Duration</Typography.Text>
      </Col>

      {recentMonitoredLogs.clinicalMonitoringLogs
        .filter(
          item =>
            item.Start >= moment().startOf('day').toDate().getTime() &&
            item.End <= moment().endOf('day').toDate().getTime()
        )
        .map((item, i) => {
          const minutes = Number(item.Duration / 60000).toFixed(1);
          const parts = minutes.split('.');
          const decimal = parts[1];
          const duration = Number(decimal) === 0 ? parts[0] : minutes;

          return (
            <React.Fragment key={i}>
              <Col span={17}>
                <Typography.Text>{item.Event}</Typography.Text>
              </Col>

              <Col span={7}>
                <Typography.Text>{`${duration} minutes`}</Typography.Text>
              </Col>
            </React.Fragment>
          );
        })}
    </React.Fragment>
  );
};

export default RecentMonitoredActivities;

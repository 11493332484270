import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  isDirty,
  isValid,
  isSubmitting,
  isPristine,
  submit,
  reset,
} from 'redux-form';
import { Button, Input } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Col, Row, Table } from 'react-bootstrap';
import SearchBox from '../../../components/SearchBox';
import { FormTypes } from '../actions/groups';
import ClinicForm from './ClinicForm';
import {
  searchClinics,
  getClinics,
  postClinic,
  updateClinic,
  defaultClinic,
  updateCurrentClinic,
  setClinicFormType as setFormType,
  openClinicFormModal as openFormModal,
  closeClinicFormModal as closeFormModal,
} from '../actions/clinics';
import PTModal from '../group-admin/components/PTModal';
import Loading from '../../../components/Loading';
import { isLoading, doneLoading } from '../actions/clinicsLoadingState';
import { titleCase } from '../stringHelpers';
import setNotification from '../../../utils/setNotification.utils';

const loadingMS = 500;

class ClinicsPage extends Component {
  constructor(props) {
    super(props);

    this.confirmMessage =
      'You currently have unsaved changes. Are you sure you want to discard your changes?';

    this.handleSubmit = this.handleSubmit.bind(this);
    this.openNewFormModal = this.openNewFormModal.bind(this);
    this.openEditFormModal = this.openEditFormModal.bind(this);
    this.handleCloseForm = this.handleCloseForm.bind(this);
    this.onChange = this.onChange.bind(this);

    this.state = {
      search: '',
      clinicsArr: [],
    };
  }

  componentDidMount() {
    const { dispatch, group } = this.props;
    dispatch(isLoading());
    dispatch(getClinics(group.name, group.id)).then(
      x => {
        setTimeout(() => {
          dispatch(doneLoading());
        }, loadingMS);
      },
      x => {
        setTimeout(() => {
          dispatch(doneLoading());
        }, loadingMS);
      }
    );
  }

  sortClinics = (a, b) => {
    if (a.ClinicName.trim() < b.ClinicName.trim()) {
      return -1;
    }
    if (a.ClinicName.trim() > b.ClinicName.trim()) {
      return 1;
    }
    return 0;
  };

  onChange(event) {
    const { clinics } = this.props;
    const search = event.target.value;

    this.setState({ search });

    if (search) {
      const clinicsArr = clinics.filter(
        item =>
          item.ClinicName &&
          item.ClinicName.toLowerCase().startsWith(search.toLowerCase())
      );

      this.setState({ clinicsArr });
    }
  }

  handleCloseForm() {
    const {
      props: { dispatch },
    } = this;

    dispatch(closeFormModal);
  }

  render() {
    const {
      openEditFormModal,
      openNewFormModal,
      handleCloseForm,
      handleSubmit,
      props: {
        clinics,
        fetched,
        formType,
        formModalOpen,
        isFormValid,
        isFormSubmitting,
        isFormPristine,
        dispatch,
        currentClinic,
        group,
      },
      state: { search, clinicsArr },
    } = this;

    let content;
    const list = search ? clinicsArr : clinics;

    Object.keys(currentClinic).forEach(val => {
      if (
        val === 'clinicName' ||
        val === 'address' ||
        val === 'city' ||
        val === 'contactName'
      ) {
        currentClinic[val] = titleCase(currentClinic[val]);
      }
    });
    if (
      !clinics ||
      (fetched && clinics.length === 0 && !this.props.isSearching)
    ) {
      content = (
        <div>
          <Row>
            <Col md={4} mdOffset={4} className="ptw-action-block">
              <Button
                className="btn-primary btn-block"
                onClick={openNewFormModal}
              >
                Add Clinic
              </Button>
            </Col>
          </Row>
        </div>
      );
    } else {
      content = (
        <div>
          <div className="tab-header">
            <div />
            <div className="tab-header-controls">
              <Button
                onClick={openNewFormModal}
                type="primary"
                className="btn-primary ptw-btn"
              >
                <PlusOutlined /> Add Clinic
              </Button>
              <Input
                size="middle"
                placeholder="Clinic Name"
                prefix={<SearchOutlined />}
                onChange={this.onChange}
              />
            </div>
          </div>
          <Table responsive>
            <thead>
              <tr>
                <th>Clinic Name</th>
                <th>Address</th>
                <th>State</th>
                <th>Zip</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(list) &&
                list
                  .sort((a, b) => this.sortClinics(a, b))
                  .map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr
                          id={'row-' + index}
                          onClick={openEditFormModal && openEditFormModal(item)}
                        >
                          <td>{item.ClinicName}</td>
                          <td>{item.Address}</td>
                          <td>{item.State}</td>
                          <td>{item.Zip}</td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
            </tbody>
          </Table>
        </div>
      );
    }

    return (
      <div>
        <Loading isLoading={this.props.isLoading}>
          {content}
          <PTModal
            header={`${formType === FormTypes.NEW ? 'Add' : 'Edit'} Clinic`}
            width={1000}
            showModal={formModalOpen}
            close={handleCloseForm}
            save={() => dispatch(submit('clinic'))}
            saveEnabled={isFormValid && !isFormSubmitting && !isFormPristine}
          >
            <ClinicForm
              onSubmit={handleSubmit}
              initialValues={currentClinic}
              groupName={group.name}
            />
          </PTModal>
        </Loading>
      </div>
    );
  }

  async handleSubmit(values) {
    const { dispatch, group, formType, currentClinic } = this.props;
    let submitAction;

    values.IsPracticePromotions = group.IsPracticePromotions;
    values.BlogUrl = group.BlogUrl;

    if (formType === FormTypes.NEW) {
      submitAction = postClinic(group.name, { ...values, groupId: group.id });
    } else {
      submitAction = updateClinic(group.name, {
        ...values,
        groupId: group.id,
        id: currentClinic.id,
      });
    }

    return await dispatch(submitAction)
      .then(({ action }) => {
        setNotification('success', 'Success!', 'Clinic has been saved.');
        dispatch(getClinics(group.name, group.id));
        dispatch(closeFormModal);
      })
      .catch(err => {
        setNotification(
          'error',
          'Error!',
          'An error occurred while saving clinic.'
        );
        dispatch(closeFormModal);
      });
  }

  openNewFormModal() {
    const { dispatch } = this.props;
    dispatch(setFormType(FormTypes.NEW));
    dispatch(updateCurrentClinic(defaultClinic));
    dispatch(openFormModal);
    dispatch(reset('clinic'));
  }

  openEditFormModal(clinic) {
    const { dispatch } = this.props;
    return () => {
      dispatch(setFormType(FormTypes.EDIT));
      dispatch(updateCurrentClinic(clinic));
      dispatch(openFormModal);
    };
  }
}

function mapStateToProps(state) {
  return {
    clinics: state.clinics.items,
    isFetching: state.clinics.isFetching,
    fetched: state.clinics.fetched,
    //todo: implement with new UX Growl notifications
    // successModalOpen: state.clinics.successModalOpen,
    formModalOpen: state.clinics.formModalOpen,
    formType: state.clinics.formType,
    currentClinic: state.clinics.currentClinic,
    isLoading: state.clinics.isLoading,
    isSearching: state.clinics.isSearching,
    isFormValid: isValid('clinic')(state),
    isFormDirty: isDirty('clinic')(state),
    isFormSubmitting: isSubmitting('clinic')(state),
    isFormPristine: isPristine('clinic')(state),
  };
}

export default connect(mapStateToProps)(ClinicsPage);

import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { Button, Input, notification, Popconfirm, Spin } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { titleCase } from 'voca';

import { formatDateTime } from './mixins';
import services from '../../../../../services';

export default function ActivityLog({
  date,
  activity,
  type,
  handleDeleteCLinicMonitorLogs,
  selectedPatient,
  getClinicLogs
}) {
  const [showEdit, setShowEdit] = useState(false);
  const [notes, setNotes] = useState('');
  const [loadingId, setLoadingId] = useState([]);

  useEffect(() => {
    if (showEdit) {
      setNotes(activity.Notes || '');
    } else {
      setNotes('');
    }
  }, [showEdit]);

  const handleEditClinicLogNotes = async (activity) => {
    try {
      setLoadingId([...loadingId, activity.Id]);
      await services.rtm.editClinicLogNotes({
        Id: activity.Id,
        Sub: selectedPatient.Sub,
        GroupId: selectedPatient.GroupId,
        Date: activity.End,
        Notes: notes
      });

      setNotes('');
      notification.success({
        message: 'Edit Success',
        description: 'Clinic log notes successfully edited.',
        duration: 2
      });

      await getClinicLogs();
    } catch (error) {
      notification.error({
        message: 'Edit Failed',
        description: 'An error occurred while editing clinic log notes.',
        duration: 2
      });
    } finally {
      setLoadingId(loadingId.filter((id) => id !== activity.Id));
      setShowEdit(false);
    }
  };

  const handleDeleteClinicLog = async (id) => {
    setLoadingId([...loadingId, id]);
    await handleDeleteCLinicMonitorLogs(id).finally(() => {
      setLoadingId(loadingId.filter((x) => x !== id));
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      {loadingId.includes(activity.Id) && (
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 36,
                marginTop: 48
              }}
              spin
            />
          }
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: '#FFF',
            opacity: 0.5,
            zIndex: 2
          }}
        />
      )}

      <div className="patient-detail-log-container">
        <div className="patient-detail-log-header">
          {date && <span>{formatDateTime(date).date}</span>}
        </div>

        {type === 'patient' && (
          <ul className="patient-detail-log-activity">
            {activity &&
              activity.map((datapoint, i) => {
                const { Time, Event, Count } = datapoint;

                let event;
                switch (Event) {
                  case 'WAKE':
                    event = 'APP_ACCESS';
                    break;

                  case 'EXERCISE_COMPLETED':
                  case 'EXERCISES_COMPLETED':
                    event = 'COMPLETE_EXERCISE';
                    break;

                  default:
                    event = Event;
                    break;
                }

                return (
                  <li className="patient-detail-log-activity-text" key={i}>
                    {formatDateTime(Time).time}
                    {Event && (
                      <span> - {titleCase(event.replaceAll('_', ' '))} </span>
                    )}
                    {Count !== 1 && <span> - ({Count}) </span>}
                  </li>
                );
              })}
          </ul>
        )}

        {type === 'clinical' && (
          <div className="patient-detail-log-activity">
            {activity && (
              <Fragment>
                {showEdit ? (
                  <div style={{ marginBottom: 20 }}>
                    <div className="patient-detail-tab-rtm-form-activity">
                      <span className="patient-detail-tab-rtm-form-label">
                        {' '}
                        Edit Notes{' '}
                      </span>
                      <Input.TextArea
                        style={{ resize: 'none' }}
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                      />
                    </div>
                    <div className="patient-detail-tab-rtm-form-button">
                      <Button
                        type="primary"
                        className="patient-detail-tab-rtm-form-button-save"
                        onClick={() => handleEditClinicLogNotes(activity)}
                      >
                        {' '}
                        Save Changes{' '}
                      </Button>
                      <Button
                        className="patient-detail-tab-rtm-form-button-cancel"
                        onClick={() => setShowEdit(false)}
                      >
                        {' '}
                        Cancel{' '}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <Fragment>
                    <Popconfirm
                      placement="topRight"
                      title={
                        'Are you sure you want to delete this Monitoring Log Activity?'
                      }
                      onConfirm={() => handleDeleteClinicLog(activity.Id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        shape="circle"
                        type="link"
                        className="btn-link patient-detail-log-delete-btn"
                        icon={<DeleteOutlined />}
                      />
                    </Popconfirm>
                    <Button
                      shape="circle"
                      type="link"
                      className="btn-link patient-detail-log-delete-btn"
                      icon={<EditOutlined />}
                      onClick={() => setShowEdit(true)}
                    />
                    <span className="patient-detail-log-sub-header">
                      {activity.Name} - {activity.Event}
                    </span>
                    <ul>
                      <li className="patient-detail-log-activity-text">
                        <span>
                          {' '}
                          Start Time: {formatDateTime(activity.Start).time}{' '}
                        </span>
                      </li>
                      <li className="patient-detail-log-activity-text">
                        <span>
                          {' '}
                          End Time: {formatDateTime(activity.End).time}{' '}
                        </span>
                      </li>
                      <li className="patient-detail-log-activity-text">
                        <span>
                          {' '}
                          Total Duration: {activity.Duration} minutes{' '}
                        </span>
                      </li>
                      {activity.Notes && (
                        <li className="patient-detail-log-activity-text">
                          <span> Notes: {activity.Notes}</span>
                        </li>
                      )}
                    </ul>
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  Row,
  Col,
  Typography,
  Checkbox,
  Form,
  Input,
  Select,
  Button,
  Popover,
  Tooltip,
} from 'antd';
import { SwapOutlined, UndoOutlined } from '@ant-design/icons';
import { toCamelCaseObjKeys } from '../../../utils/object.utils';

import ReactGA from 'react-ga';
import imagePlaceholder from '../../../img/image-placeholder.png';
import states from '../../../states';
import services from '../../../services';

const IMAGE_URI = 'https://d2p805pqn3eul9.cloudfront.net';

const CustomizeExerciseItem = ({
  isExerciseFrequency,
  exercise,
  checkedExercises,
  handleCheckExerciseItem,
}) => {
  const [showMirrorBtn, setShowMirrorBtn] = useState(false);
  const [isMirror, setIsMirror] = useState(false);

  const [usedCustomParams, setUsedCustomParams] = useState(true);
  const [prescription, setPrescription] = useRecoilState(states.prescription);
  const [exercises, setExercises] = useRecoilState(states.exercises);

  const prevExercise = useRef();
  const [form] = Form.useForm();

  const customParams = exercises.custom.parameter.find(
    p => p.ExerciseId === exercise.id
  );

  useEffect(() => {
    const unmapped = exercises.unmapped;
    const item = unmapped.find(ex => ex.id === exercise.id);

    if (!item) {
      getExerciseDetails();
    } else {
      const image =
        exercise.image || `${IMAGE_URI}/${exercise.groupId}/${exercise.id}.jpg`;
      const imageMirror = item.imageMirror;
      const videoMirror = item.videoMirror;

      setShowMirrorBtn(!!imageMirror && !!videoMirror);
      setIsMirror(image === imageMirror && exercise.video === videoMirror);
    }
  }, []);

  // update all fields values when previous and current exercsies is not identical
  useEffect(() => {
    prevExercise.current = exercise;

    if (prevExercise !== exercise) {
      if (checkedExercises.length !== 0) {
        form.setFieldsValue({
          sets: exercise.sets,
          reps: exercise.reps,
          hold: exercise.hold,
          instructions: exercise.instructions,
        });
      }
    }
  }, [exercise, checkedExercises]);

  const getExerciseDetails = async () => {
    try {
      const { id, groupId } = exercise;
      const response = await services.exercises.getExerciseDetails(id, groupId);

      if (response.status === 200 && response.data) {
        const item = {
          ...toCamelCaseObjKeys(response.data),
        };

        setExercises(prevState => ({
          ...prevState,
          unmapped: [...prevState.unmapped, item],
        }));

        const image = exercise.image || `${IMAGE_URI}/${groupId}/${id}.jpg`;
        const imageMirror = item.imageMirror;
        const videoMirror = item.videoMirror;

        setShowMirrorBtn(!!imageMirror && !!videoMirror);
        setIsMirror(image === imageMirror && exercise.video === videoMirror);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // handle field values changes
  const handleChange = (name, value) => {
    setPrescription(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        prescription: {
          ...prevState.form.prescription,
          exercises: prevState.form.prescription.exercises.map(ex =>
            ex.id === exercise.id ? { ...ex, [name]: value } : ex
          ),
        },
      },
    }));
  };

  const handleUseDefaultParams = () => {
    const unmapped = exercises.unmapped;
    const item = unmapped.find(ex => ex.id === exercise.id);

    setUsedCustomParams(false);
    setPrescription(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        prescription: {
          ...prevState.form.prescription,
          exercises: prevState.form.prescription.exercises.map(ex => {
            if (item?.id === ex.id) {
              return item;
            }

            return ex;
          }),
        },
      },
    }));

    form.setFieldsValue({
      sets: item?.sets || '',
      reps: item?.reps || '',
      hold: item?.hold || '',
      instructions: item?.instructions || '',
    });
  };

  const handleUseCustomParams = () => {
    setUsedCustomParams(true);
    setPrescription(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        prescription: {
          ...prevState.form.prescription,
          exercises: prevState.form.prescription.exercises.map(ex => {
            if (customParams?.ExerciseId === ex.id) {
              return {
                ...ex,
                exerciseName: customParams.ExerciseName,
                instructions: customParams?.Instructions || '',
                sets: customParams?.Sets || '',
                reps: customParams?.Reps || '',
                hold: customParams?.Hold || '',
              };
            }

            return ex;
          }),
        },
      },
    }));

    form.setFieldsValue({
      sets: customParams?.Sets || '',
      reps: customParams?.Reps || '',
      hold: customParams?.Hold || '',
      instructions: customParams?.Instructions || '',
    });
  };

  const handleToggleMirror = async () => {
    const { id, groupId } = exercise;

    let unmapped = exercises.unmapped.find(ex => ex.id === id);
    await services.exercises.getExerciseDetails(id, groupId).then(res => {
      if (res.status === 200 && res.data) {
        unmapped = {
          ...toCamelCaseObjKeys(res.data),
        };
      }
    });

    const mirrored = !isMirror;
    const newExercise = {
      ...exercise,
    };

    if (mirrored) {
      newExercise.image = unmapped.imageMirror;
      newExercise.video = unmapped.videoMirror;
    } else {
      newExercise.image = unmapped.image || `${IMAGE_URI}/${groupId}/${id}.jpg`;
      newExercise.video = unmapped.video;
    }

    setIsMirror(mirrored);
    setPrescription(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        prescription: {
          ...prevState.form.prescription,
          exercises: prevState.form.prescription.exercises.map(item => {
            if (item.id !== exercise.id) {
              return item;
            }

            return newExercise;
          }),
        },
      },
    }));
  };

  const frequency = isExerciseFrequency
    ? exercise.frequency || prescription.form.prescription.frequency
    : 1;
  const perdiem = isExerciseFrequency
    ? exercise.perdiem || prescription.form.prescription.perdiem
    : 'day';

  const image =
    exercise.imgBase64 ||
    exercise.image ||
    exercise.imageUrl ||
    `${IMAGE_URI}/${exercise.groupId}/${exercise.id}.jpg`;

  const renderContent = () => {
    return (
      <div style={{ width: 450 }}>
        <Typography.Text>
          {usedCustomParams
            ? 'This exercise includes previously saved edits to name, instructions and/or parameters. Click this to restore the default values of the exercise.'
            : 'This exercise is using the default name, instructions, and parameters. Click here to re-apply your saved custom edits to the exercise.'}
        </Typography.Text>
      </div>
    );
  };

  const renderTabletView = (
    <div className="customize-exercise-item hide-dt show-lg-tablet">
      <Row gutter={[12, 12]}>
        <Col lg={1} md={1}>
          <Checkbox
            onChange={() => handleCheckExerciseItem(exercise.id)}
            checked={checkedExercises.includes(exercise.id)}
          />
        </Col>
        <Col lg={23} md={23}>
          <Typography.Title level={5}>
            {exercise.exerciseName}{' '}
          </Typography.Title>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col lg={7} md={7} sm={24} xs={24} className="center-content">
          <div className="exercise-thumbnail">
            {showMirrorBtn && (
              <Tooltip
                title={
                  isMirror
                    ? 'Use Default Image & Video'
                    : 'Use Mirror Image & Video'
                }
              >
                <Button
                  style={{
                    position: 'absolute',
                    right: 24,
                    top: 10,
                    zIndex: 999,
                  }}
                  shape="circle"
                  type={isMirror ? 'primary' : 'default'}
                  icon={<SwapOutlined />}
                  onClick={() => setIsMirror(!isMirror)}
                />
              </Tooltip>
            )}

            <img
              style={{
                width: '100%',
                aspectRatio: 16 / 10,
              }}
              alt="exercise"
              src={image}
              onError={e => {
                ReactGA.event({
                  category: 'ImageError',
                  action: `${exercise.groupId}/${exercise.id}`,
                });
                const proxy = `https://s3.amazonaws.com/ptwired-exercise-images-prod/${exercise.groupId}/${exercise.id}.jpg`;
                if (e.target.src === proxy) {
                  e.target.src = imagePlaceholder;
                } else {
                  e.target.src = proxy;
                }
              }}
            />
          </div>
        </Col>
        <Col lg={17} md={17} sm={24} xs={24}>
          <Form form={form} layout="vertical">
            <Row gutter={[16, 16]}>
              <Col lg={12} md={24} sm={24}>
                <Typography.Text>
                  Parameters{' '}
                  {!!customParams && (
                    <Popover placement="top" content={renderContent()}>
                      <Button
                        style={{ marginLeft: 4 }}
                        shape="circle"
                        type={usedCustomParams ? 'default' : 'primary'}
                        icon={<UndoOutlined />}
                        onClick={() =>
                          usedCustomParams
                            ? handleUseDefaultParams()
                            : handleUseCustomParams()
                        }
                      />
                    </Popover>
                  )}
                </Typography.Text>
                <Row
                  gutter={[8, 8]}
                  className="mt-1"
                  style={{ marginBottom: -8 }}
                >
                  <Col lg={8} md={8} sm={8} xs={8}>
                    <Form.Item name="sets">
                      <Input
                        size="large"
                        placeholder="Enter sets"
                        value={exercise.sets}
                        defaultValue={exercise.sets}
                        onChange={e => handleChange('sets', e.target.value)}
                        type="text"
                        name="sets"
                        suffix="sets"
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={8} sm={8} xs={8}>
                    <Form.Item name="reps">
                      <Input
                        size="large"
                        placeholder="Enter reps"
                        value={exercise.reps}
                        defaultValue={exercise.reps}
                        onChange={e => handleChange('reps', e.target.value)}
                        type="text"
                        name="reps"
                        suffix="reps"
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8} md={8} sm={8} xs={8}>
                    <Form.Item name="hold">
                      <Input
                        size="large"
                        placeholder="Enter hold"
                        value={exercise.hold}
                        defaultValue={exercise.hold}
                        onChange={e => handleChange('hold', e.target.value)}
                        type="text"
                        name="hold"
                        suffix="hold"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {isExerciseFrequency && (
                  <React.Fragment>
                    <Typography.Text>Prescription Frequency</Typography.Text>
                    <div className="mt-1">
                      <Select
                        size="large"
                        value={exercise.frequency}
                        defaultValue={exercise.frequency}
                        onChange={value => handleChange('frequency', value)}
                        virtual={false}
                        name="frequency"
                        style={{ minWidth: 100 }}
                      >
                        <Select.Option value={1}>1x</Select.Option>
                        <Select.Option value={2}>2x</Select.Option>
                        <Select.Option value={3}>3x</Select.Option>
                        <Select.Option value={4}>4x</Select.Option>
                        <Select.Option value={5}>5x</Select.Option>
                        <Select.Option value={6}>6x</Select.Option>
                        <Select.Option value={7}>7x</Select.Option>
                        <Select.Option value={8}>8x</Select.Option>
                        <Select.Option value={9}>9x</Select.Option>
                        <Select.Option value={10}>10x</Select.Option>
                      </Select>
                      <span className="ml-2 mr-2">Every</span>
                      <Select
                        size="large"
                        value={exercise.perdiem}
                        defaultValue={exercise.perdiem}
                        onChange={value => handleChange('perdiem', value)}
                        virtual={false}
                        name="perdiem"
                        style={{ minWidth: 100 }}
                      >
                        <Select.Option value={'day'}>Day</Select.Option>
                        <Select.Option value={'week'}>Week</Select.Option>
                        <Select.Option value={'month'}>Month</Select.Option>
                      </Select>
                    </div>
                  </React.Fragment>
                )}
              </Col>

              <Col lg={12} md={24} sm={24} xs={24}>
                <Form.Item
                  name="instructions"
                  label="Prescription Instructions"
                  rules={[
                    {
                      validator: async (_, value) => {
                        if (!value.length) {
                          handleChange('formError', true);
                          return Promise.reject(
                            'Please input your instructions.'
                          );
                        }

                        if (value.length > 1000) {
                          handleChange('formError', true);
                          return Promise.reject(
                            'Max length is 1000 characters.'
                          );
                        }

                        handleChange('formError', false);
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input.TextArea
                    value={exercise.instructions}
                    defaultValue={exercise.instructions}
                    onChange={e => handleChange('instructions', e.target.value)}
                    size="large"
                    placeholder="Enter instructions"
                    rows={4}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );

  return (
    <Fragment>
      {renderTabletView}
      <div className="customize-exercise-item hide-lg-tablet">
        <Row gutter={[16, 16]} style={{ marginTop: -5, marginBottom: -5 }}>
          <Col lg={1} md={1} />
          <Col lg={23} md={23}>
            <Typography.Title level={5}>
              {exercise.exerciseName}{' '}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[32, 32]}>
          <Col lg={1} md={1} className="center-content">
            <Checkbox
              onChange={() => handleCheckExerciseItem(exercise.id)}
              checked={checkedExercises.includes(exercise.id)}
            />
          </Col>
          <Col lg={5} md={5} className="center-content">
            <div className="exercise-thumbnail">
              {showMirrorBtn && (
                <Tooltip
                  title={
                    isMirror
                      ? 'Use Default Image & Video'
                      : 'Use Mirror Image & Video'
                  }
                >
                  <Button
                    style={{
                      position: 'absolute',
                      right: 24,
                      top: 10,
                      zIndex: 999,
                    }}
                    shape="circle"
                    type={isMirror ? 'primary' : 'default'}
                    icon={<SwapOutlined />}
                    onClick={handleToggleMirror}
                  />
                </Tooltip>
              )}

              <img
                style={{
                  width: '100%',
                  aspectRatio: 16 / 10,
                }}
                alt="exercise"
                src={image}
                onError={e => {
                  ReactGA.event({
                    category: 'ImageError',
                    action: `${exercise.groupId}/${exercise.id}`,
                  });
                  const proxy = `https://s3.amazonaws.com/ptwired-exercise-images-prod/${exercise.groupId}/${exercise.id}.jpg`;
                  if (e.target.src === proxy) {
                    e.target.src = imagePlaceholder;
                  } else {
                    e.target.src = proxy;
                  }
                }}
              />
            </div>
          </Col>
          <Col lg={18} md={18}>
            <Form form={form} layout="vertical">
              <Row gutter={[16, 16]}>
                <Col lg={12} md={12}>
                  <Typography.Text>
                    Parameters{' '}
                    {!!customParams && (
                      <Popover placement="top" content={renderContent()}>
                        <Button
                          style={{ marginLeft: 4 }}
                          shape="circle"
                          type={usedCustomParams ? 'default' : 'primary'}
                          icon={<UndoOutlined />}
                          onClick={() =>
                            usedCustomParams
                              ? handleUseDefaultParams()
                              : handleUseCustomParams()
                          }
                        />
                      </Popover>
                    )}
                  </Typography.Text>
                  <Row
                    gutter={[8, 8]}
                    className="mt-1"
                    style={{ marginBottom: -8 }}
                  >
                    <Col lg={8} md={8}>
                      <Form.Item name="sets">
                        <Input
                          size="large"
                          placeholder="Enter sets"
                          value={exercise.sets}
                          defaultValue={exercise.sets}
                          onChange={e => handleChange('sets', e.target.value)}
                          type="text"
                          name="sets"
                          suffix="sets"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={8}>
                      <Form.Item name="reps">
                        <Input
                          size="large"
                          placeholder="Enter reps"
                          value={exercise.reps}
                          defaultValue={exercise.reps}
                          onChange={e => handleChange('reps', e.target.value)}
                          type="text"
                          name="reps"
                          suffix="reps"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={8}>
                      <Form.Item name="hold">
                        <Input
                          size="large"
                          placeholder="Enter hold"
                          value={exercise.hold}
                          defaultValue={exercise.hold}
                          onChange={e => handleChange('hold', e.target.value)}
                          type="text"
                          name="hold"
                          suffix="hold"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {isExerciseFrequency && (
                    <React.Fragment>
                      <Typography.Text>Prescription Frequency</Typography.Text>
                      <div className="mt-1">
                        <Select
                          size="large"
                          value={frequency}
                          defaultValue={frequency}
                          onChange={value => handleChange('frequency', value)}
                          virtual={false}
                          name="frequency"
                          style={{ minWidth: 100 }}
                        >
                          <Select.Option value={1}>1x</Select.Option>
                          <Select.Option value={2}>2x</Select.Option>
                          <Select.Option value={3}>3x</Select.Option>
                          <Select.Option value={4}>4x</Select.Option>
                          <Select.Option value={5}>5x</Select.Option>
                          <Select.Option value={6}>6x</Select.Option>
                          <Select.Option value={7}>7x</Select.Option>
                          <Select.Option value={8}>8x</Select.Option>
                          <Select.Option value={9}>9x</Select.Option>
                          <Select.Option value={10}>10x</Select.Option>
                        </Select>
                        <span className="ml-2 mr-2">Every</span>
                        <Select
                          size="large"
                          value={perdiem}
                          defaultValue={perdiem}
                          onChange={value => handleChange('perdiem', value)}
                          virtual={false}
                          name="perdiem"
                          style={{ minWidth: 100 }}
                        >
                          <Select.Option value={'day'}>Day</Select.Option>
                          <Select.Option value={'week'}>Week</Select.Option>
                          <Select.Option value={'month'}>Month</Select.Option>
                        </Select>
                      </div>
                    </React.Fragment>
                  )}
                </Col>

                <Col lg={12} md={12}>
                  <Form.Item
                    name="instructions"
                    label="Prescription Instructions"
                    rules={[
                      {
                        validator: async (_, value) => {
                          if (!value.length) {
                            handleChange('formError', true);
                            return Promise.reject(
                              'Please input your instructions.'
                            );
                          }

                          if (value.length > 1000) {
                            handleChange('formError', true);
                            return Promise.reject(
                              'Max length is 1000 characters.'
                            );
                          }

                          handleChange('formError', false);
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input.TextArea
                      value={exercise.instructions}
                      defaultValue={exercise.instructions}
                      onChange={e =>
                        handleChange('instructions', e.target.value)
                      }
                      size="large"
                      placeholder="Enter instructions"
                      rows={4}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default CustomizeExerciseItem;
